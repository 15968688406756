import React from "react";
import styled from "styled-components";

const AffiliateRulesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 0rem;
`;

const DashboardSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 2rem;
`;

const SectionHeader = styled.h2`
  margin: 0px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  padding: "";
`;

const SectionContentBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SectionBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const AffiliateRules = () => {
  return (
    <AffiliateRulesWrapper>
      <DashboardSection>
        <SectionHeader>Commission Reward Rate</SectionHeader>
        <SectionContentBody>
          <div>Games</div>
          <SectionBodyDiv>
            <p>The Original Games</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "5px 10px",
                borderRadius: "5px",
              }}
            >
              Wager × 1% × Commission Rate × 28%
            </div>
          </SectionBodyDiv>
          <SectionBodyDiv>
            <p>3rd Party Slots, and games</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "5px 10px",
                borderRadius: "5px",
              }}
            >
              Wager × 1% × Commission Rate × 60%
            </div>
          </SectionBodyDiv>
        </SectionContentBody>
      </DashboardSection>
    </AffiliateRulesWrapper>
  );
};

export default AffiliateRules;
