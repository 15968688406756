import React, { useState, useEffect } from "react";
import styled from "styled-components";

const GSContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    max-height: 65px;
  }
`;

const ScrollWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  transition: transform 0.5s ease;
  width: 100%;
  transform: translateY(${(props) => props.translateY}px);

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    width: max-content;
    justify-items: space-between;
  }
`;

const GSCard = styled.div`
  border-radius: 10px;
  padding: 10px;
  display: flex;
  width: max-content;
  height: 62px;
  margin-bottom: 10px;
`;

const GSIconPlaceholder = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 8px;
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 45px;
    height: 45px;
  }
`;

const GSTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GSProfitText = styled.span`
  font-weight: bold;
  font-size: 12px;
  font-family: Arial, sans-serif;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const GSDescriptionText = styled.span`
  font-size: 12px;
  font-family: Arial, sans-serif;
  margin-top: -4px;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const OptionalText = styled.span`
  color: #888;
  font-size: 12px;
  font-family: Arial, sans-serif;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const cardData = [
  {
    iconSrc: "/assets/images/nano/gs1.webp",
    optionalText: "Hidden",
    profitText: "Won $48664.27",
    profitColor: "#5DDB1C",
    descriptionText: "In Plinko",
  },
  {
    optionalText: "Hidden",
    iconSrc: "/assets/images/nano/gs2.webp",
    profitText: "Profit: $432.47",
    descriptionText: "In Mines",
  },
  {
    iconSrc: "/assets/images/nano/gs1.webp",
    optionalText: "Hidden",
    profitText: "Won $48664.27",
    profitColor: "#5DDB1C",
    descriptionText: "In Plinko",
  },
  {
    optionalText: "Hidden",
    iconSrc: "/assets/images/nano/gs2.webp",
    profitText: "Profit: $432.47",
    descriptionText: "In Mines",
  },
  {
    iconSrc: "/assets/images/nano/gs1.webp",
    optionalText: "Hidden",
    profitText: "Won $48664.27",
    profitColor: "#5DDB1C",
    descriptionText: "In Plinko",
  },
  {
    optionalText: "Hidden",
    iconSrc: "/assets/images/nano/gs2.webp",
    profitText: "Profit: $432.47",
    descriptionText: "In Mines",
  },
  {
    iconSrc: "/assets/images/nano/gs1.webp",
    optionalText: "Hidden",
    profitText: "Won $48664.27",
    profitColor: "#5DDB1C",
    descriptionText: "In Plinko",
  },
  {
    optionalText: "Hidden",
    iconSrc: "/assets/images/nano/gs2.webp",
    profitText: "Profit: $432.47",
    descriptionText: "In Mines",
  },
  {
    iconSrc: "/assets/images/nano/gs1.webp",
    optionalText: "Hidden",
    profitText: "Won $48664.27",
    profitColor: "#5DDB1C",
    descriptionText: "In Plinko",
  },
  {
    optionalText: "Hidden",
    iconSrc: "/assets/images/nano/gs2.webp",
    profitText: "Profit: $432.47",
    descriptionText: "In Mines",
  },
  {
    iconSrc: "/assets/images/nano/gs1.webp",
    optionalText: "Hidden",
    profitText: "Won $48664.27",
    profitColor: "#5DDB1C",
    descriptionText: "In Plinko",
  },
  {
    optionalText: "Hidden",
    iconSrc: "/assets/images/nano/gs2.webp",
    profitText: "Profit: $432.47",
    descriptionText: "In Mines",
  },
  {
    iconSrc: "/assets/images/nano/gs1.webp",
    optionalText: "Hidden",
    profitText: "Won $48664.27",
    profitColor: "#5DDB1C",
    descriptionText: "In Plinko",
  },
  {
    optionalText: "Hidden",
    iconSrc: "/assets/images/nano/gs2.webp",
    profitText: "Profit: $432.47",
    descriptionText: "In Mines",
  },
  {
    iconSrc: "/assets/images/nano/gs1.webp",
    optionalText: "Hidden",
    profitText: "Won $48664.27",
    profitColor: "#5DDB1C",
    descriptionText: "In Plinko",
  },
  {
    optionalText: "Hidden",
    iconSrc: "/assets/images/nano/gs2.webp",
    profitText: "Profit: $432.47",
    descriptionText: "In Mines",
  },
  {
    iconSrc: "/assets/images/nano/gs1.webp",
    optionalText: "Hidden",
    profitText: "Won $48664.27",
    profitColor: "#5DDB1C",
    descriptionText: "In Plinko",
  },
  {
    optionalText: "Hidden",
    iconSrc: "/assets/images/nano/gs2.webp",
    profitText: "Profit: $432.47",
    descriptionText: "In Mines",
  },
];

const GsCard = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % cardData.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const translateY = -currentIndex * 72; // 62px card height + 10px margin

  return (
    <GSContainer>
      <ScrollWrapper translateY={translateY}>
        {[...cardData, ...cardData].map((card, index) => (
          <GSCard key={index}>
            <GSIconPlaceholder src={card.iconSrc} />
            <GSTextContainer>
              {card.optionalText && (
                <OptionalText>{card.optionalText}</OptionalText>
              )}
              <GSProfitText>
                {card.profitText.includes("Won") ? (
                  <>
                    Won{" "}
                    <span style={{ color: card.profitColor }}>
                      {card.profitText.split(" ")[1]}
                    </span>
                  </>
                ) : (
                  card.profitText
                )}
              </GSProfitText>
              <GSDescriptionText>{card.descriptionText}</GSDescriptionText>
            </GSTextContainer>
          </GSCard>
        ))}
      </ScrollWrapper>
    </GSContainer>
  );
};

export default GsCard;
