import React from "react";
import { Row, Col } from "react-bootstrap";
import UAParser from "ua-parser-js";
import {
  decode,
  encode,
  wait,
  isEmail,
  sendNotfication,
} from "../../../../Helper";

class ActiveSessions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
    };
  }

  componentDidMount() {
    var parser = new UAParser();
    var result = parser.getResult();
    var info = `Browser: ${result.browser.name} Version: ${result.browser.version} / OS: ${result.os.name} Version: ${result.os.version} / User Agent: ${result.ua}`;
    this.setState({ result: info });
  }

  render() {
    return (
      <>
        <Row>
          <Col md={12}>
            <div
              style={{
                color: "white",
                backgroundColor: "#2c3137",
                padding: "10px",
                borderRadius: "10px",
              }}
              className="cs2 font-14 l30"
            >
              {this.state.result}
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default ActiveSessions;
