// import styled from "styled-components";

// const DashboardGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 1rem;
//   padding: 1rem;
//   background-color: #f3f4f6;
// `;

// const DashboardItemWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 1rem;
//   background-color: white;
//   border-radius: 0.5rem;
//   box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
// `;

// const ItemIcon = styled.img`
//   width: 4rem;
//   height: 4rem;
//   margin-bottom: 0.5rem;
// `;

// const ItemValue = styled.span`
//   font-size: 1.5rem;
//   font-weight: bold;
//   color:black
// `;

// const ItemLabel = styled.span`
//   font-size: 0.875rem;
//   color: #4b5563;
// `;

// const ItemSubLabel = styled.span`
//   font-size: 0.75rem;
//   color: #9ca3af;
// `;

// const DashboardItem = ({ icon, value, label, subLabel }) => (
//     <DashboardItemWrapper>
//       <ItemIcon src={icon} alt={label} />
//       <ItemValue>{value}</ItemValue>
//       <ItemLabel>{label}</ItemLabel>
//       {subLabel && <ItemSubLabel>{subLabel}</ItemSubLabel>}
//     </DashboardItemWrapper>
//   );

// export default function AffiliateDashboard({ totalMembers = 0 ,todayRewards = 0 }){
//     return(
//         <div>

// <DashboardGrid>
//     <DashboardItem
//       icon="/assets/images/affiliateD/ad-1.webp"
//       value="0"
//       label="Unlocked"
//       subLabel="USD Rewards"
//     />
//     <DashboardItem
//      icon="/assets/images/affiliateD/ad-2.png"
//       value="0"
//       label="To be unlocked"
//     />
//     <DashboardItem
//       icon="/assets/images/affiliateD/ad-3.png"
//       value={totalMembers.toString()}
//       label="Friend Number"
//     />
//     <DashboardItem
//     icon="/assets/images/affiliateD/ad-4.png"
//       value={`${todayRewards} USD`}
//       label="Today's Commission Rewards (Approx.)"
//     />
//   </DashboardGrid>
//         </div>
//     )
// }
import styled from "styled-components";

import { useEffect, useState } from "react";
import storage from "../../Storage";
import { getUID } from "../../Helper";

// Existing styled components from before
const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem;
  background-color: #f3f4f6;
`;

const DashboardItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
`;

// New styled components for close button
const DashboardContainer = styled.div`
  position: relative;
  padding: 20px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #000000;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;

  &:hover {
    color: #1f2937;
  }
`;

// Rest of your existing components remain the same
const ItemIcon = styled.img`
  width: 4rem;
  height: 4rem;
  margin-bottom: 0.5rem;
`;

const ItemValue = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
`;

const ItemLabel = styled.span`
  font-size: 0.875rem;
  color: #4b5563;
`;

const ItemSubLabel = styled.span`
  font-size: 0.75rem;
  color: #9ca3af;
`;
// Add these styled components at the end of your file
const MembersTableContainer = styled.div`
  margin: 20px auto;
  width: 100%;
  padding: 20px;
  background-color: #1e2024;
  border-radius: 10px;
`;

const MembersTableTitle = styled.h2`
  color: white;
  text-align: center;
  margin-bottom: 15px;
`;

const MembersCount = styled.p`
  color: #da22ff;
  text-align: center;
  margin-bottom: 15px;
`;

const MembersTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 12px;
    text-align: left;

    color: white;
  }

  th {
    background-color: #2e3034;
  }
`;

const NoMembers = styled.p`
  color: white;
  text-align: center;
  font-style: italic;
`;
const DashboardItem = ({ icon, value, label, subLabel }) => (
  <DashboardItemWrapper>
    <ItemIcon src={icon} alt={label} />
    <ItemValue>{value}</ItemValue>
    <ItemLabel>{label}</ItemLabel>
    {subLabel && <ItemSubLabel>{subLabel}</ItemSubLabel>}
  </DashboardItemWrapper>
);

export default function AffiliateDashboard() {
  const [members, setMembers] = useState([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [ownerName, setOwnerName] = useState("");
  const [todayRewards, setTodayRewards] = useState(0);
  const [token, setToken] = useState(storage.getKey("token") || null);
  const [uid, setUid] = useState(getUID);
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (token !== null) {
      setLogged(true);
    }
    // getRefrelCode();
    // getRefrelLink();
    getMembers();
    getTodayRewards();
  }, [token]);

  const getMembers = async () => {
    try {
      const response = await fetch(
        `https://api.bidwinx.com/api/members/${getUID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setOwnerName(data.ownername);
        setTotalMembers(data.totalMembers);
        setMembers(data.members);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.log("error fetching members", error);
    }
  };
  const getTodayRewards = async () => {
    try {
      const response = await fetch(
        `https://api.bidwinx.com/api/rewards/${getUID}?filter=today`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setTodayRewards(data.totalAmount);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.log("error fetching today's rewards", error);
    }
  };

  return (
    <>
      <DashboardContainer>
        {/* <CloseButton onClick={onClose}>×</CloseButton> */}
        <DashboardGrid>
          <DashboardItem
            icon="/assets/images/affiliateD/ad-1.webp"
            value="0"
            label="Unlocked"
            subLabel="USD Rewards"
          />
          <DashboardItem
            icon="/assets/images/affiliateD/ad-2.png"
            value="0"
            label="To be unlocked"
          />
          <DashboardItem
            icon="/assets/images/affiliateD/ad-3.png"
            value={totalMembers.toString()}
            label="Friend Number"
          />
          <DashboardItem
            icon="/assets/images/affiliateD/ad-4.png"
            value={`${todayRewards} USD`}
            label="Today's Commission Rewards (Approx.)"
          />
        </DashboardGrid>
      </DashboardContainer>
      <MembersTableContainer>
        <MembersTableTitle>Your Friends</MembersTableTitle>
        <MembersCount>Total Friends: {totalMembers}</MembersCount>
        {members && members.length > 0 ? (
          <MembersTable>
            <thead>
              <tr>
                <th>Friend Name</th>
                <th>Commision Recieved</th>
              </tr>
            </thead>
            <tbody>
              {members &&
                members.length > 0 &&
                members.map((member, index) => (
                  <tr key={index}>
                    <td>{member.membername}</td>
                    <td>{member.total_commission}</td>
                  </tr>
                ))}
            </tbody>
          </MembersTable>
        ) : (
          <NoMembers>No referrals yet</NoMembers>
        )}
      </MembersTableContainer>
    </>
  );
}
