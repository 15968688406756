import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Search } from "lucide-react";
import { Col } from "react-bootstrap";
import Login from "../../Pages/Auth/Login";
import Register from "../../Pages/Auth/Register";

// import { isMobile } from '../Helper';

const HeaderContainer = styled.header`
  background-color: #24262b !important;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 200px;
  right: 0;
  z-index: 1000;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #1b1e22;
  border-radius: 30px;
  padding: 12px 16px;
  width: 100%;
  max-width: 600px;
  transition: outline 0.2s ease-in-out;
  border: ${(props) =>
    props.isFocused ? "2px solid #da22ff" : "2px solid transparent"};
`;

const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  margin-left: 8px;
  outline: none;

  &::placeholder {
    color: #a0a0a0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 5px 15px;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
`;

const SignInButton = styled(Button)`
  background-color: #da22ff;
`;

const SignUpButton = styled(Button)`
  background-color: #2196f3;
`;

const RightWrapper = styled(Button)`
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #24262b !important;
`;

const Header = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [header, setHeader] = useState(null);
  const [show, setShow] = useState(true);
  const [avatar] = useState("/assets/images/avatar.png");

  //   useEffect(() => {
  //     const handleResize = () => {
  //       if (!isMobile()) {
  //         setHeader(desktop());
  //       } else {
  //         setHeader(mobile());
  //       }
  //     };

  //     handleResize();
  //     window.addEventListener("resize", handleResize);

  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //     };
  //   }, []);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const collapse = () => {
    setShow(!show);
    Event.emit("toggle_sidebar", show);
  };

  return (
    <HeaderContainer>
      <Logo>NANOGAMES.IO</Logo>
      <SearchBarContainer isFocused={isFocused}>
        <Search size={18} color="#a0a0a0" />
        <SearchInput
          placeholder="Game name | Provider | Category Tag"
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </SearchBarContainer>
      <RightWrapper>
        <Login />
        <Register />
      </RightWrapper>
    </HeaderContainer>
  );
};

export default Header;
