import React, { Component, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import socket from "../../../Socket";
import storage from "../../../Storage";
import {
  decode,
  encode,
  getElement,
  sendNotfication,
  isEmail,
  Event,
  DEVELOPMENT,
  wait,
  randomString,
} from "../../../Helper";
import C from "../../../Constant";
import axios from "axios";
import * as countriesData from "countries-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #1e2124;
    color: white;
    border-radius: 18px;
    overflow: hidden;
  }
`;

const ModalHeader = styled.div`
  background-color: #da22ff;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

const ModalBody = styled(Modal.Body)`
  padding: 20px;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#2b2f33",
    borderColor: "#3a3f44",
    borderRadius: "12px",
    color: "white", // Set the color of the selected value to white
    padding: "14px",
    minHeight: "26px",
    "&:hover": {
      borderColor: "#DA22FF",
    },
    "&:focus": {
      outline: "none",
      borderColor: "#DA22FF",
      boxShadow: "0 0 0 2px rgba(67, 179, 11, 0.2)",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#9a9a9a", // Set the placeholder text color to a light gray
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#2b2f33",
    borderRadius: "12px",
    borderColor: "#3a3f44",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#DA22FF" : "#2b2f33",
    color: "white", // Set the text color of the options to white
    borderRadius: "8px",
    padding: "8px",
    "&:hover": {
      backgroundColor: "#DA22FF",
      color: "white",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white", // Set the color of the selected value to white
  }),
};

const StyledInput = styled.input`
  background-color: #2b2f33;
  border: 1px solid #3a3f44;
  border-radius: 12px;
  color: white;
  padding: 14px;
  &::placeholder {
    color: #6c757d;
  }
  &:focus {
    outline: none;
    border-color: #da22ff;
    box-shadow: 0 0 0 2px rgba(67, 179, 11, 0.2);
  }
`;

const Labeltext = styled.div`
  color: #696f79;
  font-size: 14px;
  display: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const Button = styled.button`
  padding: 12px 30px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
`;

const SignInButton = styled(Button)`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
  border: 1px solid #da22ff;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 150px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }

  &:hover {
    color: #fff;
    background-size: 200% auto;
    text-align: center;
    background-image: linear-gradient(
      to right,
      #da22ff 0%,
      #9733ee 51%,
      #da22ff 100%
    );
  }
`;

const SignUpButton = styled(Button)`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
  background-image: linear-gradient(
    to right,
    #da22ff 0%,
    #9733ee 51%,
    #da22ff 100%
  );
  border: none;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 150px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }

  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }
`;

const StyledSignUpButton = styled.div`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
  background-image: linear-gradient(
    to right,
    #da22ff 0%,
    #9733ee 51%,
    #da22ff 100%
  );
  border: none;
  border-radius: 10px;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;

  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px 15px;
    color: #fff !important;
    border: 1px solid #da22ff;
  }
`;

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: storage.getKey("logged"),
      show: this.props.show || false,
      username: "",
      email: "",
      password: "",
      defaultCountry: "AF",
      phoneNumber: "",
      referral: this.props.referral || "",
      ruleChecked: false,
      submitted: false,
      disabled: false,
      disableVerifyButton: false,
      showReferralInput: false,
      effect: "zoomIn",
      sendingOTP: false,
      verifyingOTP: false,
      otp: "",
      encodedOTP: "",
    };
  }

  componentDidMount() {
    socket.on(C.REGISTER_USER, (data) => this.setRegister(decode(data)));
    Event.on("register_form", this.handleShow);

    const language = navigator.language || navigator.userLanguage;
    const country = language.split("-")[1]?.toLowerCase();
    if (country && countriesData.countries[country.toUpperCase()]) {
      this.setState({ defaultCountry: country });
    }
  }

  setRegister = (data) => {
    if (data.error) {
      this.setState({
        disabled: false,
        disableVerifyButton: false,
        submitted: false,
        verifyingOTP: false,
      });
      return sendNotfication(data.error, "error", "bottom-left");
    }

    if (data.status) {
      console.log("Data received:", data);
      this.setState({ submitted: false, verifyingOTP: false });
      wait(1000).then(() => {
        socket.emit(
          C.LOGIN_USER,
          encode({
            username: data.name,
            password: data.password,
            recaptcha: "google",
          })
        );
      });
    }
  };

  handleShow = () => {
    this.setState({ show: true, effect: "zoomIn" });
  };

  handleClose = () => {
    this.setState({
      show: false,
      effect: "zoomOut",
      disabled: false,
      disableVerifyButton: false,
      submitted: false,
      ruleChecked: false,
      sendingOTP: false,
      verifyingOTP: false,
      otp: "",
      encodedOTP: "",
    });
  };

  verifyReferralCode = async () => {
    try {
      const response = await axios.get(
        `https://api.bidwinx.com/verify-referral-code/${this.state.referral}`
      );
      console.log("Referral response:", response);
      return response.data.valid;
    } catch (error) {
      console.error("Referral code verification error:", error);
      return false;
    }
  };

  sendOTP = async () => {
    const { email } = this.state;
    if (!isEmail(email)) {
      sendNotfication(
        "Please enter a valid email address",
        "error",
        "bottom-left"
      );
      return;
    }

    this.setState({ sendingOTP: true, disabled: true });
    try {
      const response = await axios.post(
        "https://api.bidwinx.com/send-register-otp",
        { to: email }
      );
      if (response.data.success) {
        this.setState({
          encodedOTP: response.data.encodedOtp,
          sendingOTP: false,
          verifyingOTP: true,
          disabled: false,
          disableVerifyButton: false,
        });
        sendNotfication("OTP sent to your email", "success", "bottom-left");
      } else {
        this.setState({ sendingOTP: false, disabled: false });
        sendNotfication("Failed to send OTP", "error", "bottom-left");
      }
    } catch (error) {
      this.setState({ sendingOTP: false, disabled: false });
      console.error("Error sending OTP:", error);
      sendNotfication("Failed to send OTP", "error", "bottom-left");
    }
  };

  verifyOTP = async () => {
    const { otp, encodedOTP } = this.state;
    if (!otp) {
      sendNotfication("Please enter the OTP", "error", "bottom-left");
      return;
    }
    this.setState({ verifyingOTP: true, disableVerifyButton: true });
    try {
      const response = await axios.post("https://api.bidwinx.com/verify-otp", {
        otp,
        encodedOtp: encodedOTP,
      });
      if (response.data.success) {
        const {
          username,
          password,
          email,
          referral,
          phoneNumber,
          selectedCountry,
        } = this.state;
        await socket.emit(
          C.REGISTER_USER,
          encode({
            username,
            password,
            email,
            method: true,
            refree: referral || "",
            phone: phoneNumber || "",
            aff: storage.getKey("aff") || null,
          })
        );
        this.setState({ verifyingOTP: false, disableVerifyButton: false });
      } else {
        this.setState({ disableVerifyButton: false });
        sendNotfication("Invalid OTP", "error", "bottom-left");
      }
    } catch (error) {
      this.setState({ verifyingOTP: false, disableVerifyButton: false });
      console.error("Error verifying OTP:", error);
      sendNotfication("Failed to verify OTP", "error", "bottom-left");
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ submitted: true, disabled: true });

    const { username, email, password, ruleChecked, referral, phoneNumber } =
      this.state;

    if (referral && referral.trim()) {
      const verified = await this.verifyReferralCode();
      if (!verified) {
        this.setState({ disabled: false });
        sendNotfication(
          "Please enter a valid referral code",
          "error",
          "bottom-left"
        );
        return;
      }
    }

    if (!(username && password && email && ruleChecked && phoneNumber)) {
      this.setState({ disabled: false });
      return;
    }

    if (username.length < 5) {
      this.setState({ disabled: false });
      sendNotfication(
        "Username must be at least 5 characters",
        "error",
        "bottom-left"
      );
      return;
    }

    if (phoneNumber == "" || phoneNumber == null) {
      this.setState({ disabled: false });
      sendNotfication("Please enter phone number", "error", "bottom-left");
      return;
    }

    if (!isEmail(email)) {
      this.setState({ disabled: false });
      sendNotfication(
        "Please enter a valid email address",
        "error",
        "bottom-left"
      );
      return;
    }

    await this.sendOTP();
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  checkRule = () => {
    this.setState((prevState) => ({ ruleChecked: !prevState.ruleChecked }));
  };

  login = () => {
    this.handleClose();
    Event.emit("login_form");
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handlePhoneChange = (phone) => {
    this.setState({ phoneNumber: phone });
  };

  handleToggleReferralInput = () => {
    this.setState((prevState) => ({
      showReferralInput: !prevState.showReferralInput,
    }));
  };
  render() {
    const {
      show,
      username,
      email,
      password,
      ruleChecked,
      showReferralInput,
      disabled,
      disableVerifyButton,
      referral,
      defaultCountry,
      phoneNumber,
      sendingOTP,
      verifyingOTP,
      otp,
    } = this.state;

    return (
      <>
        <StyledSignUpButton onClick={this.handleShow}>
          Sign up
        </StyledSignUpButton>
        <StyledModal
          size="md"
          centered
          show={show}
          onHide={this.handleClose}
          aria-labelledby="register-modal"
          className={`animated ${this.state.effect}`}
        >
          <ModalHeader>
            <CloseButton onClick={this.handleClose}>×</CloseButton>
          </ModalHeader>
          <ModalBody>
            <div className="mb-2 text-center">
              <h3
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  margin: "unset",
                }}
              >
                Let's Build the Ultimate Casino Together
              </h3>
            </div>
            <StyledForm onSubmit={this.handleSubmit}>
              {!verifyingOTP && (
                <>
                  <Labeltext>Username</Labeltext>
                  <StyledInput
                    type="text"
                    name="username"
                    value={username}
                    onChange={this.handleInputChange}
                    placeholder="Username"
                  />
                  <Labeltext>Email Address</Labeltext>
                  <StyledInput
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleInputChange}
                    placeholder="Email Address"
                  />
                  <Labeltext>Password</Labeltext>
                  <StyledInput
                    type="password"
                    name="password"
                    value={password}
                    onChange={this.handleInputChange}
                    placeholder="Password"
                  />
                  {/* <Labeltext>Country</Labeltext>
                  <Select
                    name="selectedCountry"
                    options={this.state.countryOptions}
                    value={this.state.selectedCountry}
                    onChange={this.handleCountryChange}
                    styles={customStyles}
                    className="w-32"
                    classNamePrefix="select"
                    placeholder=""
                  /> */}
                  <Labeltext>Phone Number</Labeltext>
                  {/* <StyledInput
                    type="text"
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    onChange={this.handleInputChange}
                    placeholder="Phone Number"
                  /> */}
                  <PhoneInput
                    country={defaultCountry}
                    value={phoneNumber}
                    onChange={this.handlePhoneChange}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                  />

                  {/* Referral Code Toggle */}
                  <div
                    type="button"
                    className="m-0"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      width: "max-content",
                      marginLeft: "5px !important",
                    }}
                    onClick={this.handleToggleReferralInput}
                  >
                    <label
                      htmlFor="toggleReferral"
                      style={{
                        marginBottom: "0px",
                      }}
                    >
                      Referral Code
                    </label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#DA22FF"
                      class="bi bi-chevron-down"
                      viewBox="0 0 18 18"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </div>

                  {/* Referral Code Input */}
                  {showReferralInput && (
                    <StyledInput
                      type="text"
                      name="referral"
                      value={referral}
                      onChange={this.handleInputChange}
                      placeholder="Referral Code"
                      className="m-0"
                    />
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="ruleCheck"
                      checked={ruleChecked}
                      onChange={this.checkRule}
                    />
                    <label
                      htmlFor="ruleCheck"
                      style={{ margin: "0px", fontSize: "12px" }}
                    >
                      I agree with the user agreement
                    </label>
                  </div>
                  <ButtonContainer>
                    <SignUpButton
                      id="signupBtn"
                      type="submit"
                      disabled={disabled || sendingOTP}
                    >
                      {(disabled || sendingOTP) && (
                        <span
                          className="mr-1 spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      {sendingOTP ? "Sending OTP" : "Sign Up"}
                    </SignUpButton>
                    <SignInButton type="button" onClick={this.login}>
                      Sign In
                    </SignInButton>
                  </ButtonContainer>
                </>
              )}

              {verifyingOTP && (
                <>
                  <Labeltext>Enter OTP</Labeltext>
                  <StyledInput
                    type="text"
                    name="otp"
                    value={otp}
                    onChange={this.handleInputChange}
                    placeholder="Enter OTP"
                  />
                  <ButtonContainer>
                    <SignUpButton
                      id="verifyBtn"
                      type="button"
                      disabled={disableVerifyButton}
                      onClick={this.verifyOTP}
                    >
                      {disableVerifyButton && (
                        <span
                          className="mr-1 spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      Verify OTP
                    </SignUpButton>
                    <SignInButton type="button" onClick={this.login}>
                      Sign In
                    </SignInButton>
                  </ButtonContainer>
                </>
              )}
            </StyledForm>
          </ModalBody>
        </StyledModal>
      </>
    );
  }
}

export default Register;
