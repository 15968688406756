import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSwipeable } from "react-swipeable";

// Styled components
const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  overflow: hidden;

  @media (max-width: 911px) {
    margin-top: 0rem;
  }
`;

const CarouselTrack = styled.div`
  display: flex;
  transition: transform 0.3s ease-in-out;
  transform: translateX(${(props) => props.translate}%);
  width: 100%;

  @media (min-width: 911px) {
    gap: 0.5rem;
  }
`;

const CarouselItem = styled.div`
  flex: 0 0 ${(props) => 100 / props.itemsPerPage}%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex: 0 0 100%; /* Show only one card on smaller screens */
  }
`;

const ImageCard = styled.div`
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    width: ${({ width }) => width || "100%"};
    height: ${({ height }) => height || "100%"};
    object-fit: cover;
    border-radius: 10px;
  }
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  @media (min-width: 911px) {
    display: none;
  }
`;

const Dot = styled.button`
  background-color: ${(props) => (props.active ? "#bbb" : "#333")};
  border: none;
  width: 2px !important;
  height: 3px !important;
  border-radius: 100%;
  margin: 0 5px;
  cursor: pointer;
  opacity: 0.25;

  &:hover {
    background-color: #333;
  }
`;

const carouselItems = [
  {
    url: "/assets/images/carousel/bidwinx-games.png",
    width: "100%",
    height: "100%",
  },
  { url: "/assets/images/carousel/4.png", width: "100%", height: "100%" },
  {
    url: "/assets/images/carousel/bid-winX-play-bold-big-win-banner.png",
    width: "100%",
    height: "100%",
  },
  {
    url: "/assets/images/carousel/bidwinx-lottery.png",
    width: "100%",
    height: "100%",
  },
  {
    url: "/assets/images/carousel/bidwinx-vip-level.png",
    width: "100%",
    height: "100%",
  },
  {
    url: "/assets/images/carousel/bidwinx-games.png",
    width: "100%",
    height: "100%",
  },
  { url: "/assets/images/carousel/4.png", width: "100%", height: "100%" },
  {
    url: "/assets/images/carousel/bid-winX-play-bold-big-win-banner.png",
    width: "100%",
    height: "100%",
  },
  {
    url: "/assets/images/carousel/bidwinx-lottery.png",
    width: "100%",
    height: "100%",
  },
  {
    url: "/assets/images/carousel/bidwinx-vip-level.png",
    width: "100%",
    height: "100%",
  },
];

const CustomSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  // Update itemsPerPage based on window width
  useEffect(() => {
    const updateItemsPerPage = () => {
      if (window.innerWidth <= 991) {
        setItemsPerPage(1); // Show 1 item per page on smaller screens
      } else if (window.innerWidth <= 768) {
        setItemsPerPage(1); // Show 1 item per page on medium screens
      } else {
        setItemsPerPage(5); // Show 5 items per page on large screens
      }
    };

    updateItemsPerPage(); // Call the function to set the initial value

    window.addEventListener("resize", updateItemsPerPage);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);

  // Handle swipe left or right
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
  });

  const handlePrev = () => {
    setCurrentIndex((prev) =>
      prev === 0 ? carouselItems.length - itemsPerPage : prev - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prev) =>
      prev >= carouselItems.length - itemsPerPage ? 0 : prev + 1
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  // Auto slide every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 2000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <CarouselContainer {...handlers}>
      <CarouselTrack translate={-currentIndex * (100 / itemsPerPage)}>
        {carouselItems.map((item, index) => (
          <CarouselItem key={index} itemsPerPage={itemsPerPage}>
            <ImageCard width={item.width} height={item.height}>
              <img src={item.url} alt={`carousel item ${index}`} />
            </ImageCard>
          </CarouselItem>
        ))}
      </CarouselTrack>

      {/* Dots */}
      <DotContainer>
        {carouselItems.slice(0, carouselItems.length).map((_, index) => (
          <Dot
            key={index}
            active={currentIndex === index}
            onClick={() => goToSlide(index)}
          />
        ))}
      </DotContainer>
    </CarouselContainer>
  );
};

export default CustomSlider;
