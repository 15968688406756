import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Dropdown, Modal, Row, Col } from "react-bootstrap";
import PerfectScrollbar from "perfect-scrollbar";
import coins from "../../../coins";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import { setCoin, gameCoin, setCredit } from "../../../../actions/gameCoin";
import {
  __,
  wait,
  decode,
  encode,
  forceSatoshiFormat,
  Event,
  getUID,
} from "../../../../Helper";
import C from "../../../../Constant";
import styled from "styled-components";
// import {  } from "lucide-react";
import {
  Search,
  Wallet,
  ArrowDownUp,
  Vault,
  ChevronRight,
  WalletCards,
  X,
} from "lucide-react";
import Deposit from "../../../Components/User/Wallet/Deposit";
import DepositHello from "../../../Components/User/Wallet/Deposithello";
import WithdrawlHello from "../../../Components/User/Wallet/Withdrawhello";
import SwapHello from "../../../Components/User/Wallet/SwapHello";
import CoinSelectionModal from "../../../Components/CoinSelectionModal";

import axios from "axios";

const Container = styled.div`
  background-color: #1e2024;
  display: flex;
  border-radius: 26px;
  justify-content: flex-end;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
`;

const Label = styled.div`
  display: block;
  margin-bottom: -5px;
  font-size: 14px;
  color: #373b40;
  font-family: normal;
`;

const CubBalance = styled.div`
  color: #99999;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
`;
const WalletConatiner = styled.div`
  //   color: #99999;
  //   font-size: 14px;
  //   font-weight: bold;
  //   margin-right: 10px;
`;

const WalletButton = styled.button`
  background-color: #7b61ff;
  border: none;
  border-radius: 20px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
`;
const ManageListButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7438f7;
  border-radius: 30px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  margin-bottom: 10px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  span {
    margin-right: 8px;
  }
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #dfdfdf; /* Light gray, you can adjust as needed */
  margin: 20px 0; /* Adds some spacing above and below */
`;
const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #222327;
  border-radius: 13px;
  padding: 5px 10px;

  input {
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: #333;
    flex: 1;
    padding-left: 8px;

    &::placeholder {
      color: #999;
    }
  }
`;

const ResponsiveModal = styled(Modal)`
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 90%;
      width: 400px;
    }
    @media (min-width: 768px) {
      max-width: 85%;
      width: 500px;
    }
    @media (min-width: 992px) {
      max-width: 80%;
      width: 600px;
    }
    @media (min-width: 1200px) {
      max-width: 75%;
      width: 500px;
    }
  }
`;

const ModalHeader = styled(Modal.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
`;

const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const ModalSubtitle = styled.div`
  font-size: 14px;
  color: #616770;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  padding: 0.25rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1e2024;
  border-radius: 14px;
  overflow: hidden;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 32px;
  padding: 8px;

  @media (max-width: 1200px) {
    gap: 24px;
  }

  @media (max-width: 768px) {
    gap: 12px;
    padding: 6px;
  }

  @media (max-width: 480px) {
    gap: 4px;
    padding: 4px;
  }
`;

const NavButton = styled.button`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.active ? "#da22ff" : "transparent")};
  color: ${(props) => (props.active ? "white" : "#8c8c8c")};
  border: none;
  padding: 12px 20px;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;
  flex: 1;
  min-width: 0;

  &:hover {
    background-color: ${(props) => (props.active ? "#da22ff" : "#2c2c2c")};
  }

  @media (max-width: 768px) {
    padding: 10px 16px;
    font-size: 12px;
    border-radius: 12px;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    font-size: 11px;
    border-radius: 10px;
  }
`;

const IconText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.active ? "white" : "#8c8c8c")};
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  gap: 8px;

  @media (max-width: 768px) {
    gap: 6px;
  }

  @media (max-width: 480px) {
    gap: 4px;
  }
`;
const ContentArea = styled.div`
  background-color: #1e2024;
  color: white;
  border-radius: 14px;
  padding: 12px 8px;
`;

const ContainerDeposit = styled.div`
  background-color: #2c2c2c;
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  max-width: 400px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  color: #8c8c8c;
  font-size: 14px;
  margin-bottom: 12px;
`;

const CurrencyBox = styled.div`
  background-color: #25282c;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

const CurrencyInfo = styled.div`
  display: flex;
  align-items: center;
`;

const CurrencyIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: #da22ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-weight: bold;
  color: white;
`;

const CurrencyName = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: white;
`;

const BalanceInfo = styled.div`
  text-align: right;
`;

const BalanceLabel = styled.div`
  font-size: 12px;
  color: #8c8c8c;
`;

const BalanceAmount = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: white;
`;

const ArrowIcon = styled.span`
  color: #8c8c8c;
  font-size: 18px;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const LabelSwap = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  color: #676f78;
  //   font-family:normal;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 20px;
  background-color: #25282c;
  border: 1px solid #3a3a3a;
  border-radius: 20px;
  color: #ffffff;
  font-size: 12px;
`;

const ButtonGroup = styled.div`
  position: absolute;
  right: 3px;
  display: flex;
  align-items: center;
`;

const ButtonWithdrawl = styled.button`
  background-color: #32363f;
  border: none;
  color: #a0a0a0;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  margin-right: 10px;

  &:hover {
    background-color: #3a3a3a;
  }
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1a202c;
  padding: 1rem;
`;

const FeeText = styled.div`
  color: #a0aec0;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;

const GreenText = styled.span`
  color: #da22ff;
`;

const StyledButton = styled.button`
  background-color: #da22ff;
  color: white;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  width: 100%;
  max-width: 20rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #da22ff;
    transform: scale(1.05);
  }
`;
const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SwitcherContainer = styled.div`
  display: flex;
  background-color: #2b2e36;
  border-radius: 9999px;
  width: 256px;
  justify-content: center;
`;

const SwitcherButton = styled.div`
  flex: 1;
  padding: 8px 16px;
  border-radius: 9999px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  background-color: ${(props) => (props.active ? "#3C3F49" : "#2B2E36")};
  cursor: pointer;

  &:hover {
    background-color: #444854; /* Add a hover effect if needed */
  }
`;
// Styled components
// const Container1 = styled.div`
// //   background-color: #1f2937;
//   color: white;
//   padding: 16px;
//   border-radius: 12px;
//   width: 100%;
// `;

// const HeaderButtonGroup = styled.div`
//   display: flex;
//   background-color: #292C32;
//   border-radius: 50px;
//   margin-bottom: 16px;
//   width: 60%;
// `;

const HeaderButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 50px;
  transition: all 0.3s ease;
  text-align: center;
  ${(props) =>
    props.active
      ? `
        background-color: #3B5432;
        color: white;
      `
      : `
        background-color: transparent;
        color: #9ca3af;
        &:hover {
          background-color: #3B5432;
          color: white;
        }
      `}
`;

// const TableHeader = styled.div`
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
//   gap: 16px;
//   color: #9ca3af;
//   font-size: 14px;
//   margin-bottom: 16px;
// `;

// const TableRow = styled.div`
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
//   gap: 16px;
//   font-size: 14px;
//   color: white;
//   margin-bottom: 12px;
// `;
const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #17181b;
    border-radius: 15px;
    border: none;
    height: 60vh;
  }
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 90%;
      width: 400px;
    }
    @media (min-width: 768px) {
      max-width: 85%;
      width: 500px;
    }
    @media (min-width: 992px) {
      max-width: 80%;
      width: 600px;
    }
    @media (min-width: 1200px) {
      max-width: 75%;
      width: 800px;
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0 20px 20px;
  background-color: #17181b;
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px 0;
`;

const PlaceholderImage = styled.img`
  margin-bottom: 16px;
  width: 100px;
  height: 100px;
`;

const PlaceholderText = styled.p`
  color: #9ca3af;
`;

const TableCell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0; // This is crucial for text-overflow to work
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  color: #9ca3af;
  font-size: 14px;
  margin-bottom: 16px;
  padding-right: 8px;

  @media (max-width: 768px) {
    font-size: 12px;
    gap: 8px;
  }
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  font-size: 14px;
  color: white;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    font-size: 12px;
    gap: 8px;
  }
`;

const Container1 = styled.div`
  color: white;
  padding: 16px;
  border-radius: 12px;
  width: 100%;
  overflow-x: hidden;
`;

const HeaderButtonGroup = styled.div`
  display: flex;
  background-color: #292c32;
  border-radius: 50px;
  margin-bottom: 16px;
  width: 60%;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

// Main ModalBody content
const ModalContent = () => {
  const [activeTab, setActiveTab] = useState("Deposit");
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch API data based on the active tab and uid
  const fetchData = async (tab) => {
    const uid = getUID;
    setError(null);

    try {
      const response = await axios.get(
        `https://api.bidwinx.com/api/${tab.toLowerCase()}New`,
        {
          params: { uid }, // Pass the UID as a query parameter
        }
      );
      console.log("API Response:", response.data);
      setTransactionData(response.data);
    } catch (err) {
      console.error("API call failed", err);
      setError("Failed to fetch data.");
      setTransactionData([]);
    } finally {
      setLoading(false);
    }
  };
  const formatDateTime = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options); // Format based on locale
  };
  // Fetch data whenever activeTab changes
  useEffect(() => {
    fetchData(activeTab);
  }, [activeTab]);

  return (
    <Container1>
      {/* Header Buttons */}
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <HeaderButtonGroup>
          <HeaderButton
            active={activeTab === "Deposit"}
            onClick={() => setActiveTab("Deposit")}
          >
            Deposit
          </HeaderButton>
          <HeaderButton
            active={activeTab === "Withdraw"}
            onClick={() => setActiveTab("Withdraw")}
          >
            Withdraw
          </HeaderButton>
        </HeaderButtonGroup>
      </div>

      {/* Content */}
      {loading ? (
        <EmptyState>
          <PlaceholderText>Loading data...</PlaceholderText>
        </EmptyState>
      ) : error ? (
        <EmptyState>
          <PlaceholderText>{error}</PlaceholderText>
        </EmptyState>
      ) : transactionData.length === 0 ? (
        <EmptyState>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
              width: "100%",
            }}
          >
            <img
              src="/assets/images/emptyPanda.webp"
              style={{ height: "400px", width: "200px", objectFit: "contain" }}
            />
            <PlaceholderText>No transactions available.</PlaceholderText>
          </div>
        </EmptyState>
      ) : (
        <>
          <div style={{ height: "300px" }}>
            {/* Table Header */}
            <TableHeader style={{ color: "#9ca3af" }}>
              <div>Date</div>
              <div>Amount</div>
              <div>Status</div>
              <div>
                {activeTab === "Deposit" ? "Transaction" : "Address"}
              </div>{" "}
              {/* For deposit it's orderid, for withdraw it's wallet */}
            </TableHeader>

            {/* Table Rows */}
            {transactionData.map((transaction, index) => (
              <TableRow style={{ color: "#9ca3af" }} key={index}>
                <div>
                  {activeTab === "Deposit"
                    ? formatDateTime(transaction.created_at)
                    : formatDateTime(transaction.date)}
                </div>{" "}
                {/* Time */}
                <div>{transaction.amount}</div> {/* Amount */}
                <div>{transaction.status}</div> {/* State */}
                <div>
                  {activeTab === "Deposit"
                    ? transaction.orderid
                    : transaction.wallet}
                </div>{" "}
                {/* Transaction */}
              </TableRow>
            ))}
          </div>
        </>
      )}
    </Container1>
  );
};

class UserCreditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showManage: false,
      isTransferIn: true,
      token: storage.getKey("token"),
      coin: storage.getKey("coin") ? storage.getKey("coin") : "INR",
      activeClientCoins: storage.getKey("active_coins")
        ? storage.getKey("active_coins")
        : null,
      selectedCoin: storage.getKey("coin"),
      credits: {},
      coins: [],
      uid: getUID,
      manage_coins: [],
      different: [],
      credit: false,
      usd: storage.getKey("usd") === "true" ? true : false,
      searchTerm: "",
      isModalOpen: false,
      activeSection: "deposit",
      selectedCoinSwapSend: "INR",
      creditSWapSend: 0,
      isModalOpenSwapSend: false,
      selectedCoinSwapApprox: "INR",
      creditSWapApprox: 0,
      isModalOpenSwapApprox: false,
      selectedCoinTransferIn: "INR",
      creditTransferIn: 0,
      isModalOpenTransferIn: false,
      selectedCoinTransferOut: "INR",
      creditTransferOut: 0,
      isModalOpenTransferOut: false,
    };
    this.selectCoin = this.selectCoin.bind(this);
    this.wrapperRef = React.createRef();
    // this.handleClickOutside = this.handleClickOutside.bind(this)
    this.sections = [
      { id: "deposit", label: "Deposit", icon: Wallet },
      { id: "withdraw", label: "Withdraw", icon: WalletCards },
      { id: "swap", label: "Swap", icon: ArrowDownUp },
      { id: "vaultpro", label: "Vault Pro", icon: Vault },
    ];
  }

  setActiveSection = (sectionId) => {
    this.setState({ activeSection: sectionId });
  };
  toggleModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  handleCoinTranserferIn = (coin) => {
    this.setState({ selectedCoinTransferIn: coin });
  };

  handleCreditTransferIn = (newCredit) => {
    this.setState({ creditTransferIn: newCredit });
  };

  openModalTransferIn = () => {
    this.setState({ isModalOpenTransferIn: true });
  };

  closeModalTransferIn = () => {
    this.setState({ isModalOpenTransferIn: false });
  };

  handleCoinTranserferOut = (coin) => {
    this.setState({ selectedCoinTransferOut: coin });
  };

  handleCreditTransferOut = (newCredit) => {
    this.setState({ creditTransferOut: newCredit });
  };

  openModalTransferOut = () => {
    this.setState({ isModalOpenTransferOut: true });
  };

  closeModalTransferOut = () => {
    this.setState({ isModalOpenTransferOut: false });
  };

  handleCoinChange = (coin) => {
    this.setState({ selectedCoinSwapSend: coin });
  };

  handleCreditChange = (newCredit) => {
    this.setState({ creditSWapSend: newCredit });
  };

  openModal = () => {
    this.setState({ isModalOpenSwapSend: true });
  };

  closeModal = () => {
    this.setState({ isModalOpenSwapSend: false });
  };

  handleCoinChangeApprox = (coin) => {
    this.setState({ selectedCoinSwapApprox: coin });
  };

  handleCreditChangeApprox = (newCredit) => {
    this.setState({ creditSWapApprox: newCredit });
  };

  openModalApprox = () => {
    this.setState({ isModalOpenSwapApprox: true });
  };

  closeModalApprox = () => {
    this.setState({ isModalOpenSwapApprox: false });
  };

  componentDidMount() {
    //Get Redux Coin
    this.props.gameCoin();

    Event.on("other_coins", () => {
      this.setState({ showManage: true });
    });

    if (this.state.token !== null)
      socket.emit(
        C.CREDIT,
        encode({ token: this.state.token, coin: this.state.coin })
      );

    socket.on(C.CREDIT, (data) => this.getCredit(decode(data)));
    socket.on(C.UPDATE_CREDIT, (data) => this.updateCredit(decode(data)));

    if (this.state.activeClientCoins === null) {
      var arr = [];
      for (var i = 1; i < 8; i++) {
        arr.push(i);
      }
      storage.setKey("active_coins", arr);
    }

    this.setUpManageCoins();

    if (this.state.showManage) {
      wait(1000).then(() => {
        const ps = new PerfectScrollbar(".ccoins", {
          wheelSpeed: 1,
          suppressScrollX: true,
          wheelPropagation: true,
          minScrollbarLength: 2,
        });
        ps.update();
      });
    }

    window.addEventListener("wheel", this.handleScroll, { passive: false });
    // window.addEventListener('mousedown', this.handleClickOutside);
  }

  toggleWalletModal = (e) => {
    e.stopPropagation();
    // this.props.onToggleWalletModal();
  };

  toggleCurrencyModal = (e) => {
    e.stopPropagation();
    this.props.onToggleCurrencyModal();
  };

  setActiveSection = (sectionId) => {
    this.props.onSetActiveSection(sectionId);
  };

  handleScroll(e) {
    function sicimsc(e) {
      e = e || window.event;
      if (e.preventDefault) e.preventDefault();
      e.returnValue = false;
    }
    let creditBody = document.querySelector(".prevscrol");
    if (creditBody) {
      creditBody.scrollTop -= e.wheelDeltaY;
      sicimsc(e);
    }
  }

  // handleClickOutside(event) {
  //     if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
  //         this.setState({ show: false });
  //     }
  // }

  componentWillUnmount() {
    window.removeEventListener("wheel", this.handleScroll, { passive: false });
    // window.removeEventListener('mousedown', this.handleClickOutside);
  }

  activeCoinChange = (id, active) => {
    if (id === 1) return;
    const activeClientCoins = storage.getKey("active_coins");
    let update;

    if (!active) {
      update = activeClientCoins + "," + id;
    } else {
      update = __.replace(activeClientCoins, "," + id, "");
    }

    update = __.replace(update, null, "");

    const compareNumbers = (a, b) => a - b;

    let arr = [];

    const regex = /(\d+)/gi;
    let m;

    while ((m = regex.exec(update)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      m.forEach((match, groupIndex) => {
        match = parseFloat(match);
        arr.push(match);
      });
    }

    arr.sort(compareNumbers);
    var c = __.xor(arr);

    storage.setKey("active_coins", c);

    this.setUpManageCoins();
  };

  setUpManageCoins = () => {
    const { t } = this.props;
    let self = this;

    this.setState({ manage_coins: [] });

    const activeCoins = storage.getKey("active_coins");
    let ac = __.split(activeCoins, ",");

    coins.forEach((item, key) => {
      //Default
      if (activeCoins === null) {
        let add = (
          <Dropdown.Item key={key} className={"num-style"}>
            <div
              style={{ color: "white" }}
              className="float-left text-white ncpt"
            >
              <img
                src={"/assets/images/" + item.image}
                alt="coin"
                className={"mini-coin-12"}
              />
              {item.preffix}
            </div>
            <div className="float-right">
              <div className="custom-control custom-switch switch-success">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"switch_" + key}
                  checked={true}
                />
                <label
                  style={{ color: "white" }}
                  onClick={(e) =>
                    self.activeCoinChange(parseFloat(item.id), true)
                  }
                  className={"custom-control-label"}
                  htmlFor={"switch_" + key}
                >
                  {item.name}
                </label>
              </div>
            </div>
          </Dropdown.Item>
        );
        return self.setState((state) => ({
          manage_coins: [add, ...state.manage_coins],
        }));
      }

      let active = false;

      let check = ac.some(
        (element) => parseFloat(element) === parseFloat(item.id)
      );

      if (check) {
        active = true;
      }

      var disabled = false;

      if (item.preffix === "BTC") disabled = true;

      let add = (
        <Dropdown.Item key={key} className={"num-style"}>
          <div
            style={{ color: "white" }}
            className="float-left text-white ncpt"
          >
            <img
              src={"/assets/images/" + item.image}
              alt="coin"
              className={"mini-coin-12"}
            />
            {item.preffix}
          </div>
          <div className="float-right">
            <div className="custom-control custom-switch switch-success">
              <input
                type="checkbox"
                className="custom-control-input"
                id={"switch_" + key}
                checked={active}
                disabled={disabled}
              />
              <label
                style={{ color: "white" }}
                onClick={(e) =>
                  self.activeCoinChange(parseFloat(item.id), active)
                }
                className="custom-control-label"
                htmlFor={"switch_" + key}
              >
                {item.name}
              </label>
            </div>
          </div>
        </Dropdown.Item>
      );
      self.setState((state) => ({
        manage_coins: [add, ...state.manage_coins],
      }));
    });
  };

  updateAllCoins(newCoin = null, newVal = null) {
    const activeClientCoins = storage.getKey("active_coins");
    this.setState({ coins: [] });

    let ac = null,
      style,
      value,
      coin;

    if (activeClientCoins !== null) {
      ac = activeClientCoins.split(",");

      __.reverse(ac).forEach((num, key) => {
        var item = __.find(coins, { id: parseFloat(num) });
        if (__.isUndefined(item)) return;
        style =
          __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin)
            ? "actc"
            : "";

        if (newVal !== null) value = newVal;
        else
          value = forceSatoshiFormat(
            this.state.credits[__.lowerCase(item.preffix)]
          );

        if (__.lowerCase(item.preffix) === newCoin) {
          this.state.credits[newCoin] = value;
        }

        if (newCoin !== null) {
          coin = (
            <Dropdown.Item
              onClick={(e) => this.selectCoin(item.preffix, newCoin, newVal)}
              key={key}
              className={"num-style " + style}
            >
              <div style={{ color: "white" }} className="float-left">
                <img
                  src={"/assets/images/" + item.image}
                  alt="coin"
                  className={"mini-coin-7"}
                />
                {item.preffix}
              </div>
              {__.lowerCase(item.preffix) === newCoin ? (
                <div style={{ color: "white" }} className="float-right">
                  {value}
                </div>
              ) : (
                <div style={{ color: "white" }} className="float-right">
                  {forceSatoshiFormat(
                    this.state.credits[__.lowerCase(item.preffix)]
                  )}
                </div>
              )}
            </Dropdown.Item>
          );
        } else {
          coin = (
            <Dropdown.Item
              onClick={(e) => this.selectCoin(item.preffix)}
              key={key}
              className={"num-style " + style}
            >
              <div style={{ color: "white" }} className="float-left">
                <img
                  src={"/assets/images/" + item.image}
                  alt="coin"
                  className={"mini-coin-7"}
                />
                {item.preffix}
              </div>
              <div style={{ color: "white" }} className="float-right">
                {value}
              </div>
            </Dropdown.Item>
          );
        }

        this.setState((state) => ({ coins: [coin, ...state.coins] }));
      });
    } else {
      coins.forEach((item, key) => {
        if (ac !== null) {
          let check = ac.some(
            (element) => parseFloat(element) === parseFloat(item.id)
          );

          if (check) {
            style =
              __.lowerCase(item.preffix) ===
              __.lowerCase(this.state.selectedCoin)
                ? "actc"
                : "";
            value = forceSatoshiFormat(
              this.state.credits[__.lowerCase(item.preffix)]
            );
            coin = (
              <Dropdown.Item
                onClick={(e) => this.selectCoin(item.preffix)}
                key={key}
                className={"num-style " + style}
              >
                <div className="float-left">
                  <img
                    src={"/assets/images/" + item.image}
                    alt="coin"
                    className={"mini-coin-7"}
                  />
                  {item.preffix}
                </div>

                <div style={{ color: "white" }} className="float-right">
                  {value}
                </div>
              </Dropdown.Item>
            );
          }
        } else {
          style =
            __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin)
              ? "actc"
              : "";
          value = forceSatoshiFormat(
            this.state.credits[__.lowerCase(item.preffix)]
          );
          coin = (
            <Dropdown.Item
              onClick={(e) => this.selectCoin(item.preffix)}
              key={key}
              className={"num-style " + style}
            >
              <div className="float-left">
                <img
                  src={"/assets/images/" + item.image}
                  alt="coin"
                  className={"mini-coin-7"}
                />
                {item.preffix}
              </div>
              <div style={{ color: "white" }} className="float-right">
                {value}
              </div>
            </Dropdown.Item>
          );
        }
        this.setState((state) => ({ coins: [coin, ...state.coins] }));
      });
    }
  }

  openWalletModal = (e) => {
    e.stopPropagation();
    this.setState({ showWalletModal: true, show: false });
  };

  closeWalletModal = () => {
    this.setState({ showWalletModal: false });
  };
  getCredit(data) {
    let { credit } = data;

    this.setState({ credits: credit });

    let currentCoin = __.lowerCase(this.props.coin);
    let currentCredit = forceSatoshiFormat(this.state.credits[currentCoin]);
    this.setState({ credit: currentCredit });
    storage.setKey("credit", currentCredit);

    //Set for Redux
    this.props.setCredit(currentCredit);
    this.updateAllCoins();
  }

  updateCredit(data) {
    let credit = data.value;
    let coin = data.coin;

    let different;
    let arr;

    if (__.toNumber(credit) > __.toNumber(this.state.credit)) {
      different = __.toNumber(credit) - __.toNumber(this.state.credit);
      arr = {
        amount: different,
        color: "text-success",
      };
    } else {
      different = __.toNumber(this.state.credit) - __.toNumber(credit);
      arr = {
        amount: different,
        color: "text-danger",
      };
    }

    let check = forceSatoshiFormat(different);

    if (check.toString() !== "0.00000000") {
      this.setState((state) => ({ different: [arr, ...state.different] }));
    }

    var realCredit = credit;
    credit = forceSatoshiFormat(credit);

    this.setState({ credit: credit });
    storage.setKey("credit", credit);

    this.updateParentCoin(__.lowerCase(coin), credit);
    this.updateAllCoins(__.lowerCase(coin), realCredit);

    //Set for Redux
    this.props.setCredit(credit);
  }

  updateParentCoin(coin, amount) {
    this.setState({ coins: [] });

    coins.forEach((item, key) => {
      if (__.lowerCase(item.preffix) === coin) {
        const style =
          __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin)
            ? "actc"
            : "";
        let update = (
          <Dropdown.Item
            onClick={(e) => this.selectCoin(__.upperCase(coin))}
            key={key}
            className={"num-style " + style}
          >
            <div className="float-left">
              <img
                src={"/assets/images/" + item.image}
                alt="coin"
                className={"mini-coin-7"}
              />
              {item.preffix}
            </div>
            <div style={{ color: "white" }} className="float-right">
              {forceSatoshiFormat(amount)}
            </div>
          </Dropdown.Item>
        );
        this.setState((state) => ({ coins: [update, ...state.coins] }));
      } else {
        const style =
          __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin)
            ? "actc"
            : "";
        let value = forceSatoshiFormat(
          this.state.credits[__.lowerCase(item.preffix)]
        );
        let update = (
          <Dropdown.Item
            onClick={(e) => this.selectCoin(item.preffix)}
            key={key}
            className={"num-style " + style}
          >
            <div className="float-left">
              <img
                src={"/assets/images/" + item.image}
                alt="coin"
                className={"mini-coin-7"}
              />
              {item.preffix}
            </div>
            <div style={{ color: "white" }} className="float-right">
              {value}
            </div>
          </Dropdown.Item>
        );
        this.setState((state) => ({ coins: [update, ...state.coins] }));
      }
    });
  }

  selectCoin(name, newCoin, newVal) {
    //Fix For Double selection
    if (storage.getKey("coin") === name) return;
    storage.setKey("coin", name);

    let credit = this.state.credits[__.lowerCase(name)];
    this.setState({ coin: name, credit: credit, selectedCoin: name });

    //Set Coin For Redux
    this.props.setCoin(name);
    this.props.setCredit(credit);

    wait(200).then(() => {
      this.updateAllCoins(newCoin, newVal);
      Event.emit("coin_changed", name);
      // this.props.onToggleCurrencyModal()
    });
    this.props.onToggleCurrencyModal();
  }

  addDiff(data, i) {
    let id = "id_" + Math.floor(Math.random() * 1000 + 1);

    wait(2000).then(() => {
      try {
        document.getElementById(id).classList.remove("frd");
        document.getElementById(id).classList.add("fadeOutDown");
      } catch (e) {}
      this.state.different.splice(i, 1);
    });

    return (
      <li
        key={i}
        id={id}
        className={"list-inline w-100 text-right animated frd " + data.color}
      >
        {" "}
        {data.color === "text-danger" ? "-" : "+"}
        {forceSatoshiFormat(data.amount, this.state.coin)}
      </li>
    );
  }

  closeManage = () => {
    this.setState({ showManage: false });
    wait(200).then(() => {
      this.updateAllCoins();
    });
  };

  manageCoins = () => {
    this.setState({ showManage: true });
  };

  open = (e) => {
    this.setState({ show: !this.state.show });
  };

  usdformat = () => {
    this.setState({ usd: !this.state.usd });
    wait(1000).then(() => {
      storage.setKey("usd", this.state.usd);
    });
  };
  handleSearch = (e) => {
    this.setState({ searchTerm: e.target.value });
  };

  filteredCoins = () => {
    const { searchTerm, coins } = this.state;
    return coins.filter((coin) => {
      const coinText = coin.props.children
        .map((child) => {
          if (typeof child === "string") {
            return child.toLowerCase();
          }
          return "";
        })
        .join(" ");
      return coinText.includes(searchTerm.toLowerCase());
    });
  };

  filteredCoinsCurrency = () => {
    const { searchTerm, coins } = this.state;
    return coins.filter((coin) => {
      const coinText = coin.props.children
        .map((child) => {
          if (typeof child === "string") {
            return child.toLowerCase();
          }
          return "";
        })
        .join(" ");
      return coinText.includes(searchTerm.toLowerCase());
    });
  };

  openCoinDropdown = (e) => {
    e.stopPropagation();
    this.setState({ show: !this.state.show, showWallet: false });
  };

  openWalletDropdown = (e) => {
    e.stopPropagation();
    this.setState({ showWallet: !this.state.showWallet, show: false });
  };
  toggleTransfer = () => {
    this.setState((prevState) => ({
      isTransferIn: !prevState.isTransferIn,
    }));
  };
  toggleCurrencytModal = (e) => {
    e.stopPropagation();
    // this.setState(prevState => ({
    //     showWalletModal:false,
    //     showCurrencyModal: !this.props.showCurrencyModal,
    //     show: false,
    //     showWallet: false
    // }));
    this.props.onToggleCurrencyModal();
  };

  render() {
    let { credit, different, coin, coins, show, showWallet, searchTerm } =
      this.state;
    const { showWalletModal, showCurrencyModal, activeSection, isModalOpen } =
      this.props;
    credit = forceSatoshiFormat(credit, coin, this.state.usd);
    const { isTransferIn } = this.state;
    let diff = different.map((data, i) => this.addDiff(data, i));

    const coinDropdownStyle = show ? "show prevscrol" : "d-nones";
    const walletDropdownStyle = showWallet ? "show prevscrol" : "d-nones";
    const filteredCoins = this.filteredCoins();
    const filteredCoinsCurrency = this.filteredCoinsCurrency();

    return (
      <>
        <ResponsiveModal
          size="sm"
          centered={true}
          backdrop={"static"}
          show={showWalletModal}
          onHide={this.toggleWalletModal}
          aria-labelledby="wallet-modal"
          className={"animated zoomIn"}
        >
          <ModalHeader>
            {showCurrencyModal ? (
              <div style={{ fontSize: "16px", textTransform: "capitalize" }}>
                Choose Coin
              </div>
            ) : (
              <div style={{ fontSize: "16px", textTransform: "capitalize" }}>
                {" "}
                Wallet
              </div>
            )}
            {showCurrencyModal ? (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    textTransform: "capitalize",
                    color: "#616770",
                  }}
                >
                  Hide Small
                </div>
                <button
                  type="button"
                  className="close p-2"
                  onClick={this.toggleCurrencytModal}
                >
                  <i className={"mdi mdi-close"} />
                </button>
              </div>
            ) : (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    textTransform: "capitalize",
                    color: "#616770",
                  }}
                  onClick={(e) => {
                    // this.toggleWalletModal(e)
                    this.props.onToggleWalletModal();
                    this.setState({ openTransacationModal: true });
                  }}
                >
                  {" "}
                  Transactions
                </div>
                <button
                  type="button"
                  className="close p-2"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.onToggleWalletModal();
                  }}
                >
                  <i className={"mdi mdi-close"} />
                </button>
              </div>
            )}
          </ModalHeader>
          <Modal.Body>
            {showCurrencyModal ? (
              <div>
                <div
                  onClick={(e) => {
                    if (
                      e.target.matches(".search-bar, .search-bar *") ||
                      e.target.matches(".clist, .clist *")
                    ) {
                      return;
                    }
                    this.setState({ show: false });
                  }}
                >
                  <div className="search-bar">
                    <SearchContainer>
                      <Search color="#636A73" size={16} />
                      <input
                        type="text"
                        placeholder="Search coins..."
                        value={searchTerm}
                        onChange={this.handleSearch}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </SearchContainer>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    {filteredCoinsCurrency}
                  </div>
                  <Divider />
                  {/* <div className="flex justify-between items-center w-full" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <ManageListButton onClick={this.manageCoins}>
                                            <span>Manage List</span>
                                        </ManageListButton>


                                        <div className="custom-control custom-switch switch-info mt-2 text-center">
                                            <label onClick={(e) => this.usdformat(e)} className="custom-control-label" htmlFor="usdSwitch">View in USD</label>
                                            <input type="checkbox" className="custom-control-input" id="usdSwitch" checked={this.state.usd} />
                                        </div>
                                    </div> */}
                </div>
              </div>
            ) : (
              <div>
                <NavContainer>
                  <ButtonContainer>
                    {this.sections.map((section) => (
                      <NavButton
                        key={section.id}
                        active={activeSection === section.id}
                        onClick={() => this.setActiveSection(section.id)}
                      >
                        <ButtonContent>
                          <section.icon
                            style={{
                              color:
                                activeSection === section.id
                                  ? "white"
                                  : "#8c8c8c",
                              width:
                                window.innerWidth <= 480
                                  ? 14
                                  : window.innerWidth <= 768
                                  ? 16
                                  : 18,
                              height:
                                window.innerWidth <= 480
                                  ? 14
                                  : window.innerWidth <= 768
                                  ? 16
                                  : 18,
                            }}
                          />
                          <IconText active={activeSection === section.id}>
                            {section.label}
                          </IconText>
                        </ButtonContent>
                      </NavButton>
                    ))}
                  </ButtonContainer>
                </NavContainer>
                {activeSection === "vaultpro" && (
                  <div
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <LabelSwap style={{ fontWeight: "bold", fontSize: "14px" }}>
                      Annual Percentage Rate{" "}
                      <span
                        style={{
                          color: "#da22ff",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        12%
                      </span>
                    </LabelSwap>
                    <span style={{ color: "#676F78" }}>Security rules</span>
                  </div>
                )}
                {activeSection === "swap" && (
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <LabelSwap style={{ fontWeight: "bold", fontSize: "14px" }}>
                      Swap Fee{" "}
                      <span
                        style={{
                          color: "#da22ff",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        1%
                      </span>
                    </LabelSwap>
                  </div>
                )}

                <div style={{ marginTop: "12px" }}>
                  <ContentArea>
                    {activeSection === "swap" && (
                      <>
                        <SwapHello />
                      </>
                    )}
                    {activeSection === "deposit" && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              textTransform: "capitalize",
                              color: "#616770",
                            }}
                          >
                            {" "}
                            Deposit Currency
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              textTransform: "capitalize",
                              color: "#616770",
                            }}
                            onClick={(e) => {
                              this.toggleWalletModal(e);
                              this.setState({ openTransacationModal: true });
                            }}
                          >
                            {" "}
                            Record
                          </div>
                        </div>

                        <CurrencyBox onClick={this.toggleCurrencytModal}>
                          <CurrencyInfo style={{ marginRight: "24px" }}>
                            <CurrencyIcon>
                              <img
                                src={
                                  "/assets/images/" +
                                  __.lowerCase(coin) +
                                  ".png"
                                }
                                style={{ height: "32px", width: "32px" }}
                                alt=""
                              />
                            </CurrencyIcon>
                            <CurrencyName>{coin}</CurrencyName>
                          </CurrencyInfo>
                          <ArrowIcon>
                            <ChevronRight />
                          </ArrowIcon>

                          <BalanceInfo style={{ textAlign: "left" }}>
                            <BalanceLabel>Balance :</BalanceLabel>
                            <BalanceAmount>{credit}</BalanceAmount>
                          </BalanceInfo>
                        </CurrencyBox>
                        <div style={{ marginTop: "22px" }}>
                          <DepositHello coin={coin} />
                        </div>
                      </div>
                    )}
                    {activeSection === "withdraw" && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              textTransform: "capitalize",
                              color: "#616770",
                            }}
                          >
                            {" "}
                            Withdraw Currency
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              textTransform: "capitalize",
                              color: "#616770",
                            }}
                            onClick={(e) => {
                              this.toggleWalletModal(e);
                              this.setState({ openTransacationModal: true });
                            }}
                          >
                            {" "}
                            Record
                          </div>
                        </div>

                        <CurrencyBox onClick={this.toggleCurrencytModal}>
                          <CurrencyInfo style={{ marginRight: "24px" }}>
                            <CurrencyIcon>
                              <img
                                src={
                                  "/assets/images/" +
                                  __.lowerCase(coin) +
                                  ".png"
                                }
                                style={{ height: "32px", width: "32px" }}
                                alt=""
                              />
                            </CurrencyIcon>
                            <CurrencyName>{coin}</CurrencyName>
                          </CurrencyInfo>
                          <ArrowIcon>
                            <ChevronRight />
                          </ArrowIcon>

                          <BalanceInfo style={{ textAlign: "left" }}>
                            <BalanceLabel>Balance :</BalanceLabel>
                            <BalanceAmount>{credit}</BalanceAmount>
                          </BalanceInfo>
                        </CurrencyBox>
                        <div style={{ marginTop: "22px" }}>
                          <WithdrawlHello coin={coin} />
                        </div>
                      </div>
                    )}

                    {activeSection === "vaultpro" && (
                      <div>
                        <ParentContainer>
                          <SwitcherContainer>
                            <SwitcherButton
                              active={isTransferIn}
                              onClick={this.toggleTransfer}
                            >
                              Transfer In
                            </SwitcherButton>
                            <SwitcherButton
                              active={!isTransferIn}
                              onClick={this.toggleTransfer}
                            >
                              Transfer Out
                            </SwitcherButton>
                          </SwitcherContainer>
                        </ParentContainer>
                        {isTransferIn && (
                          <InputContainer>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <LabelSwap>Send</LabelSwap>
                              <div>
                                <span style={{ color: "#676F78" }}>
                                  Wallet Balance{" "}
                                </span>
                                <span style={{ color: "#676F78" }}>
                                  {this.state.creditTransferIn}
                                </span>
                              </div>
                            </div>

                            <InputWrapper>
                              <Input
                                type="text"
                                name=" amountTransferIn"
                                value={this.state.amountTransferIn}
                                onChange={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    amountTransferIn: e.target.value,
                                  });
                                }}
                                //   defaultValue="0"
                              />

                              <ButtonGroup>
                                <div
                                  style={{
                                    backgroundColor: "#3C404B",
                                    borderRadius: "22px",
                                    padding: "10px 16px",
                                    marginRight: "10px",
                                  }}
                                >
                                  Max
                                </div>

                                <CurrencyInfo style={{ marginRight: "24px" }}>
                                  <CurrencyIcon>
                                    <img
                                      src={
                                        "/assets/images/" +
                                        __.lowerCase(
                                          this.state.selectedCoinTransferIn
                                        ) +
                                        ".png"
                                      }
                                      style={{ height: "32px", width: "32px" }}
                                      alt=""
                                    />
                                  </CurrencyIcon>
                                  <CurrencyName>
                                    {this.state.selectedCoinTransferIn}
                                  </CurrencyName>
                                </CurrencyInfo>
                                <ArrowIcon onClick={this.openModalTransferIn}>
                                  <ChevronRight />
                                </ArrowIcon>
                              </ButtonGroup>
                            </InputWrapper>
                            <CoinSelectionModal
                              setCoin={this.handleCoinTranserferIn}
                              setCredit={this.handleCreditTransferIn}
                              coin={this.state.selectedCoinTransferIn}
                              isOpen={this.state.isModalOpenTransferIn}
                              onClose={this.closeModalTransferIn}
                            />
                          </InputContainer>
                        )}

                        {!isTransferIn && (
                          <InputContainer>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <LabelSwap> You Send</LabelSwap>
                              <div>
                                <span style={{ color: "#676F78" }}>
                                  Vault Balance{" "}
                                </span>
                                <span style={{ color: "#676F78" }}>
                                  {this.state.vaultAmount}
                                </span>
                              </div>
                            </div>

                            <InputWrapper>
                              <Input
                                type="text"
                                name="amountTransferOut"
                                value={this.state.amountTransferOut}
                                onChange={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    amountTransferOut: e.target.value,
                                  });
                                }}
                                // defaultValue="0"
                              />
                              <ButtonGroup>
                                <div
                                  style={{
                                    backgroundColor: "#3C404B",
                                    borderRadius: "22px",
                                    padding: "10px 16px",
                                    marginRight: "10px",
                                  }}
                                >
                                  Max
                                </div>

                                <CurrencyInfo style={{ marginRight: "24px" }}>
                                  <CurrencyIcon>
                                    <img
                                      src={
                                        "/assets/images/" +
                                        __.lowerCase(
                                          this.state.selectedCoinTransferOut
                                        ) +
                                        ".png"
                                      }
                                      style={{ height: "32px", width: "32px" }}
                                      alt=""
                                    />
                                  </CurrencyIcon>
                                  <CurrencyName>
                                    {this.state.selectedCoinTransferOut}
                                  </CurrencyName>
                                </CurrencyInfo>
                                <ArrowIcon onClick={this.openModalTransferOut}>
                                  <ChevronRight />
                                </ArrowIcon>
                              </ButtonGroup>
                            </InputWrapper>
                            <CoinSelectionModal
                              setCoin={this.handleCoinTranserferOut}
                              setCredit={this.handleCreditTransferOut}
                              coin={this.state.selectedCoinTransferOut}
                              isOpen={this.state.isModalOpenTransferOut}
                              onClose={this.closeModalTransferOut}
                            />
                          </InputContainer>
                        )}

                        <div style={{ color: "red" }}>
                          {this.state.errorSend}
                        </div>

                        <SubmitContainer>
                          <StyledButton onClick={this.handleTransfer}>
                            {isTransferIn
                              ? "Transfer to Vault Pro"
                              : "Withdraw to Wallet"}
                          </StyledButton>
                        </SubmitContainer>
                      </div>
                    )}
                  </ContentArea>
                </div>
              </div>
            )}
          </Modal.Body>
        </ResponsiveModal>

        <StyledModal
          show={this.state.openTransacationModal}
          onHide={() => this.setState({ openTransacationModal: false })}
          centered
        >
          <ModalHeader style={{ background: "#17181B" }}>
            <div style={{ fontSize: "16px", fontWeight: "bold" }}>
              Transaction
            </div>
            <X
              onClick={() => this.setState({ openTransacationModal: false })}
              size={24}
              style={{ cursor: "pointer" }}
            />
          </ModalHeader>
          <ModalBody style={{ background: "#17181B" }}>
            {/* Pass activeTab to ModalContent */}
            <ModalContent activeTab={this.props.activeSection} />
          </ModalBody>
        </StyledModal>
      </>
    );
  }
}

UserCreditModal.propTypes = {
  setCoin: PropTypes.func.isRequired,
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps, { setCoin, gameCoin, setCredit })(
  UserCreditModal
);
