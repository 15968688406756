import React from "react";
import styled from "styled-components";

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 200px;
  min-height: 250px;
  max-width: 360px;
  border-radius: 10px;
  padding: 20px;
  background-color: #27272a;
  color: #d1d1d1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  z-index: 9999;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 20px;
  z-index: 9999;
`;

const CloseBtn = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  z-index: 9999;
`;

const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  z-index: 9999;
`;

const LanguageButton = styled.button`
  background: #333;
  color: #d1d1d1;
  border: none;
  border-radius: 5px;
  padding: 10px;
  min-width: 100px;
  cursor: pointer;
  &:hover {
    background: #444;
    color: #efb81c;
  }
  z-index: 9999;
`;

const Languages = [
  { id: "1", name: "English" },
  { id: "2", name: "French" },
  { id: "3", name: "German" },
  { id: "4", name: "Spanish" },
  { id: "5", name: "Portuguese" },
];

const LanguageSelectorModal = ({ setModalClosed, setLanguage }) => {
  return (
    <Modal>
      <Header>
        <Title>Select Language</Title>
        <CloseBtn onClick={() => setModalClosed(false)}>✖️</CloseBtn>
      </Header>
      <Body>
        {Languages.map((language) => (
          <LanguageButton
            key={language.id}
            onClick={() => setLanguage(language.name)}
          >
            {language.name}
          </LanguageButton>
        ))}
      </Body>
    </Modal>
  );
};

export default LanguageSelectorModal;
