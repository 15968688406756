import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Menu, X } from "lucide-react";
import { addNewStyle, CRISTP_ID, wait } from "../../../../Helper";

const ToggleButton = styled.button`
  position: fixed;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
`;

const SidebarContainer = styled.nav`
  background-color: #1e2024;
  width: 70%;
  height: 100vh;
  padding: 10px 0px;
  color: #ffffff;
  transition: all 0.3s ease;
  overflow-y: auto;
  position: fixed;

  top: 0;
  left: 0;
  z-index: 1000;
  transform: ${(props) =>
    props.isCollapsedMobile ? "translateX(0)" : "translateX(-100%)"};
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: ${(props) => (props.isCollapsedMobile ? "block" : "none")};
`;

const MenuItemLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  padding: 4px 8px;
  cursor: pointer;
  margin: 20px 0;
  position: relative;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  text-decoration: none;

  ${(props) =>
    props.active &&
    `
    // &::before {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   bottom: 0;
    //   width: 60%;
    //   background-color: rgba(76,175,80,0.2);
    //   border-top-right-radius: 20px;
    //   border-bottom-right-radius: 20px;
    // }
  `}
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  position: relative;
  z-index: 1;
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
`;

const Label = styled.span`
  font-size: 14px !important;
  font-weight: "bold";
  position: relative;
  z-index: 1;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #353535;
  margin: 8px 16px;
`;

const LogoWrapper = styled.div`
  // padding: 20px;
  // text-align: center;
`;

const SwitcherContainer = styled.div`
  display: flex;
  // background-color: #1F2937;
  border-radius: 9999px;
  padding: 4px;
  width: 100%;
  opacity: 0;
`;

const SidebarTop = styled.div`
  background-color: rgb(30, 32, 36);
  width: 100% !important;
  padding: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  color: #fff;
  display: flex;
  position: relative;
`;

const Tab = styled.div`
  color: #9ca3af;
  font-size: 14px;
  padding: 8px 35px;
  border-radius: 10px;
  transition: all 0.3s, color 0.3s;

  ${(props) =>
    props.active
      ? `
    color: #fff;
    background-size: 200% auto;
    text-align: center;
    background-image: linear-gradient(to right, #DA22FF 0%, #9733EE  51%, #DA22FF  100%)
  `
      : `
    &:hover {
      background-position: right center;
      color: #fff;
      text-decoration: none;
   }
  `}
`;

const XButton = styled(X)`
  position: absolute;
  top: 30px;
  right: 14px;
  z-index: 100;
`;

const ThemeSwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 8px;
  padding: 8px;
  border-radius: 5px;
  background-color: #27272a;
  transition: 0.3s ease;
  margin: 10px;

  ${(props) =>
    props.isCollapsed &&
    `
    padding: 0px;

    button {
      border-radius: 5px;
      padding: 12px;
    }
    `}
`;

const ThemeSwitcherButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px;
  background-color: #ffffff00;
  color: #ffffff10;
  filter: grayscale(1) brightness(0.5);
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &.active-light {
    background-color: #ffffff;
    color: #000000 !important;
    filter: none;
  }

  &.active-dark {
    background-color: #00000050;
    color: #ffffff;
    filter: none;
  }
`;

const MobileMenu = ({ isCollapsedMobile, setIsCollapsedMobile }) => {
  const location = useLocation();
  const history = useHistory();
  const sidebarRef = useRef(null);
  const [activeTab, setActiveTab] = useState("Casino");
  const tabs = ["Casino", "Sports"];

  const [loaded, setLoaded] = useState(false);

  const support = () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CRISTP_ID;

    if (!loaded) {
      console.log("support");
      setLoaded(true);
      const script = document.createElement("script");
      script.src = "https://client.crisp.chat/l.js";
      script.id = "chtt";
      script.async = true;
      document.head.appendChild(script);
    }

    addNewStyle("#crisp-chatbox {display:block !important;}");
    addNewStyle(
      ".crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}"
    );

    wait(2000).then(() => {
      const close = document.querySelector(".cc-wdhl");
      if (close === null) return;
      close.addEventListener("click", function (event) {
        addNewStyle("#crisp-chatbox {display:none !important;}");
        document.getElementById("chtt").src = "";
      });
    });
  };

  const [theme, setTheme] = useState("dark");

  const handleToggle = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const menuItems = [
    {
      name: "Home",
      icon: "/assets/images/sidebar/bid-win-lobby.webp",
      width: "30px",
      color: "rgb(255, 255, 255)",
      path: "/",
    },
    {
      name: "Originals",
      icon: "/assets/images/sidebar/Originals-Star.svg",
      width: "20px",
      color: "rgb(255, 255, 255)",
      path: "/games",
    },
    {
      name: "Slots",
      icon: "/assets/images/sidebar/bid-win-slots-games.webp",
      width: "20px",
      color: "rgb(255, 255, 255)",
      path: "/slots",
    },
    {
      name: "Lottery",
      icon: "/assets/images/sidebar/lottery-ticket.webp",
      width: "20px",
      color: "rgb(255, 255, 255)",
      path: "/wingo",
    },
    {
      name: "Multiplayer",
      icon: "/assets/images/sidebar/bid-win-multiplayer-games.webp",
      width: "20px",
      color: "rgb(255, 255, 255)",
      color: "rgb(255, 255, 255)",
      path: "#!",
    },
    {
      name: "Top Trending",
      icon: "/assets/images/sidebar/bidwinx-trending-games.webp",
      width: "20px",
      color: "rgb(255, 255, 255)",
      path: "#!",
    },
    {
      name: "Promotions",
      icon: "/assets/images/sidebar/bidwinx-promotions.webp",
      width: "20px",
      color: "rgb(255, 255, 255)",
      path: "/promotions",
      submenu: [
        {
          name: "Rakeback",
          icon: "/assets/images/sidebar/rakeback.webp",
          width: "28px",
          path: "/rakeback",
        },
      ],
    },
    { divider: true },
    {
      name: "Affiliate",
      icon: "/assets/images/sidebar/bid-win-affiliate-program.webp",
      width: "24px",
      color: "rgb(255, 255, 255)",
      path: "/affiliate",
    },
    {
      name: "VIP Club",
      icon: "/assets/images/sidebar/bid-win-vip-club.webp",
      width: "24px",
      color: "rgb(255, 255, 255)",
      path: "/vip-club",
    },
    { divider: true },
    {
      name: "Help Center",
      icon: "/assets/images/sidebar/bid-win-help-center.webp",
      width: "24px",
      color: "rgb(255, 255, 255)",
      path: "/provably-fair",
    },
    {
      name: "About Us",
      icon: "/assets/images/sidebar/about-bid-win.webp",
      width: "24px",
      color: "rgb(255, 255, 255)",
      path: "/about-us",
    },
  ];

  const handleMenuItemClick = (path) => {
    history.push(path);
    setIsCollapsedMobile(false);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsCollapsedMobile(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Overlay
        isCollapsedMobile={isCollapsedMobile}
        onClick={() => setIsCollapsedMobile(false)}
      />
      <SidebarContainer isCollapsedMobile={isCollapsedMobile} ref={sidebarRef}>
        <XButton
          size={20}
          onClick={() => setIsCollapsedMobile(false)}
        ></XButton>

        <SidebarTop>
          <SwitcherContainer>
            {tabs.map((tab) => (
              <Tab
                key={tab}
                active={activeTab === tab}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </Tab>
            ))}
          </SwitcherContainer>
        </SidebarTop>

        {menuItems.map((item, index) =>
          item.divider ? (
            <Divider key={`divider-${index}`} />
          ) : (
            <MenuItemLink
              key={item.name}
              to={item.path}
              active={location.pathname === item.path}
              color={item.color}
              onClick={() => handleMenuItemClick(item.path)}
            >
              <IconWrapper active={location.pathname === item.path}>
                <Icon src={item.icon} alt={item.name} />
              </IconWrapper>
              {isCollapsedMobile && (
                <Label active={location.pathname === item.path}>
                  {item.name}
                </Label>
              )}
            </MenuItemLink>
          )
        )}

        {/* Theme Switcher Button */}
        <ThemeSwitcherWrapper>
          <ThemeSwitcherButton
            onClick={handleToggle}
            theme={theme}
            aria-label="Switch Theme"
            className={`${theme === "dark" && "active-dark"}`}
          >
            <img
              src="/assets/images/sidebar/svg/dark-theme-moon.svg"
              alt="Moon Icon"
              width={"15px"}
              height={"auto"}
            />
            Dark
          </ThemeSwitcherButton>
          <ThemeSwitcherButton
            onClick={handleToggle}
            theme={theme}
            aria-label="Switch Theme"
            className={`${theme === "light" && "active-light"}`}
          >
            <img
              src="/assets/images/sidebar/svg/light-theme-sun.svg"
              alt="sun Icon"
              width={"18px"}
              height={"auto"}
            />
            Light
          </ThemeSwitcherButton>
        </ThemeSwitcherWrapper>
      </SidebarContainer>
    </>
  );
};

export default MobileMenu;
