import React from "react";
import { Col, Row, Dropdown } from "react-bootstrap";
import coins from "../../../coins";
import socket from "../../../../Socket";
import axios from "axios";
import { getUID } from "../../../../Helper";
import storage from "../../../../Storage";

import Cookies from "js-cookie";

import { QRCode } from "react-qrcode-logo";
import {
  __,
  decode,
  encode,
  getElement,
  wait,
  Event,
  isMobile,
  sendNotfication,
  forceSatoshiFormat,
  fixDate,
} from "../../../../Helper";
import DepositArchive from "./DepositArchive";
import InrHistory from "./inrhistory";
import C from "../../../../Constant";
import styled from "styled-components";
import { Loader, Clipboard, Check } from "lucide-react";

const AddressContainer = styled.div`
  border-radius: 8px;
  padding: 8px;
  width: 100%;
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1a202c;
  padding: 1rem;
  margin-top: 1rem;
`;

const FeeText = styled.span`
  color: #a0aec0;
  font-size: 14px;
  //   margin-bottom: 0.5rem;
`;

const GreenText = styled.span`
  color: #da22ff;
  font-size: 14px;
  display: block;
  max-width: 100%; // Adjust this value based on your layout
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledButton = styled.button`
  background-color: #da22ff;
  color: white;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  width: 100%;
  max-width: 20rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #4ab814;
    transform: scale(1.05);
  }

  &:disabled {
    background-color: #4a5568;
    cursor: not-allowed;
    transform: none;
  }
`;

const Title = styled.h3`
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 8px;
`;

const TronHighlight = styled.span`
  color: #00ff00;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 20px;
  background-color: #25282c;
  border: 1px solid #3a3a3a;
  border-radius: 20px;
  color: #ffffff;
  font-size: 12px;
`;

const DepositButton = styled.button`
  background-color: #da22ff;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 20px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const NetworkSelect = styled.select`
  width: 100%;
  padding: 20px 40px 20px 20px; /* Added extra padding on the right */
  background-color: #25282c;
  border: 1px solid #3a3a3a;
  border-radius: 20px;
  color: #ffffff;
  font-size: 12px;

  &:focus {
    outline: none;
  }

  /* Optional: Change cursor to pointer for better UX */
  cursor: pointer;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #a0a0a0;
  font-family: normal;
`;

const SubTitle = styled.h4`
  color: #ffffff;
  font-size: 12px;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const CopyButton = styled.button`
  background-color: #2d3748;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  padding: 6px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #4a5568;
  }
`;

class DepositHello extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      currentCoin: props.coin,
      credits: {},
      list: [],
      content: [],
      wallet: "btc",
      deposit: true,
      slide: false,
      height: 573,
      margin: "mt-1",
      amount: "",
      coinId: "",
      networks: [],
      selectedNetwork: "",
      qrString: "",
      coinName: "",
      logoUrl: "",
      isLoading: false,
      isCopied: false,
      depositAddress: "", // State to hold deposit address
      depositAmount: "", // State to hold deposit amount
      showDepositDetails: false, // State to show/hide deposit details,,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    socket.emit(C.CREDIT, encode({ coin: this.props.coin }));
    socket.on(C.CREDIT, (data) => this.getCreditAndCoins(decode(data)));

    Event.on("deposit_archive", () => {
      this.showArchive();
    });

    wait(400).then(() => {
      this.loadCoinId(this.props.coin);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getCreditAndCoins = (data) => {
    if (this._isMounted) {
      let { credit } = data;
      this.setState({ credits: credit });
      this.setUpCoins();
    }
  };

  setUpCoins = () => {
    if (isMobile()) {
      this.setState({ height: 685, margin: "mt-1" });
    }

    coins.forEach((item, i) => {
      if (item.preffix === "NC") return;

      let credit = forceSatoshiFormat(
        this.state.credits[__.lowerCase(item.preffix)]
      );

      let list = (
        <Dropdown.Item
          key={__.toString(i)}
          as={"button"}
          className={"animated fadeIn"}
          onClick={(e) => this.loadCoinId(item.preffix)}
        >
          <span>
            <img
              src={"/assets/images/" + item.image}
              className={"img-fluid mini-coin mr-1"}
              alt="Coin"
            />{" "}
            {item.preffix}
          </span>
        </Dropdown.Item>
      );

      this.setState((state) => ({ list: [list, ...state.list] }));
    });
  };

  showArchive = () => {
    if (this.state.slide) Event.emit("deposit_archive_back");

    this.setState({ slide: !this.state.slide });

    wait(300).then(() => {
      this.setState({ deposit: !this.state.deposit });
    });

    wait(300).then(() => {
      this.loadCoinId("BTC");
    });
  };

  loadCoinId = async (coin) => {
    if (coin.toUpperCase() === "INR") {
      console.log("Coin is INR, skipping loadCoinId process.");
      return; // Exit the function early
    }
    const list = [
      { id: "bitcoin", symbol: "BTC" },
      { id: "ethereum", symbol: "ETH" },
      { id: "tether-usdt", symbol: "USDT" },
      { id: "bitcoincash", symbol: "BCH" },
      { id: "litecoin", symbol: "LTC" },
      { id: "tron", symbol: "TRX" },
      { id: "dogecoin", symbol: "DOGE" },
      { id: "cardano", symbol: "ADA" },
      { id: "ripple", symbol: "XRP" },
      { id: "binancecoin", symbol: "BNB" },
      { id: "paxdollar", symbol: "USDP" },
      { id: "nexo", symbol: "NEXO" },
      { id: "maker", symbol: "MKR" },
      { id: "trueusd", symbol: "TUSD" },
      { id: "usdcoin", symbol: "USDC" },
      { id: "binanceusd", symbol: "BUSD" },
    ];

    if (this._isMounted) {
      this.setState({
        currentCoin: coin,
        coinId: "",
        networks: [],
        selectedNetwork: "",
        isLoading: true,
        coinName: "",
        logoUrl: "",
      });

      try {
        const response = await axios.post(
          "https://api.bidwinx.com/getCoinDetails",
          {
            symbol: coin.toUpperCase(),
          }
        );
        const { coinId, networks, coinFullName, logoUrl } = response.data;
        let networksList = Object.keys(networks);

        const coinInfo = list.find(
          (item) => item.symbol === coin.toUpperCase()
        );
        let selectedNetwork = networksList[0] || "";
        let coinName = coinInfo ? coinInfo.id : coin.toLowerCase();

        // Special handling for USDT
        if (coin.toUpperCase() === "USDT") {
          networksList = ["ETH", "TRX", "BSC"]; // Only allow these networks for USDT
        }

        // Update coin name dynamically based on selected network for all coins
        switch (selectedNetwork) {
          case "TRX":
            coinName = "tron"; // For TRX network
            break;
          case "BSC":
            coinName = "binancecoin"; // For BSC network
            break;
          case "ETH":
            coinName = "ethereum"; // For ETH network
            break;
          case "BTC":
            coinName = "bitcoin"; // For BTC network
            break;
          case "BCH":
            coinName = "bitcoincash"; // For BCH network
            break;
          case "ADA":
            coinName = "cardano"; // For ADA network
            break;
          case "XRP":
            coinName = "ripple"; // For XRP network
            break;
          case "DOGE":
            coinName = "dogecoin"; // For DOGE network
            break;
          case "LTC":
            coinName = "litecoin"; // For LTC network
            break;
          case "USDP":
            coinName = "paxdollar"; // For USDP network
            break;
          case "NEXO":
            coinName = "nexo"; // For NEXO network
            break;
          case "MKR":
            coinName = "maker"; // For MKR network
            break;
          case "TUSD":
            coinName = "trueusd"; // For TUSD network
            break;
          case "USDC":
            coinName = "usdcoin"; // For USDC network
            break;
          case "BUSD":
            coinName = "binanceusd"; // For BUSD network
            break;
          default:
            coinName = coinInfo ? coinInfo.id : coin.toLowerCase(); // Fallback to default
            break;
        }

        this.setState({
          coinId,
          networks: networksList,
          selectedNetwork,
          isLoading: false,
          coinName,
          logoUrl,
        });
        console.log("coin", this.state.coinName);
      } catch (error) {
        console.error("Error fetching coin details:", error);
        sendNotfication(
          "Error fetching coin information",
          "error",
          "top-right"
        );
        this.setState({ isLoading: false });
      }
    }
  };

  initiateDeposit = async () => {
    // Check if the amount is null, empty, or 0
    if (!this.state.amount || this.state.amount <= 0) {
      sendNotfication("Please enter a correct amount.", "error", "top-right");
      return;
    }

    this.setState({ isLoading: true, qrString: "" });
    const uid = Cookies.get("uid").toString();
    try {
      const response = await axios.post(
        "https://api.bidwinx.com/createDeposit",
        {
          coinId: this.state.coinId,
          price: this.state.amount,
          orderId: `order${Date.now()}`, // Generate a unique order ID
          chain: this.state.selectedNetwork,
          generateCheckoutURL: true,
          returnUrl: "https://bidwinx.com/",
          userid: uid,
        }
      );

      const { data } = response.data;

      if (data.address) {
        // Determine correct coin name based on the selected network
        let coinName;
        switch (this.state.selectedNetwork.toUpperCase()) {
          case "TRX":
            coinName = "tron"; // For TRX network
            break;
          case "ETH":
            coinName = "ethereum"; // For ETH network
            break;
          case "BSC":
            coinName = "binancecoin"; // For BSC network
            break;
          case "BTC":
            coinName = "bitcoin"; // For BTC network
            break;
          case "BCH":
            coinName = "bitcoincash"; // For BCH network
            break;
          case "ADA":
            coinName = "cardano"; // For ADA network
            break;
          case "XRP":
            coinName = "ripple"; // For XRP network
            break;
          case "DOGE":
            coinName = "dogecoin"; // For DOGE network
            break;
          case "LTC":
            coinName = "litecoin"; // For LTC network
            break;
          case "USDP":
            coinName = "paxdollar"; // For USDP network
            break;
          case "NEXO":
            coinName = "nexo"; // For NEXO network
            break;
          case "MKR":
            coinName = "maker"; // For MKR network
            break;
          case "TUSD":
            coinName = "trueusd"; // For TUSD network
            break;
          case "USDC":
            coinName = "usdcoin"; // For USDC network
            break;
          case "BUSD":
            coinName = "binanceusd"; // For BUSD network
            break;
          default:
            coinName = this.state.coinName; // Use current coin name if no match
            break;
        }

        // Update the state with the deposit details and show deposit section
        this.setState({
          depositAddress: data.address,
          depositAmount: this.state.amount,
          showDepositDetails: true,
          isLoading: false,
          qrString: `${coinName}:${data.address}?amount=${this.state.amount}`, // Correct coin name in QR string
        });

        console.log("qr", this.state.qrString);
      } else {
        sendNotfication("Deposit initiation failed", "error", "top-right");
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.error("Error initiating deposit:", error);
      sendNotfication("Error initiating deposit", "error", "top-right");
      this.setState({ isLoading: false });
    }
  };
  initiateUPIPayment = async () => {
    if (this.state.currentCoin !== "INR") {
      console.log(
        "UPI payment can only be initiated when the current coin is INR."
      );
      return;
    }

    // Check if the amount is null, empty, or 0
    if (!this.state.amount || this.state.amount <= 0) {
      sendNotfication("Please enter a correct amount.", "error", "top-right");
      return;
    }

    this.setState({ isLoading: true });

    try {
      const response = await axios.post(
        "https://api.bidwinx.com/createorderupi",
        {
          uid: getUID.toString(), // Assuming getUID is the user's ID.
          amount: this.state.amount, // Amount for the UPI payment
        }
      );

      const { data } = response.data;

      if (data && response.data.status) {
        const { payment_url } = data;
        const phonepay = data.upi_intent.phonepe_link;
        const paytm = data.upi_intent.paytm_link;
        const gpay = data.upi_intent.gpay_link;
        // Redirect to the UPI payment URL in the same window
        // window.location.href = payment_url;
        this.setState({
          depositAmount: this.state.amount,
          isLoading: false,
          qrString: phonepay, // Correct coin name in QR string
        });
        window.location.href = payment_url;
        console.log("qr", this.state.qrString);
      } else {
        // If there's an issue with the API response, show an error notification
        sendNotfication(
          response.data.msg || "Error creating UPI order",
          "error",
          "top-right"
        );
      }
    } catch (error) {
      // Handle errors in the try block (e.g., network issues)
      console.error("Error initiating UPI payment:", error);
      sendNotfication("Error initiating UPI payment", "error", "top-right");
    } finally {
      // Stop loading state once the API call is finished (either success or failure)
      this.setState({ isLoading: false });
    }
  };

  handleAmountChange = (e) => {
    this.setState({ amount: e.target.value });
  };

  handleNetworkChange = (e) => {
    this.setState({ selectedNetwork: e.target.value });
  };

  copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.setState({ isCopied: true });
        sendNotfication("Address copied to clipboard", "success", "top-right");
        // Reset the icon after 3 seconds
        // setTimeout(() => {
        //     this.setState({ isCopied: false });
        // }, 3000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        // this.props.sendNotification("Failed to copy address", "error", "top-right");
      });
  };

  render() {
    let { height, margin } = this.state;

    return (
      <div className="revi">
        {this.state.deposit ? (
          <div className={this.state.slide ? "animated fadeOut " : ""}>
            <AddressContainer>
              {/* Deposit Details Section */}
              {!this.state.showDepositDetails ? (
                <>
                  <InputContainer>
                    <Label>Deposit Amount</Label>
                    <Input
                      type="number"
                      value={this.state.amount}
                      onChange={this.handleAmountChange}
                      placeholder="Enter amount"
                      disabled={this.state.isLoading}
                    />
                  </InputContainer>
                  {this.state.networks.length > 0 && (
                    <>
                      <Label>Choose Network</Label>
                      <NetworkSelect
                        value={this.state.selectedNetwork}
                        onChange={this.handleNetworkChange}
                        disabled={this.state.isLoading}
                      >
                        {this.state.networks.map((network) => (
                          <option key={network} value={network}>
                            {network}
                          </option>
                        ))}
                      </NetworkSelect>
                    </>
                  )}

                  <SubmitContainer>
                    <StyledButton
                      onClick={() => {
                        if (this.state.currentCoin === "INR") {
                          this.initiateUPIPayment(); // Call UPI payment function if coin is INR
                        } else {
                          this.initiateDeposit(); // Otherwise, call the regular deposit function
                        }
                      }}
                      disabled={this.state.isLoading}
                    >
                      {this.state.isLoading ? (
                        <>
                          <Loader className="animate-spin mr-2" />
                          Processing...
                        </>
                      ) : (
                        "Deposit"
                      )}
                    </StyledButton>
                  </SubmitContainer>
                </>
              ) : (
                // Show Address and QR Code After Deposit is Initiated
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <FeeText>Deposit Amount:</FeeText>
                    <GreenText>
                      {this.state.depositAmount}
                      {this.props.coin}
                    </GreenText>
                  </div>
                  {this.state.currentCoin !== "INR" && (
                    <>
                      <SubTitle>Deposit Address</SubTitle>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <GreenText>{this.state.depositAddress}</GreenText>

                        <CopyButton
                          onClick={() =>
                            this.copyToClipboard(this.state.depositAddress)
                          }
                        >
                          {this.state.isCopied ? (
                            <Check size={16} className="text-green-500" />
                          ) : (
                            <Clipboard size={16} />
                          )}
                        </CopyButton>
                      </div>
                    </>
                  )}

                  <div
                    style={{
                      marginTop: "20px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <QRCode
                      size={150}
                      logoWidth={60}
                      // logoImage={this.state.logoUrl}
                      value={this.state.qrString}
                    />
                  </div>
                </div>
              )}
            </AddressContainer>
          </div>
        ) : this.state.currentCoin == "INR" ? (
          <div className={this.state.slide == false ? "animated fadeOut" : ""}>
            <InrHistory />
          </div>
        ) : (
          <div className={this.state.slide == false ? "animated fadeOut" : ""}>
            <DepositArchive />
          </div>
        )}
      </div>
    );
  }
}

export default DepositHello;
