import React, { Component } from "react";
import { Button, Row, Col, Dropdown } from "react-bootstrap";
import axios from "axios";
import socket from "../../../../Socket";
import {
  __,
  decode,
  encode,
  isValidNumber,
  forceSatoshiFormat,
  wait,
  sendNotfication,
  Event,
} from "../../../../Helper";
import storage from "../../../../Storage";
import coins from "../../../coins";
import WithdrawlArchive from "./WithdrawlArchive";
import C from "../../../../Constant";
import styled from "styled-components";
import CoinSelectionModal from "../../CoinSelectionModal";
import { ChevronRight } from "lucide-react";

const NavButton = styled.button`
  display: flex;
  align-items: center;

  background-color: ${(props) => (props.active ? "#da22ff" : "transparent")};
  color: ${(props) => (props.active ? "white" : "#8c8c8c")};
  border: none;
  padding: 12px 20px;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;
  gap: 8px;

  &:hover {
    background-color: ${(props) => (props.active ? "#da22ff" : "#2c2c2c")};
  }

  svg {
    margin-right: 8px;
  }
`;

const ContentArea = styled.div`
  background-color: #1e2024;
  color: white;
  border-radius: 14px;
  padding: 12px 8px;
`;

const ContainerDeposit = styled.div`
  background-color: #2c2c2c;
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  max-width: 400px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  color: #8c8c8c;
  font-size: 14px;
  margin-bottom: 12px;
`;

const CurrencyBox = styled.div`
  background-color: #25282c;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

const CurrencyInfo = styled.div`
  display: flex;
  align-items: center;
`;

const CurrencyIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: #da22ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-weight: bold;
  color: white;
`;

const CurrencyName = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: white;
`;

const BalanceInfo = styled.div`
  text-align: right;
`;

const BalanceLabel = styled.div`
  font-size: 12px;
  color: #8c8c8c;
`;

const BalanceAmount = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: white;
`;

const ArrowIcon = styled.span`
  color: #8c8c8c;
  font-size: 18px;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const LabelSwap = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  color: #676f78;
  font-family: normal;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 20px;
  background-color: #25282c;
  border: 1px solid #3a3a3a;
  border-radius: 20px;
  color: #ffffff;
  font-size: 12px;
`;

const ButtonGroup = styled.div`
  position: absolute;
  right: 3px;
  display: flex;
  align-items: center;
`;

const ButtonWithdrawl = styled.button`
  background-color: #32363f;
  border: none;
  color: #a0a0a0;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  margin-right: 10px;

  &:hover {
    background-color: #3a3a3a;
  }
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1a202c;
  padding: 1rem;
`;

const FeeText = styled.div`
  color: #a0aec0;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;

const GreenText = styled.span`
  color: #da22ff;
`;

const StyledButton = styled.button`
  background-color: #da22ff;
  color: white;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  width: 100%;
  max-width: 20rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #da22ff;
    transform: scale(1.05);
  }
`;
const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SwitcherContainer = styled.div`
  display: flex;
  background-color: #2b2e36;
  border-radius: 9999px;
  width: 256px;
  justify-content: center;
`;

const SwitcherButton = styled.div`
  flex: 1;
  padding: 8px 16px;
  border-radius: 9999px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  background-color: ${(props) => (props.active ? "#3C3F49" : "#2B2E36")};
  cursor: pointer;

  &:hover {
    background-color: #444854; /* Add a hover effect if needed */
  }
`;
const ErrorMessage = styled.div`
  color: #ff4d4f;
  margin: 8px 0;
  font-size: 14px;
`;

const SwapButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: ${(props) => (props.disabled ? "#3c404b" : "#1890ff")};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const MaxButton = styled.button`
  background-color: #3c404b;
  border-radius: 22px;
  padding: 10px 16px;
  margin-right: 10px;
  color: white;
  border: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
//_isMounted can prevent from double socket response

const FEE_WITHDRAWL = forceSatoshiFormat(0.000001);

// const LabelSwap = styled.label`
//   display: block;
//   margin-bottom: 5px;
//   font-size: 12px;
//   color: #676F78;
// //   font-family:normal;
// `;

class SwapHello extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      credit: storage.getKey("credit"),
      showArchive: false,
      slide: false,
      final: false,
      errorMessage: "",
      amount: "", // Changed from 0 to empty string
      amount1: "", // Changed from 0 to empty string
      im: true,
      error: false,
      error2: false,
      loading: false,
      loader: false,
      list: [],
      list1: [],
      coin: "BTC",
      coin1: "USDT",
      fee_withdrawal: FEE_WITHDRAWL,
      price: [],
      selectedCoinSwapSend: "BTC",
      creditSWapSend: 0,
      isModalOpenSwapSend: false,
      selectedCoinSwapApprox: "USDT",
      creditSWapApprox: 0,
      isModalOpenSwapApprox: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setMaxBits = this.setMaxBits.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleCreditChange = (newCredit) => {
    this.setState({ creditSWapSend: newCredit });
  };

  openModal = () => {
    this.setState({ isModalOpenSwapSend: true });
  };

  closeModal = () => {
    this.setState({ isModalOpenSwapSend: false });
  };

  handleCoinChangeApprox = (coin) => {
    this.setState({ selectedCoinSwapApprox: coin });
  };

  handleCreditChangeApprox = (newCredit) => {
    this.setState({ creditSWapApprox: newCredit });
  };

  openModalApprox = () => {
    this.setState({ isModalOpenSwapApprox: true });
  };

  closeModalApprox = () => {
    this.setState({ isModalOpenSwapApprox: false });
  };

  componentDidMount() {
    this._isMounted = true;

    socket.emit(C.CREDIT_COIN, encode({ coin: this.state.coin }));
    socket.on(C.CREDIT_COIN, (data) => this.setCreditCoin(decode(data)));
    socket.on(C.SUBMIT_NEW_SWAP, (data) => this.response(decode(data)));

    coins.forEach((item, key) => {
      if (item.preffix === "NC") return;

      let list = (
        <Dropdown.Item
          key={key}
          onClick={(e) => this.handleInputCoin(item.active, item.preffix)}
          className={"animated fadeIn"}
        >
          <img
            src={"/assets/images/" + item.image}
            className={"mini-coin-8"}
            alt=""
          />
          {item.preffix}
        </Dropdown.Item>
      );

      let list1 = (
        <Dropdown.Item
          key={key}
          onClick={(e) => this.handleInputCoin1(item.active, item.preffix)}
          className={"animated fadeIn"}
        >
          <img
            src={"/assets/images/" + item.image}
            className={"mini-coin-8"}
            alt=""
          />
          {item.preffix}
        </Dropdown.Item>
      );

      this.setState((state) => ({ list: [list, ...state.list] }));
      this.setState((state) => ({ list1: [list1, ...state.list1] }));
    });

    Event.on("withdraw_archive", () => {
      this.showArchive();
    });
    // this.setState({
    //   amount: 0,
    //   amount1: 0
    // });
    this.callPrice();
    this.priceRefreshInterval = setInterval(() => this.callPrice(), 30000);
    this.exchangeCoin(this.state.amount, 1);
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.priceRefreshInterval) {
      clearInterval(this.priceRefreshInterval);
    }
  }

  callPrice() {
    const api = "https://api.bidwinx.com/rate";
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
      },

      url: api,
      method: "GET",
    }).then(
      function (req) {
        const res = req.data;
        console.log(res);
        this.setState({ price: res });
      }.bind(this)
    );
  }

  // Updated exchange calculation method
  exchangeCoin(amount, id) {
    let { coin, coin1, price, creditSWapSend } = this.state;

    // Clear error state
    this.setState({ error: false, errorMessage: "" });

    // Handle empty input
    if (!amount || amount === "") {
      this.setState({
        amount1: id === 1 ? "" : this.state.amount1,
        amount: id === 2 ? "" : this.state.amount,
      });
      return;
    }

    // Check if we have price data
    if (!price || Object.keys(price).length === 0) {
      this.setState({ error: true, errorMessage: "Price data unavailable" });
      return;
    }

    const numAmount = Number(amount);

    // Validate input is a number
    if (isNaN(numAmount)) {
      this.setState({
        error: true,
        errorMessage: "Please enter a valid number",
      });
      return;
    }

    // Check balance if user is sending
    if (id === 1 && numAmount > creditSWapSend) {
      this.setState({
        error: true,
        errorMessage: `Insufficient balance. Available: ${creditSWapSend} ${coin}`,
      });
      return;
    }

    // Get rates for both coins
    const fromRate = price[coin.toUpperCase()];
    const toRate = price[coin1.toUpperCase()];

    if (!fromRate || !toRate) {
      this.setState({
        error: true,
        errorMessage: "Price data unavailable for selected coins",
      });
      return;
    }

    // Calculate exchange
    let result;
    if (id === 1) {
      // Converting from sending coin to receiving coin
      console.log("amountt", numAmount);
      const fee = forceSatoshiFormat((Number(this.state.amount) * 1) / 100);
      console.log("fee", fee);
      result = (numAmount * fromRate) / toRate;
      this.setState({ amount1: forceSatoshiFormat(result) });
    } else {
      // Converting from receiving coin to sending coin
      const fee = (Number(this.state.amount) * 1) / 100;
      result = (numAmount * toRate) / fromRate;

      // Check if calculated sending amount exceeds balance
      if (result > creditSWapSend) {
        this.setState({
          error: true,
          errorMessage: `Insufficient balance. Available: ${creditSWapSend} ${coin}`,
          amount: forceSatoshiFormat(result),
        });
      } else {
        const fee = (numAmount * 1) / 100;
        this.setState({ amount: forceSatoshiFormat(result) });
      }
    }
  }

  response(data) {
    if (this._isMounted) {
      wait(1000).then(() => {
        let message = data.status.toString();
        sendNotfication(message, "info", "top-center");
        socket.emit(C.CREDIT);
        this.setState({
          final: false,
          showArchive: false,
          loading: false,
        });
      });
    }
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;

    // Allow empty string for clearing input
    if (value === "") {
      this.setState({
        [name]: "",
        error: false,
        errorMessage: "",
        amount1: name === "amount" ? "" : this.state.amount1,
        amount: name === "amount1" ? "" : this.state.amount,
      });
      return;
    }

    // Only allow numbers and decimal point
    if (!/^\d*\.?\d*$/.test(value)) return;

    const numValue = Number(value);
    const { creditSWapSend, coin } = this.state;

    // Check balance if this is the sending amount
    if (name === "amount" && numValue > creditSWapSend) {
      this.setState({
        error: true,
        errorMessage: `Insufficient balance. Available: ${creditSWapSend} ${coin}`,
        [name]: value,
      });
      return;
    }

    // Update state and trigger exchange calculation
    this.setState({ [name]: value }, () => {
      this.exchangeCoin(value, name === "amount" ? 1 : 2);
    });
  };

  setMaxAmount = () => {
    const { creditSWapSend } = this.state;
    if (creditSWapSend > 0) {
      this.setState({ amount: creditSWapSend.toString() }, () => {
        this.exchangeCoin(creditSWapSend, 1);
      });
    }
  };

  getUserCredit() {
    return forceSatoshiFormat(this.state.credit);
  }

  setCreditCoin(data) {
    let { credit } = data;
    this.setState({ credit: credit, amount: credit == 0 ? null : credit });
  }

  maxUserCredit() {
    this.setState({ error: false, error2: false });
    return this.getUserCredit();
  }

  setMaxBits() {
    this.setState({ amount: this.maxUserCredit() });
  }

  submitForm = (e) => {
    e.preventDefault();

    const {
      error,
      coin,
      coin1,
      amount,
      amount1,
      creditSWapSend,
      fee_withdrawal,
    } = this.state;

    // Validation checks
    if (error) {
      return; // Don't submit if there's an error
    }

    if (!amount || !amount1 || amount === "" || amount1 === "") {
      this.setState({ error: true, errorMessage: "Please enter amounts" });
      return;
    }

    if (coin === coin1) {
      this.setState({ error: true, errorMessage: "Cannot swap same coin" });
      return;
    }

    const numAmount = Number(amount);

    if (numAmount > creditSWapSend) {
      this.setState({
        error: true,
        errorMessage: `Insufficient balance. Available: ${creditSWapSend} ${coin}`,
      });
      return;
    }
    const fee = (numAmount * 1) / 100;
    const finalAmount = numAmount - fee;

    if (finalAmount <= 0) {
      this.setState({
        error: true,
        errorMessage: "Amount after fee is too small",
      });
      return;
    }

    // Check minimum amount
    const minAmount = this.getFee(coin);
    if (finalAmount < minAmount) {
      this.setState({
        error: true,
        errorMessage: `Minimum amount is ${minAmount} ${coin}`,
      });
      return;
    }

    this.setState({ loading: true });

    // Submit swap request
    socket.emit(
      C.SUBMIT_NEW_SWAP,
      encode({
        amount: numAmount,
        amount1: Number(amount1),
        coin,
        coin1,
        immed: fee_withdrawal,
      })
    );
  };

  onChange(event, list) {
    this.setState({ list: list });
  }

  showArchive = (e) => {
    if (this.state.slide) Event.emit("withdraw_archive_back");

    this.setState({ slide: !this.state.slide });

    wait(500).then(() => {
      this.setState({ showArchive: !this.state.showArchive });
    });
  };

  handleInputCoin = (active, value) => {
    if (active) {
      socket.emit(C.CREDIT_COIN, encode({ coin: value }));
    } else {
      this.setState({ amount: forceSatoshiFormat(0) });
    }
    this.setState({
      coin: value,
      error: false,
      error2: false,
      amount: 0,
      amount1: 0,
    });
  };

  handleInputCoin1 = (active, value) => {
    if (active) {
      // socket.emit(C.CREDIT_COIN, encode({ coin: value }));
    } else {
      this.setState({ amount1: forceSatoshiFormat(0) });
    }
    this.setState({
      coin1: value,
      error: false,
      error2: false,
      amount: 0,
      amount1: 0,
    });
  };
  handleCoinChange = (type, value) => {
    const stateUpdate =
      type === "send"
        ? { coin: value, selectedCoinSwapSend: value }
        : { coin1: value, selectedCoinSwapApprox: value };

    this.setState(
      {
        ...stateUpdate,
        amount: "", // Clear amounts when changing coins
        amount1: "",
        error: false,
        errorMessage: "",
      },
      () => {
        if (type === "send") {
          // Fetch new balance for selected coin
          socket.emit(C.CREDIT_COIN, encode({ coin: value }));
        }
      }
    );
  };

  getFee = (coin) => {
    let find = __.find(coins, function (o) {
      return o.preffix === __.upperCase(coin);
    });

    return find.min;
  };

  render() {
    let UNIT = this.state.coin;
    let UNIT1 = this.state.coin1;
    const {
      errorMessage,
      creditSWapSend,
      selectedCoinSwapSend,
      selectedCoinSwapApprox,
      loading,
    } = this.state;
    let {
      loader,
      final,
      error,
      showArchive,
      amount,
      credit,
      error2,
      minFee,
      fee_withdrawal,
      error3,
      amount1,
      coin1,
    } = this.state;
    let details = withdrawlDetail(
      amount,
      fee_withdrawal,
      UNIT,
      credit,
      error,
      amount1,
      coin1
    );
    const top = true;
    return (
      <>
        <div>
          <CoinSelectionModal
            setCoin={(coin) => this.handleCoinChange("send", coin)}
            setCredit={this.handleCreditChange}
            coin={selectedCoinSwapSend}
            isOpen={this.state.isModalOpenSwapSend}
            onClose={this.closeModal}
          />

          <CoinSelectionModal
            setCoin={(coin) => this.handleCoinChange("receive", coin)}
            setCredit={this.handleCreditChangeApprox}
            coin={this.state.selectedCoinSwapApprox}
            isOpen={this.state.isModalOpenSwapApprox}
            onClose={this.closeModalApprox}
          />

          <InputContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LabelSwap style={{ color: "#676F78" }}>You send</LabelSwap>
              <div>
                <span style={{ color: "#676F78" }}>Min: 0 </span>
                <span style={{ color: "#676F78" }}>
                  Balance: {this.state.creditSWapSend}{" "}
                </span>
              </div>
            </div>

            <InputWrapper>
              <Input
                type="text"
                name="amount"
                value={amount}
                onChange={this.handleInputChange}
                placeholder="0"
              />
              <ButtonGroup>
                {/* <MaxButton onClick={this.setMaxAmount} disabled={creditSWapSend <= 0}>
                Max
              </MaxButton> */}
                {/* <div style={{ backgroundColor: '#3C404B', borderRadius: '22px', padding: '10px 16px', marginRight: '10px' }}>
                Max
            </div> */}

                <CurrencyInfo
                  onClick={() => this.setState({ isModalOpenSwapSend: true })}
                  style={{ marginRight: "24px" }}
                >
                  <CurrencyIcon>
                    <img
                      src={
                        "/assets/images/" +
                        __.lowerCase(selectedCoinSwapSend) +
                        ".png"
                      }
                      style={{ height: "32px", width: "32px" }}
                      alt=""
                    />
                  </CurrencyIcon>
                  <CurrencyName>{selectedCoinSwapSend}</CurrencyName>
                </CurrencyInfo>
                <ArrowIcon
                  onClick={() => this.setState({ isModalOpenSwapSend: true })}
                >
                  <ChevronRight />
                </ArrowIcon>
              </ButtonGroup>
            </InputWrapper>
          </InputContainer>
          {/* <LabelSwap>1 {selectedCoinSwapSend} = {creditSWapSend}{selectedCoinSwapSend}</LabelSwap> */}

          <InputContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LabelSwap>You get Approximately</LabelSwap>
              <span style={{ color: "#676F78" }}>Record</span>
            </div>

            <InputWrapper>
              <Input
                type="text"
                name="amount1"
                value={amount1}
                onChange={this.handleInputChange}
                placeholder="0"
              />
              <ButtonGroup>
                {/* <div style={{ backgroundColor: '#3C404B', borderRadius: '22px', padding: '10px 16px', marginRight: '10px' }}>
                Max
            </div> */}

                <CurrencyInfo
                  onClick={() => this.setState({ isModalOpenSwapApprox: true })}
                  style={{ marginRight: "24px" }}
                >
                  <CurrencyIcon>
                    <img
                      src={
                        "/assets/images/" +
                        __.lowerCase(this.state.selectedCoinSwapApprox) +
                        ".png"
                      }
                      style={{ height: "32px", width: "32px" }}
                      alt=""
                    />
                  </CurrencyIcon>
                  <CurrencyName>
                    {this.state.selectedCoinSwapApprox}
                  </CurrencyName>
                </CurrencyInfo>
                <ArrowIcon
                  onClick={() => this.setState({ isModalOpenSwapApprox: true })}
                >
                  <ChevronRight />
                </ArrowIcon>
              </ButtonGroup>
            </InputWrapper>
          </InputContainer>

          {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <SubmitContainer>
            <FeeText>
              Fee 1 % :{" "}
              <GreenText>
                {" "}
                {(Number(this.state.amount) * 1) / 100} {this.state.coin}
              </GreenText>
            </FeeText>
            <StyledButton onClick={this.submitForm} disabled={loading || error}>
              {loading ? "Processing..." : "Swap"}
            </StyledButton>
          </SubmitContainer>
        </div>
      </>
    );
  }
}

function withdrawlDetail(amount, fee, coin, credit, error, amount1, coin1) {
  let total = forceSatoshiFormat(amount - fee);

  return (
    <>
      <ul className={"p-2 m-0"}>
        <li>
          Will Receive Approximately:{" "}
          <span className="text-yellow">
            {amount1} {coin1}
          </span>
        </li>
        <li>
          Balance available for Swap: <b>{forceSatoshiFormat(credit)}</b> {coin}
        </li>
        <li>
          Amount to Swap: <b>{amount}</b> {coin}
        </li>
        <li>
          Swap Fee: <b>{forceSatoshiFormat(fee)}</b> {coin}
        </li>
        <li>
          Total: <span className="text-yellow">{total}</span>
        </li>
      </ul>
    </>
  );
}

export default SwapHello;
