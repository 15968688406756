import React, { Component, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import Cookies from "js-cookie";
import storage from "../../../Storage";
import socket from "../../../Socket";
import {
  Event,
  wait,
  decode,
  encode,
  sendNotfication,
  isEmail,
  CLIENT_ID,
} from "../../../Helper";
import C from "../../../Constant";
import GoogleBtn from "./Google";
import { ArrowLeft } from "lucide-react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #1e2124;
    color: white;
    border-radius: 18px;
    overflow: hidden;
  }
`;

const ModalHeader = styled.div`
  background-color: #da22ff;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

const ModalBody = styled(Modal.Body)`
  padding: 20px;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledInput = styled.input`
  background-color: #2b2f33;
  border: 1px solid #3a3f44;
  border-radius: 12px;
  color: white;
  padding: 14px;
  &::placeholder {
    color: #6c757d;
  }
  &:focus {
    outline: none;
    border-color: #da22ff;
    box-shadow: 0 0 0 2px rgba(67, 179, 11, 0.2);
  }
`;

const StyledButton = styled.button`
  background-color: #8c52ff;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  &:disabled {
    opacity: 0.7;
  }
`;

const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const SocialLoginButton = styled.button`
  background-color: #2b2f33;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledSignInButton = styled.div`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
  // background-image: linear-gradient(to right, #DA22FF 0%, #9733EE  51%, #DA22FF  100%);
  background-color: transparent;
  border: none;
  border-radius: 10px;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  border: 1px solid transparent;
  border-color: #da22ff;

  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    border-color: transparent;
    padding: 8px 15px;
  }
`;

const LabelText = styled.div`
  color: #696f79;
  font-size: 14px;
  display: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 15px;
`;

const Button = styled.button`
  padding: 12px 30px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
`;

const SignInButton = styled(Button)`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
  background-image: linear-gradient(
    to right,
    #da22ff 0%,
    #9733ee 51%,
    #da22ff 100%
  );
  border: none;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 150px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }

  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }
`;

const SignUpButton = styled(Button)`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
  border: 1px solid #da22ff;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 150px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }

  &:hover {
    color: #fff;
    background-size: 200% auto;
    text-align: center;
    background-image: linear-gradient(
      to right,
      #da22ff 0%,
      #9733ee 51%,
      #da22ff 100%
    );
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const ModalContainer = styled.div`
  background: #1e2124;
  border-radius: 16px;
  width: 90%;
  max-width: 480px;
  position: relative;
  padding: 1rem 1.5rem;
  animation: ${fadeIn} 0.3s ease-out;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.3);
  max-width: 375px;
`;

const BackButton = styled.button`
  position: absolute;
  left: 1.5rem;
  top: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #9ca3af;
  transition: color 0.2s ease;
  padding: 0px;

  &:hover {
    color: #ffffff;
  }
`;

const CloseButtonF = styled.button`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #9ca3af;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
  padding: 0px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
  }
`;

const LogoContainer = styled.div`
  text-align: center;
  margin: 1rem 0 2.5rem;
`;

const Logo = styled.img`
  width: 96px;
  height: 96px;
  margin: 0 auto 1rem;
`;

const Title = styled.h2`
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2.8rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: #e5e7eb;
  font-size: 0.875rem;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  }

  &::placeholder {
    color: #6b7280;
  }
`;

const SubmitButton = styled.button`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
  background-image: linear-gradient(
    to right,
    #da22ff 0%,
    #9733ee 51%,
    #da22ff 100%
  );
  padding: 0.875rem;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
  font-size: 1rem;

  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }

  &:active {
    transform: translateY(0);
  }
`;
const LoadingSpinner = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 0.8s linear infinite;
  margin-left: 8px;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const ForgotPasswordDialog = ({ onClose, onBack }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    // Setup socket listener
    socket.on(C.RESET_PASSWORD, (data) => resetPassword(decode(data)));

    // Cleanup listener on unmount
    return () => {
      socket.off(C.RESET_PASSWORD);
    };
  }, []);

  const resetPassword = (data) => {
    setDisabled(false);
    setLoading(false);

    if (data.status) {
      sendNotfication("your password sended to email", "success", "top-center");
      onClose(); // Close modal on success
    } else {
      sendNotfication(
        "this email is not registred on our system",
        "warning",
        "top-center"
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isEmail(email)) {
      return sendNotfication(
        "please_enter_valid_email_address",
        "warning",
        "top-center"
      );
    }

    setDisabled(true);
    setLoading(true);

    try {
      await wait(700);
      socket.emit(C.RESET_PASSWORD, encode({ email }));
    } catch (error) {
      setDisabled(false);
      setLoading(false);
      sendNotfication("an_error_occurred", "error", "top-center");
    }
  };

  return (
    <ModalOverlay>
      <ModalContainer>
        <BackButton onClick={onBack}>
          <ArrowLeft size={24} />
        </BackButton>

        <CloseButtonF onClick={onClose}>×</CloseButtonF>

        {/* <LogoContainer>
          <Title>Get Your Password in Your Email</Title>
        </LogoContainer> */}

        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Registered Email</Label>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
              required
              disabled={disabled}
            />
          </FormGroup>

          <SubmitButton type="submit" disabled={disabled}>
            <ButtonContent>
              Send Password To Email
              {loading && <LoadingSpinner />}
            </ButtonContent>
          </SubmitButton>
        </Form>
      </ModalContainer>
    </ModalOverlay>
  );
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: storage.getKey("logged"),
      show: this.props.show ? this.props.show : false,
      username: "",
      password: "",
      status: false,
      submitted: false,
      disabled: false,
      isLogined: false,
      effect: "zoomIn",
      showForgotPassword: false,
    };
  }

  componentDidMount() {
    socket.on(C.LOGIN_USER, (data) => this.setLogin(decode(data)));
    Event.on("login_form", () => {
      this.handleShow();
    });
  }

  setLogin = (data) => {
    if (data.status === true) {
      sendNotfication(
        "Successfully Login, Please Wait...",
        "success",
        "bottom-left"
      );
      Cookies.set("session", data.token, { expires: 14 });
      storage.setKey("logged", true);
      storage.setKey("token", data.token);
      storage.setKey("name", data.name);
      storage.setKey("avatar", data.avatar);
      storage.setKey("email", data.email);
      storage.setKey("credit", data.credit);
      storage.setKey("room", data.room);
      storage.setKey("friends", data.friends);

      wait(500).then(() => {
        window.location.reload();
      });
    } else {
      this.setState({ submitted: false, disabled: false });
      sendNotfication(data.status, "success", "bottom-left");
    }
  };

  handleShow = () => {
    this.setState({
      show: true,
      effect: "zoomIn",
      showForgotPassword: false,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
      showForgotPassword: false,
      effect: "zoomOut",
      disabled: false,
      status: false,
      submitted: false,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ submitted: true, disabled: true, status: "Please wait" });

    const { username, password } = this.state;

    if (!(username && password)) {
      this.setState({ disabled: false, status: false });
      return;
    }

    wait(200).then(() => {
      socket.emit(
        C.LOGIN_USER,
        encode({
          username: username,
          password: password,
          recaptcha: "google",
        })
      );
    });
  };

  handleBackToLogin = () => {
    this.setState({
      showForgotPassword: false,
      show: true,
    });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  signup = (e) => {
    e.preventDefault();
    this.handleClose();
    Event.emit("register_form");
  };

  forgetPass = (e) => {
    e.preventDefault();
    this.setState({
      showForgotPassword: true,
      show: false,
    });
  };
  handleGoogleLogin = (resp) => {
    if (resp.accessToken) {
      this.setState({
        isLogined: true,
        accessToken: resp.accessToken,
      });

      let profile = resp.profileObj;

      socket.emit(
        C.LOGIN_USER_GOOGLE,
        encode({
          username: profile.givenName + profile.familyName,
          email: profile.email,
          token: resp.accessToken,
          //F A K E S
          user_token: randomString(50),
          security_key: randomString(10),
          secret_user: randomString(44),
          secret_realtime: randomString(50),
          client_system: randomString(23),
          token_key: randomString(23),
          secret_token: randomString(25),
        })
      );
    }
  };

  handleGoogleError = () => {
    console.log("Google Login Failed");
  };
  render() {
    const { show, username, password, disabled, showForgotPassword } =
      this.state;

    return (
      <>
        <StyledSignInButton onClick={this.handleShow}>
          Sign in
        </StyledSignInButton>

        {showForgotPassword ? (
          <ForgotPasswordDialog
            onClose={this.handleClose}
            onBack={this.handleBackToLogin}
          />
        ) : (
          <StyledModal
            size="md"
            centered
            show={show}
            onHide={this.handleClose}
            aria-labelledby="login-modal"
            className={`animated ${this.state.effect}`}
          >
            <ModalHeader>
              <CloseButton onClick={this.handleClose}>×</CloseButton>
            </ModalHeader>
            <ModalBody>
              <div className="mb-4 text-center">
                <h3>You Are a Great Person; Victory Is Yours.</h3>
              </div>
              <StyledForm onSubmit={this.handleSubmit}>
                <LabelText>Enter Username/Email</LabelText>
                <StyledInput
                  name="username"
                  value={username}
                  onChange={this.handleInputChange}
                  placeholder="Enter Username/Email"
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <LabelText>Login Password</LabelText>
                </div>
                <StyledInput
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.handleInputChange}
                  placeholder="Login Password"
                />
                <div className="mt-2 text-right">
                  <button
                    onClick={this.forgetPass}
                    style={{
                      background: "none",
                      border: "none",
                      color: "blue",
                      cursor: "pointer",
                      padding: "0",
                      fontSize: "inherit",
                      textDecoration: "underline",
                    }}
                  >
                    Forgot password?
                  </button>
                </div>
                <ButtonContainer>
                  <SignInButton type="submit" disabled={disabled}>
                    {disabled && (
                      <span
                        className="mr-1 spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Sign In
                  </SignInButton>
                  <SignUpButton onClick={this.signup}>Sign up</SignUpButton>
                </ButtonContainer>
              </StyledForm>
              <div className="mt-3 text-lg text-center">
                <SocialLoginContainer>
                  <GoogleOAuthProvider clientId="869861485735-q8iaqbosrhdgjsp3rgiea4e7u447hsqr.apps.googleusercontent.com">
                    {!this.state.isLogined && (
                      <GoogleLogin
                        onSuccess={this.handleGoogleLogin}
                        onError={this.handleGoogleError}
                        useOneTap
                        theme="filled_blue"
                        shape="pill"
                        size="large"
                        text="continue_with"
                      />
                    )}
                  </GoogleOAuthProvider>
                </SocialLoginContainer>
              </div>
            </ModalBody>
          </StyledModal>
        )}
      </>
    );
  }
}

export default Login;
