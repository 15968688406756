import { mapRange } from "gsap";
import React from "react";
import storage from "../../../../Storage";
import C from "../../../../Constant";
import io from "socket.io-client";
import coins from "../../../coins";
import "./index.css";

import { withCookies } from "react-cookie";
import { decode, encode, forceSatoshiFormat } from "../../../../Helper";
import socket from "../../../../Socket";

const namespaces = ["30s", "1m", "2m"];

class UserAgreement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      betAmount: 10,
      winningValue: null,
      winnigColor: null,
      isAnimationStarted: false,
      showWinUI: false,
      winStatus: "",
      showWin: false,
      isAnimating: false,
      numbers: [
        { id: 0, imagePath: "/assets/images/num0.png" },
        { id: 1, imagePath: "/assets/images/num1.png" },
        { id: 2, imagePath: "/assets/images/num2.png" },
        { id: 3, imagePath: "/assets/images/num3.png" },
        { id: 4, imagePath: "/assets/images/num4.png" },
        { id: 5, imagePath: "/assets/images/num5.png" },
        { id: 6, imagePath: "/assets/images/num6.png" },
        { id: 7, imagePath: "/assets/images/num7.png" },
        { id: 8, imagePath: "/assets/images/num8.png" },
        { id: 9, imagePath: "/assets/images/num9.png" },
      ],
      isButtonClicked: false,
      animationReady: false,
      selectedNumbers: [],
      tableData: [],
      isEditing: false,
      isFocused: false,
      isTimerZero: false,
      highlightedNumber: -1,
      coinImage: "",
      isInvalidAmount: false,
      activeTab: "30s",
      showSession: "",
      footerTabActive: "LatestBets",
      timers: { "30s": 0, "1m": 0, "2m": 0 },
      outComes: { "30s": {}, "1m": {}, "2m": {} },
      currentNamespace: "30s",
      betData: { uid: "", value: "", amount: "", cointype: "usdt" },
      countdown: null,
      dropdownOpen: false,
      selectedOption: "30 Seconds",
      balance: 0,
      betOutcome: null,
      resultData: null,
      sessionData: { "30s": [], "1m": [], "2m": [] },
      coin: storage.getKey("coin") ? storage.getKey("coin") : "BTC",
      formattedTimers: { "30s": "00:00", "1m": "00:00", "2m": "00:00" },
      activeTimers: "00:00",
      selectedNumber: null,
      placedBet: false,
      placedBetByUser: false,
      token: storage.getKey("token"),
    };
    this.placeBet = this.placeBet.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setCurrentNamespace = this.setCurrentNamespace.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.startCountdown = this.startCountdown.bind(this);
    this.animationTimer = null;
    this.blinkTimer = null;
    this.resetTimer = null;
    this.setupSocket = this.setupSocket.bind(this);
    // this.handleSessionUpdate = this.handleSessionUpdate.bind(this);
    this.setupSocket = this.setupSocket.bind(this);
    this.handleBet = this.handleBet.bind(this);
    // this.findCoinImage = this.findCoinImage.bind(this);
    //     this.placeBet = this.placeBet.bind(this);
    // this.handleInputChange = this.handleInputChange.bind(this);
    // this.setCurrentNamespace = this.setCurrentNamespace.bind(this);

    this.results = [
      { time: "13-07 02:29:00", result: 2, type: "30 Detik" },
      { time: "13-07 02:28:30", result: 6, type: "30 Detik" },
      { time: "13-07 02:28:00", result: 5, type: "30 Detik" },
      { time: "13-07 02:27:30", result: 1, type: "30 Detik" },
      { time: "13-07 02:27:00", result: 9, type: "30 Detik" },
      { time: "13-07 02:29:00", result: 2, type: "30 Detik" },
      { time: "13-07 02:28:30", result: 6, type: "30 Detik" },
      { time: "13-07 02:28:00", result: 5, type: "30 Detik" },
      { time: "13-07 02:27:30", result: 1, type: "30 Detik" },
      { time: "13-07 02:27:00", result: 9, type: "30 Detik" },
    ];
    this.images = [
      { num: 0, img: "/assets/images/num0.png" },
      { num: 1, img: "/assets/images/num1.png" },
      { num: 2, img: "/assets/images/num2.png" },
      { num: 3, img: "/assets/images/num3.png" },
      { num: 4, img: "/assets/images/num4.png" },
      { num: 5, img: "/assets/images/num5.png" },
      { num: 6, img: "/assets/images/num6.png" },
      { num: 7, img: "/assets/images/num7.png" },
      { num: 8, img: "/assets/images/num8.png" },
      { num: 9, img: "/assets/images/num9.png" },
    ];
    this.inputRef = React.createRef();
    this.sockets = {}; // To store socket connections for each namespace
  }

  toggleDropdown() {
    this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }));
  }

  selectOption(option) {
    this.setState({ selectedOption: option, dropdownOpen: false });
  }

  formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeTimers !== this.state.activeTimers) {
      if (
        this.state.activeTimers === "00:03" &&
        this.state.countdown === null
      ) {
        this.startCountdown();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeTimers !== this.state.activeTimers) {
      if (this.state.activeTimers === "00:00") {
        this.setState({ isTimerZero: true });
        this.startHighlightAnimation();
        this.startCycle();
        setTimeout(() => {
          this.setState({ animationReady: true });
        }, 1500);
      } else {
        this.setState({ isTimerZero: false });
      }
    }

    // New logic for tableData
    if (this.state.resultData) {
      this.setState((prevState) => {
        const newTableData = [
          {
            timer: "3",
            color: "gg",
            session: "dhh",
          },
          ...prevState.tableData, // Spread the existing tableData
        ].slice(0, 10); // Keep only the latest 10 entries

        return { tableData: newTableData };
      });
    }
  }

  startHighlightAnimation() {
    let currentNumber = 0;
    const interval = setInterval(() => {
      this.setState({ highlightedNumber: currentNumber });
      currentNumber++;
      if (currentNumber > 9) {
        clearInterval(interval);
        this.setState({ highlightedNumber: -1 });
      }
    }, 200); // Adjust timing as needed
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeTimers !== this.state.activeTimers) {
      if (this.state.activeTimers === "00:00") {
        this.setState({
          isTimerZero: true,

          betData: { ...this.state.betData, value: "" },
        });
        // this.startHighlightAnimation();
        this.startCycle(); // Call startCycle when timer reaches 00:00
        setTimeout(() => {
          this.setState({ animationReady: true });
        }, 1500);
      } else if (
        this.state.activeTimers === "00:03" &&
        this.state.countdown === null
      ) {
        this.startCountdown();
        // if(this.state.placedBetByUser){
        //   this.setState({winCondition:true})
        // }
      } else {
        this.setState({ isTimerZero: false });
      }
    }

    // ResultData-related logic
    if (this.state.resultData.lenght > 0) {
      this.setState((prevState) => {
        const newEntry = {
          timer: this.state.activeTimers,
          color: this.getColorForResult(this.state.resultData),
          session: this.state.showSession,
        };

        const newTableData = [
          newEntry,
          ...prevState.tableData, // Spread the existing tableData
        ].slice(0, 10); // Keep only the latest 10 entries

        return { tableData: newTableData };
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeTimers !== this.state.activeTimers) {
      if (
        this.state.activeTimers === "00:03" &&
        this.state.countdown === null
      ) {
        this.startCountdown();
      }

      // Add this new condition
      if (this.state.activeTimers === "00:00") {
        this.setState({
          betData: { ...this.state.betData, value: "" },
        });
      }
    }
  }

  startCountdown() {
    this.setState({ countdown: 3 });
    const countdownInterval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.countdown > 0) {
          return { countdown: prevState.countdown - 1 };
        } else {
          clearInterval(countdownInterval);

          // Check if the user has placed a bet and countdown is 0
          if (this.state.placedBetByUser) {
            console.log("win condition");

            // Set showWinUI to true
            setTimeout(() => {
              this.setState({ showWinUI: true });
            }, 3000);

            // Set a timeout to hide the UI after 3 seconds
            setTimeout(() => {
              this.setState({ showWinUI: false });
              this.setState({ showWin: false });
            }, 6000);
          }

          return { countdown: null };
        }
      });
    }, 1000);
  }

  componentDidMount() {
    localStorage.setItem("getCredit", "false");

    const updateFormattedTimers = () => {
      this.setState((prevState) => {
        const newFormattedTimers = {};
        Object.keys(prevState.timers).forEach((key) => {
          newFormattedTimers[key] = this.formatTime(prevState.timers[key]);
        });

        const newActiveTimers =
          newFormattedTimers[prevState.activeTab] || "00:00";

        console.log("Timer update:", {
          newFormattedTimers,
          newActiveTimers,
          activeTab: prevState.activeTab,
        });
        const coinImage = this.findCoinImage(this.state.coin);
        this.setState({ coinImage });
        if (this.state.token !== null) console.log("sesssion");

        return {
          formattedTimers: newFormattedTimers,
          activeTimers: newActiveTimers,
          showSession: String(this.state.sessionData[prevState.activeTab]),
        };
      });
    };

    // Update formatted timers every second
    this.timerInterval = setInterval(updateFormattedTimers, 1000);
  }

  componentWillUnmount() {
    // Clear the timer interval if it exists
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }

    // Disconnect all socket connections for each namespace
    namespaces.forEach((namespace) => {
      const socket = this.sockets[namespace];
      if (socket) {
        socket.disconnect();
      }
    });
  }

  findCoinImage(coinPrefix) {
    const coin = coins.find((c) => c.preffix === coinPrefix);
    return coin ? coin.image : "";
  }

  handleSessionData = (namespace, data) => {
    this.setState((prevState) => ({
      resultData: {
        ...prevState.resultData,
        [namespace]: data,
      },
    }));
  };

  setupSocket(namespace) {
    const socket = io(`https://api.bidwinx.com/${namespace}`);
    this.sockets[namespace] = socket;

    socket.on("sessionTimer", (data) => {
      console.log("timer", data);

      this.setState((prevState) => ({
        sessionData: { ...prevState.sessionData, [namespace]: data.sessionId },
      }));

      this.setState((prevState) => ({
        timers: { ...prevState.timers, [namespace]: data.timeLeft },
      }));
    });

    socket.on("sessionData", (data) => this.setState({ resultData: data }));
    // socket.on('sessionData', (data) =>console.log("table data", data) )
    socket.on(
      "betOutcome",
      (data) => {
        console.log("data before", data);
        const { cookies } = this.props;
        const uid = cookies.get("uid");
        let outComeData = data?.filter((d) => d.uid == uid);
        console.log("win Data", outComeData);

        // Check if there's at least one "win" status
        const hasWin = outComeData.some((item) => item.winloss === "win");

        // setTimeout(()=>{
        // Update the winStatus state based on the "win" status
        if (hasWin && this.state.placedBetByUser) {
          this.setState({ winStatus: "win" });
        } else if (this.state.placedBetByUser && !hasWin) {
          this.setState({ winStatus: "loss" });
        } else {
          this.setState({ winStatus: "" });
        }
        // },0)

        setTimeout(() => {
          this.setState({ winStatus: "" });
          this.setState({ placedBetByUser: false });
        }, 10000);
      }

      // console.log("data comes", data)
    );

    socket.on("winningNumber", (data) => {
      if (data.sessionId == this.state.showSession) {
        // if(this.state.countdown === 0) {
        console.log("winning number", data);
        setTimeout(() => {
          this.startAnimation();
        }, 1000);

        this.setState((prevState) => ({
          outComes: { ...prevState.outComes, [namespace]: data },
          animationReady:
            String(data.sessionId) == this.state.showSession ? true : false,
          winningValue:
            String(data.sessionId) == this.state.showSession
              ? data.number
              : null,
          resultData: data,
        }));
      }
      // }

      // socket.on(, data =>console.log(decode(data), "updated"));

      setTimeout(() => {
        this.setState({
          animationReady: false,
          winningValue: null,
          winnigColor: null,
        });
      }, 5000);

      if (data.timeLeft <= 0) {
        socket.emit("getBetOutcome", this.state.currentNamespace);
      }
    });

    // socket.on('betOutcome', data => {
    //   console.log("outcomes****", data);
    //   this.setState(prevState => ({
    //     betOutcome: data,
    //     balance: prevState.balance + data.winAmount // Update user's balance
    //   }));
    // });

    socket.on("balanceUpdate", (data) => {
      this.setState({ balance: data.newBalance });
    });

    socket.on("bettingClosed", () => {
      // alert('Betting is closed for this session.');
    });

    // socket.on('sessionData', data => {
    //   this.setState(prevState => ({
    //     sessionData: { ...prevState.sessionData, [namespace]: data }
    //   }));
    // });

    socket.on("sessionUpdate", (data) =>
      this.setState((prevState) => ({
        sessionData: { ...prevState.sessionData, [namespace]: data },
      }))
    );

    socket.on("betResult", (data) => {
      console.log("bet result", data);
    });

    socket.on("betError", (data) => {
      console.log("bet error", data);
    });
    socket.on("walletUpdate", (data) => {
      console.log("walletUpdate", data);
    });
  }

  handleBet() {
    const {
      currentNamespace,
      betAmount,
      coin,
      selectedNumbers,
      sessionData,
      currentCredit,
      token,
    } = this.state;
    console.log("this state data**", this.state);

    this.setState({ isButtonClicked: true });
    this.setState({ placedBetByUser: true });
    const newCredit = forceSatoshiFormat(
      parseFloat(currentCredit) - parseFloat(betAmount)
    );

    this.setState({
      currentCredit: newCredit,
      placedBetByUser: true,
    });
    // Validate bet data
    if (selectedNumbers.length === 0 || !betAmount || !currentNamespace) {
      console.error("Missing required bet information");
      // You might want to show an error message to the user here
      return;
    }

    const { cookies } = this.props;
    const uid = cookies.get("uid");
    console.log("uid", uid);
    console.log("placed data", this.state);

    // Create a Set from selectedNumbers to ensure uniqueness
    const uniqueSelectedNumbers = new Set(selectedNumbers);

    // Iterate over each unique selected number/color
    uniqueSelectedNumbers.forEach((value) => {
      const data = {
        uid: String(uid),
        value: String(value), // Ensure value is a string
        amount: parseFloat(betAmount),
        cointype: coin.toLowerCase(),
        sessionId: sessionData[currentNamespace],
      };

      console.log("Betting data:", data);

      // Emit the bet to the server for each unique value
      this.sockets[currentNamespace].emit("placeBet", data);
    });

    setTimeout(() => {
      this.setState({ isButtonClicked: false });
    }, 300); // 300ms matches the animation duration

    // Reset bet data after placing bet
    this.setState({
      betData: { uid: "", value: "", amount: "", cointype: coin.toLowerCase() },
      selectedNumbers: [], // Clear the selectedNumbers array
      placedBet: false,
    });
  }

  placeBet(e) {
    e.preventDefault();
    this.handleBet();
  }

  handleInputChange(e) {
    console.log(":e values are", e);
    const { name, value } = e.target;
    this.setState((prevState) => ({
      betData: { ...prevState.betData, [name]: value },
    }));
  }

  setCurrentNamespace(ns) {
    this.setState({ currentNamespace: ns });
  }
  getResultColor = (result) => {
    if (result === 1 || result === 9) return "green";
    if (result === 2 || result === 6) return "red";
    if (result === 5) return "purple";
    return "blue"; // Default color
  };

  handleFocus = () => {
    this.setState({ isEditing: true, isFocused: true });
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  handleFooterTabClick = (tab) => {
    this.setState({ footerTabActive: tab });
  };
  handleTabClick = (tab) => {
    this.setState((prevState) => {
      const newActiveTimers = prevState.formattedTimers[tab] || "00:00";

      console.log("Tab click:", {
        newTab: tab,
        newActiveTimers,
        currentFormattedTimers: prevState.formattedTimers,
      });

      return {
        activeTab: tab,
        activeTimers: newActiveTimers,
        showSession: this.state.sessionData[tab] || "0000000000000",
        currentNamespace: tab,
      };
    });
  };
  handleBlur = () => {
    this.setState({
      isEditing: false,
      isFocused: false,
      isInvalidAmount: false,
    });
  };

  handleNumberClick = (num) => {
    console.log("selected number", num);
    this.setState((prevState) => {
      const selectedNumbers = [...prevState.selectedNumbers];
      const numString = String(num); // Convert to string
      const index = selectedNumbers.indexOf(numString);

      if (index === -1) {
        selectedNumbers.push(numString);
      } else {
        selectedNumbers.splice(index, 1);
      }

      return {
        selectedNumbers,
        betData: { ...prevState.betData, value: selectedNumbers.join(",") },
      };
    });
  };

  handleChange = (e) => {
    const value = e.target.value;
    this.setState({
      betAmount: value === "" ? "" : Number(value),
      isInvalidAmount: Number(value) < 10 || Number(value) > 10000,
    });
  };

  isTimerLessThanOrEqualToFiveSeconds = (timer) => {
    const [minutes, seconds] = timer.split(":").map(Number);
    return minutes === 0 && seconds <= 5;
  };
  // componentDidMount() {
  //   this.startCycle();
  // }

  componentWillUnmount() {
    clearInterval(this.animationTimer);
    clearTimeout(this.blinkTimer);
    clearTimeout(this.resetTimer);
  }

  componentWillUnmount() {
    clearTimeout(this.blinkTimer);
    clearTimeout(this.resetTimer);
  }

  // startCycle = () => {
  //   console.log("animation called");
  //   if (this.animationTimer) {
  //     clearInterval(this.animationTimer);
  //   }

  // }

  startAnimation = () => {
    this.setState({
      isAnimating: true,
      currentHighlight: null,
      selectedNumbers: [],
      isAnimationStarted: true,
    });
    const animationOrder = [0, 1, 2, 3, 4, 9, 8, 7, 6, 5];
    let index = 0;
    let cyclesCompleted = 0;

    const blink = () => {
      this.setState({ currentHighlight: animationOrder[index] });

      if (animationOrder[index] === this.state.winningValue) {
        cyclesCompleted++;
      }

      index = (index + 1) % animationOrder.length;

      if (cyclesCompleted < 2) {
        this.blinkTimer = setTimeout(blink, 200);
      } else {
        this.setState(
          {
            currentHighlight: this.state.winningValue,
            isAnimating: false,
          },
          () => {
            this.resetTimer = setTimeout(this.resetAnimation, 2000);
          }
        );
      }
    };

    blink();
  };

  resetAnimation = () => {
    this.setState({
      currentHighlight: null,
      isAnimating: false,
      selectedNumbers: [],
      showWin: true,
    });
    if (this.state.placedBetByUser) localStorage.setItem("getCredit", "true");
  };

  toggleSelection = (id) => {
    console.log("selected number", id);
    this.setState((prevState) => ({
      selectedNumbers: prevState.selectedNumbers.includes(id)
        ? prevState.selectedNumbers.filter((n) => n !== id)
        : [...prevState.selectedNumbers, id],
    }));
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     !prevState.showWinUI &&
  //     this.state.placedBetByUser &&
  //     this.state.countdown === 0 &&
  //     !this.state.showWinUI
  //   ) {
  //     console.log("win condition");
  //     this.setState({ showWinUI: true });

  //     setTimeout(() => {
  //       this.setState({ showWinUI: false });
  //     }, 3000);
  //   }
  // }

  render() {
    const {
      betAmount,
      isEditing,
      isFocused,
      footerTabActive,
      coin,
      coinImage,
    } = this.state;
    const { dropdownOpen, selectedOption } = this.state;

    console.log("gdggd", this.state.winStatus);
    console.log("win ui", this.state.showWinUI, this.state.winStatus);

    // // Call the function
    // const isWinCondition = checkWinCondition();

    // console.log("is win condtion", isWinCondition)
    const options = ["All", "30 Seconds", "1 minute", "2 minutes"];
    const clockwiseOrder = [0, 1, 2, 3, 4, 9, 8, 7, 6, 5];
    function formatDateItem(inputDate) {
      // Parse the input date string into a Date object
      const date = new Date(inputDate);

      // Extract day, month, hours, and minutes
      const day = ("0" + date.getDate()).slice(-2); // Get day and pad with leading zero if needed
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Get month (months are zero-based) and pad with leading zero if needed
      const hours = ("0" + date.getHours()).slice(-2); // Get hours and pad with leading zero if needed
      const minutes = ("0" + date.getMinutes()).slice(-2); // Get minutes and pad with leading zero if needed

      // Construct the formatted date string
      const formattedDate = `${day}-${month} ${hours}:${minutes}`;

      return formattedDate;
    }

    const getColor = (number) => {
      switch (number) {
        case 0:
          return "/assets/images/num0.png";
        case 1:
          return "/assets/images/num1.png";
        case 2:
          return "/assets/images/num2.png";
        case 3:
          return "/assets/images/num3.png";
        case 4:
          return "/assets/images/num4.png";
        case 5:
          return "/assets/images/num5.png";
        case 6:
          return "/assets/images/num6.png";
        case 7:
          return "/assets/images/num7.png";
        case 8:
          return "/assets/images/num8.png";
        case 9:
          return "/assets/images/num9.png";
        default:
          // Handle cases where number is out of expected range (0-9)
          return "/assets/images/default.png"; // Provide a default image path or handle error case
      }
    };

    console.log("this statte", this.state.animationReady);

    const timerColor = this.isTimerLessThanOrEqualToFiveSeconds(
      this.state.activeTimers
    )
      ? "red"
      : "black";
    const gameTimerSeconds = this.isTimerLessThanOrEqualToFiveSeconds(
      this.state.formattedTimers["30s"]
    )
      ? "red"
      : "black";
    const gameTimerOneMinute = this.isTimerLessThanOrEqualToFiveSeconds(
      this.state.formattedTimers["1m"]
    )
      ? "red"
      : "black";
    const gameTimerTwoMinute = this.isTimerLessThanOrEqualToFiveSeconds(
      this.state.formattedTimers["2m"]
    )
      ? "red"
      : "black";

    const dotIconColor = this.isTimerLessThanOrEqualToFiveSeconds(
      this.state.activeTimers
    )
      ? "red"
      : "white";

    const { activeTab } = this.state;
    console.log("this", this.state);
    const { formattedTimers } = this.state;

    console.log("formatted timers", formattedTimers);
    const styles = {
      "@keyframes pulse": {
        "0%": {
          transform: "scale(1)",
        },
        "50%": {
          transform: "scale(1.1)",
        },
        "100%": {
          transform: "scale(1)",
        },
      },
      smallStrips: {
        backgroundColor: "#2C2E34",
        fontSize: "14px",
        borderRadius: "12px",
        padding: "6px",
        color: "white",
        minWidth: "130px",
        textAlign: "center",
      },
      flexBox: {
        display: "flex",
        gap: "6px",
        alignItems: "center",
        justifyContent: "center",
      },
      flexContainer: {
        display: "flex",
        justifyContent: "space-between",
      },
      svgsBoxesContainer: {
        display: "flex",
        gap: "10px",
      },
      svgBox: {
        backgroundColor: "#2C2E34",
        fontSize: "14px",
        borderRadius: "8px",
        padding: "6px 9px",
        color: "white",
      },
      gameTitle: {
        fontSize: "20px",
        fontWeight: 800,
      },
      greenUnderline: {
        width: "100%",
        height: "1px",
        backgroundColor: "#04DB7F",
        borderRadius: "2px",
      },
      gameInterface: {
        backgroundColor: "#1e2337",
        color: "white",
        fontFamily: "Arial, sans-serif",
        padding: "10px",
        maxWidth: "400px",
        margin: "0 auto",
      },
      activeGameTitle: {
        fontWeight: "bold",
        fontSize: "20px",

        color: "#04DB7F", // Change this to your desired active color
      },
      header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
      },
      topHeading: {
        backgroundColor: "#1A1C21",
        padding: "6px",
        borderRadius: "10px",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "15px",
      },
      dropdown: {
        width: "100%",
        fontFamily: "Arial, sans-serif",
        position: "relative",
      },
      dropdownHeader: {
        backgroundColor: "#2C2E34",
        color: "white",
        padding: "12px 15px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      },
      dropdownList: {
        position: "absolute",
        top: "100%",
        left: 0,
        right: 0,
        backgroundColor: "#2C2E34",
        borderRadius: "8px",
        marginTop: "5px",
        padding: 0,
        listStyle: "none",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
        zIndex: "9999",
      },
      dropdownItem: {
        padding: "12px 15px",
        color: "#9A9A9A",
        cursor: "pointer",
      },
      activeDropdownItem: {
        backgroundColor: "#2C2E34",
        color: "#FFFFFF",
      },
      chevronIcon: {
        border: "solid white",
        borderWidth: "0 2px 2px 0",
        display: "inline-block",
        padding: "3px",
        transform: dropdownOpen ? "rotate(-135deg)" : "rotate(45deg)",
        transition: "transform 0.3s ease",
      },
      mascot: {
        position: "absolute",
        bottom: "-80px",
        left: "50%",
        transform: "translateX(-50%)",
        opacity: 0.5,
      },
      betInput: {
        backgroundColor: "transparent",
        border: "none",
        color: "white",
        fontSize: "18px",
        width: "100%",
        outline: "none",
        padding: "0",
      },
      menuButton: {
        backgroundColor: "transparent",
        border: "none",
        color: "white",
        fontSize: "24px",
      },
      title: {
        margin: 0,
        fontSize: "18px",
      },
      loginButton: {
        backgroundColor: "#da22ff",
        border: "none",
        color: "white",
        padding: "5px 10px",
        borderRadius: "5px",
      },
      registerButton: {
        backgroundColor: "#f44336",
        border: "none",
        color: "white",
        padding: "5px 10px",
        borderRadius: "5px",
      },
      gameMode: {
        textAlign: "center",
        marginBottom: "20px",
      },
      subtitle: {
        fontSize: "16px",
        marginBottom: "10px",
      },
      timerOptions: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
      },
      timer: {
        backgroundColor: "#2c3247",
        padding: "5px 10px",
        borderRadius: "5px",
      },
      gameId: {
        fontSize: "14px",
        marginBottom: "5px",
      },
      countdown: {
        fontSize: "24px",
        fontWeight: "bold",
      },
      gameOptions: {
        marginBottom: "7px",
        display: "flex",
        gap: "12px",
      },
      optionButton: {
        width: "100%",
        padding: "10px",
        marginBottom: "10px",
        border: "none",
        borderRadius: "15px",
        color: "white",
        fontSize: "16px",
        fontWeight: "600",
      },
      greenButton: { backgroundColor: "#da22ff", fontWeight: "600" },
      purpleButton: { backgroundColor: "#9C27B0", fontWeight: "600" },
      redButton: { backgroundColor: "#f44336", fontWeight: "600" },
      numberGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gap: "32px",
        margin: "0 auto",
        position: "relative",
      },
      numberBall: {
        width: "80px",
        height: "80px",
        borderRadius: "50%",
        border: "none",
        color: "white",
        fontSize: "24px",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
      },
      numberButton: {
        backgroundColor: "#2c3247",
        border: "none",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        color: "white",
        fontSize: "16px",
      },
      playButton: {
        width: "100%",
        padding: "10px",
        backgroundColor: "#da22ff",
        border: "none",
        borderRadius: "15px",
        color: "white",
        fontSize: "18px",
        marginBottom: "20px",
        marginTop: "20px",
      },

      score: {
        marginBottom: "20px",
        padding: "10px",
        borderRadius: "5px",
        transition: "border 0.3s ease",
        border: this.state.isInvalidAmount
          ? "2px solid red"
          : isFocused
          ? "2px solid #da22ff"
          : "2px solid transparent",
        backgroundColor: this.state.isInvalidAmount ? "#3D2B2E" : "#111315",
        cursor: "text",
      },
      scoreText: {
        display: "block",
        fontSize: "18px",
        marginBottom: "10px",
        minHeight: "24px",
      },
      scoreButtons: {
        width: "100%",
        display: "flex",
        gap: "5px",
      },
      scoreButton: {
        backgroundColor: "#2c3247",
        border: "none",
        padding: "5px 10px",
        color: "white",
        flex: "1 90%",
        gap: "5px",
        borderRadius: "5px",
        fontWeight: "600",
      },
      numberContainer: {
        display: "flex",
        gap: "8px",
      },
      numberDigit: {
        backgroundColor: "white",
        color: "black",
        borderRadius: "5px",
        height: "30px",
        width: "25px",
        fontWeight: "600",
        display: "flex",
        justifyContent: "center",
        fontSize: "20px",
      },
      footerTabs: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#191B1E",
        padding: "10px",
        borderRadius: "10px",
        marginTop: "20px",
      },
      footerTab: {
        flex: 1,
        textAlign: "center",
        padding: "10px",
        borderRadius: "5px",
        cursor: "pointer",
        color: "white",
      },
      activeFooterTab: {
        color: "#04DB7F",
        borderBottom: "2px solid #04DB7F",
      },
      whiteUnderlineTabs: {
        borderRadius: "12px",
        width: "12px",
        height: "4px",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        marginLeft: "36px",
      },
      tabContent: {
        marginTop: "20px",
      },
      resultsTable: {
        backgroundColor: "#1e2328",
        color: "#ffffff",
        fontFamily: "Arial, sans-serif",
        maxWidth: "400px",
        margin: "0 auto",
      },
      tableHeader: {
        display: "grid",
        gridTemplateColumns: "1fr 2fr 1fr",
        padding: "10px",

        position: "sticky",
        top: 0,
        fontWeight: "bold",
        textAlign: "center",
      },
      tableBody: {
        maxHeight: "300px",
        overflowY: "auto",
      },
      tableRow: {
        display: "grid",
        gridTemplateColumns: "1.2fr 1.2fr 1fr",
        padding: "10px",
        textAlign: "center",
        // borderBottom: '1px solid #2c2e34',
      },
      resultBall: {
        display: "inline-block",
        width: "34px",
        height: "34px",
        borderRadius: "50%",
        textAlign: "center",
        fontWeight: "bold",
      },
    };

    return (
      <div style={{ position: "relative" }}>
        {/* {this.state.countdown !== null && (
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent',
            backdropFilter: 'blur(2px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}>
            <div style={{
              fontSize: '100px',
              color: 'white',
              fontWeight: 'bold',
            }}>
              {this.state.countdown === 0 ? 'GO!' : this.state.countdown}
            </div>
          </div>
        )} */}
        <div style={styles.flexContainer}>
          <div style={styles.smallStrips}>
            <div style={styles.flexBox}>
              <div>How To Play</div>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="!w-[16px] !h-[16px] ml-[8px] !mr-0 cursor-pointer"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 13.667A5.667 5.667 0 1 0 8 2.333a5.667 5.667 0 0 0 0 11.334Zm0 1A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334Z"
                  fill="#fff"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 6.833a.5.5 0 0 1 .5.5v3.334a.5.5 0 0 1-1 0V7.333a.5.5 0 0 1 .5-.5Z"
                  fill="#fff"
                ></path>
                <path
                  d="M8.667 5.333a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0Z"
                  fill="#fff"
                ></path>
              </svg>
            </div>
          </div>
          <div style={styles.svgsBoxesContainer}>
            <div style={styles.svgBox}>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="!w-[16px] !h-[16px]"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.489 3.334c-.113-.526-.865-.526-.978 0l-.796 3.717A1.833 1.833 0 0 1 2.922 8.5H2a.5.5 0 1 1 0-1h.922a.833.833 0 0 0 .815-.659l.796-3.716c.34-1.581 2.595-1.581 2.934 0l2.044 9.54c.113.528.865.528.978 0l.796-3.716A1.833 1.833 0 0 1 13.078 7.5H14a.5.5 0 0 1 0 1h-.922a.833.833 0 0 0-.815.659l-.796 3.716c-.34 1.581-2.595 1.58-2.934 0L6.49 3.335Z"
                  fill="#33C38F"
                ></path>
              </svg>
            </div>
            <div style={styles.svgBox}>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="absolute animate__animated animate__bounceIn w-5 h-5 !w-[16px] !h-[16px]"
              >
                <path
                  d="M17.875 5.873c-1.744 0-3.783 1.977-4.875 3.25-1.092-1.273-3.131-3.25-4.875-3.25-3.086 0-4.875 2.407-4.875 5.471 0 5.092 9.75 10.779 9.75 10.779s9.75-5.688 9.75-10.563c0-3.063-1.789-5.687-4.875-5.687Z"
                  stroke="rgba(236, 241, 255, 0.7)"
                  stroke-width="1.5"
                ></path>
              </svg>
            </div>
            <div style={styles.svgBox}>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="w-4 h-4 !w-[16px] !h-[16px]"
              >
                <path
                  d="M5.373 4.667h-.706a2 2 0 0 0-2 2v2.666a2 2 0 0 0 2 2H6l3.122 2.732c.862.755 2.211.142 2.211-1.003v-2.435l-1-1v3.435c0 .286-.337.44-.553.25l-3.122-2.731A1 1 0 0 0 6 10.333H4.667a1 1 0 0 1-1-1V6.667a1 1 0 0 1 1-1H6a1 1 0 0 0 .32-.053l-.947-.947ZM10.333 8.213V2.938a.333.333 0 0 0-.553-.25l-2.652 2.32L6.42 4.3l2.702-2.364c.862-.754 2.211-.142 2.211 1.003v6.275l-1-1Z"
                  fill="#fff"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.646 1.646a.5.5 0 0 1 .708 0l12 12a.5.5 0 0 1-.708.708l-12-12a.5.5 0 0 1 0-.708Z"
                  fill="#fff"
                ></path>
              </svg>
            </div>
            <div style={styles.svgBox}>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="!w-[16px] !h-[16px]"
              >
                <mask
                  id="security_svg__a"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="2"
                  y="1"
                  width="12"
                  height="14"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.4 1.2h11.2v13.346H2.4V1.199Z"
                    fill="#fff"
                  ></path>
                </mask>
                <g mask="url(#security_svg__a)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.597 3.574v-.47l-.977-.015h-.003a6.924 6.924 0 0 1-2.146-.465c-1.144-.464-1.877-.994-1.883-.998l-.592-.427-.583.424c-.03.022-.742.535-1.887 1a6.653 6.653 0 0 1-2.149.466l-.98.015v5.633c0 1.295.742 2.746 2.038 3.983.557.532 1.179.986 1.797 1.311.643.338 1.252.515 1.767.515.511 0 1.127-.181 1.778-.523a8.15 8.15 0 0 0 1.784-1.303 7.659 7.659 0 0 0 1.43-1.827c.404-.738.608-1.462.608-2.157l-.002-5.162Zm-5.626-1.18.025-.019.027.02c.086.062.884.623 2.086 1.112a7.677 7.677 0 0 0 2.493.535l.04.002.002 4.694c.002 1.033-.633 2.233-1.741 3.293-.977.934-2.144 1.562-2.904 1.562-.353 0-.827-.148-1.334-.414a7.116 7.116 0 0 1-1.57-1.15c-1.108-1.058-1.743-2.258-1.743-3.293V4.042l.04-.001c.05 0 1.229-.023 2.494-.536A10.997 10.997 0 0 0 7.97 2.396v-.001Z"
                    fill="#fff"
                  ></path>
                </g>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.993 5.744a.48.48 0 0 0-.674 0L7.066 8.999 5.68 7.613a.48.48 0 0 0-.674 0 .48.48 0 0 0 0 .673l1.722 1.722a.48.48 0 0 0 .674 0l3.59-3.59a.479.479 0 0 0 0-.674Z"
                  fill="#fff"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "8px",
          }}
        >
          <div
            style={{ padding: "12px" }}
            onClick={() => this.handleTabClick("30s")}
          >
            <div
              style={
                activeTab === "30s" ? styles.activeGameTitle : styles.gameTitle
              }
            >
              30 Seconds
            </div>
            <div
              style={{ textAlign: "center", fontSize: "18px" }}
            >{`00:${formattedTimers["30s"][0]}${formattedTimers["30s"][1]}:${formattedTimers["30s"][3]}${formattedTimers["30s"][4]}`}</div>
            {activeTab === "30s" && <div style={styles.greenUnderline}></div>}
          </div>
          <div
            style={{ padding: "12px" }}
            onClick={() => this.handleTabClick("1m")}
          >
            <div
              style={
                activeTab === "1m" ? styles.activeGameTitle : styles.gameTitle
              }
            >
              1 Minute
            </div>
            <div
              style={{ textAlign: "center", fontSize: "18px" }}
            >{`00:${formattedTimers["1m"][0]}${formattedTimers["1m"][1]}:${formattedTimers["1m"][3]}${formattedTimers["1m"][4]}`}</div>
            {activeTab === "1m" && <div style={styles.greenUnderline}></div>}
          </div>
          <div
            style={{ padding: "12px" }}
            onClick={() => this.handleTabClick("2m")}
          >
            <div
              style={
                activeTab === "2m" ? styles.activeGameTitle : styles.gameTitle
              }
            >
              2 Minutes
            </div>
            <div
              style={{ textAlign: "center", fontSize: "18px" }}
            >{`00:${formattedTimers["2m"][0]}${formattedTimers["2m"][1]}:${formattedTimers["2m"][3]}${formattedTimers["2m"][4]}`}</div>
            {activeTab === "2m" && <div style={styles.greenUnderline}></div>}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "12px",
            marginBottom: "12px",
          }}
        >
          {this.state.showSession ? (
            this.state.showSession === "[object Object]" ? (
              <div style={{ fontSize: "20px", fontWeight: "600" }}>
                Loading......
              </div>
            ) : (
              <div style={{ fontSize: "20px", fontWeight: "600" }}>
                {this.state.showSession}
              </div>
            )
          ) : (
            <div style={{ fontSize: "20px", fontWeight: "600" }}>
              Loading......
            </div>
          )}
          <div style={styles.numberContainer}>
            <div style={{ ...styles.numberDigit, color: timerColor }}>
              {this.state.activeTimers[0]}
            </div>
            <div style={{ ...styles.numberDigit, color: timerColor }}>
              {this.state.activeTimers[1]}
            </div>
            <div
              style={{
                fontSize: "20px",
                fontWeight: "600",
                color: dotIconColor,
              }}
            >
              :
            </div>
            <div style={{ ...styles.numberDigit, color: timerColor }}>
              {this.state.activeTimers[3]}
            </div>
            <div style={{ ...styles.numberDigit, color: timerColor }}>
              {this.state.activeTimers[4]}
            </div>
          </div>
        </div>
        <div style={styles.topHeading}>
          My Bet : select multiple colors or number to bet on
        </div>
        <div style={styles.gameOptions}>
          <button
            onClick={() => this.handleNumberClick("green")}
            style={{
              ...styles.optionButton,
              ...styles.greenButton,
              border: this.state.selectedNumbers.includes("green")
                ? "2px solid white"
                : "1px solid transparent",
              boxShadow: this.state.selectedNumbers.includes("green")
                ? "0 0 10px white"
                : "none",
            }}
          >
            Green
          </button>
          <button
            onClick={() => this.handleNumberClick("violet")}
            style={{
              ...styles.optionButton,
              ...styles.purpleButton,
              border: this.state.selectedNumbers.includes("violet")
                ? "2px solid white"
                : "1px solid transparent",
              boxShadow: this.state.selectedNumbers.includes("violet")
                ? "0 0 10px white"
                : "none",
            }}
          >
            Purple
          </button>
          <button
            onClick={() => this.handleNumberClick("red")}
            style={{
              ...styles.optionButton,
              ...styles.redButton,
              border: this.state.selectedNumbers.includes("red")
                ? "2px solid white"
                : "1px solid transparent",
              boxShadow: this.state.selectedNumbers.includes("red")
                ? "0 0 6px white"
                : "none",
            }}
          >
            Red
          </button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        ></div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <div className="number-grid-1">
            {this.state.numbers.map((num) => (
              <div
                style={{
                  border: this.state.selectedNumbers.includes(num.id)
                    ? "2px solid white"
                    : "1px solid transparent",
                  boxShadow: this.state.selectedNumbers.includes(num.id)
                    ? "0 0 6px white"
                    : "none",
                  borderRadius: this.state.selectedNumbers.includes(num.id)
                    ? "50%"
                    : "none",
                }}
                key={num.id}
                className={`number-circle 
                ${
                  this.state.isAnimationStarted &&
                  this.state.currentHighlight !== null &&
                  this.state.currentHighlight !== num.id
                    ? "dimmed"
                    : ""
                } 

                ${this.state.currentHighlight === num.id ? "highlighted" : ""}`}
                onClick={() => this.toggleSelection(num.id)}
              >
                <img src={num.imagePath} alt={`Number ${num.id}`} />
              </div>
            ))}
          </div>
          {this.state.countdown !== null && (
            <div className="overlay">
              <div>
                <div className="countdown">
                  {this.state.countdown === 0 ? "GO!" : this.state.countdown}
                </div>
                <div className="bahis-container">
                  <div style={{ fontSize: "16px" }} className="bahis-text">
                    The Money We have is Coming
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.showWinUI && this.state.winStatus && (
            <div className="overlay-image">
              <img
                src={
                  this.state.winStatus === "loss"
                    ? "/assets/images/youloose.png"
                    : "/assets/images/youwon.png"
                }
                className="scale-up high-priority-image"
                style={{ height: "auto", width: "100px" }}
                alt={this.state.winStatus === "loss" ? "You Lost" : "You Won"}
              />
            </div>
          )}

          {/* {this.state.showWinUI === true && this.state.winStatus !== null && (
  <div className="overlay-image">
    {this.state.winStatus === 'win' &&(
      <img
        src="/assets/images/youwon.png"
        className="scale-up high-priority-image"
        style={{ height: 'auto', width: '100px' }}
        alt="You Lost"
      />
    )
    
    }
  </div>
)} */}
        </div>

        <button
          onClick={this.placeBet}
          style={{
            ...styles.playButton,
            transform: this.state.isButtonClicked ? "scale(0.95)" : "scale(1)",
            transition: "transform 0.3s",
          }}
        >
          Bet
        </button>

        <div onClick={this.handleFocus} style={styles.score}>
          <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
            {/* <div style={{ fontSize: '18px', color: 'green', fontWeight: '#1B4530', marginTop: '-8px' }}>Rp</div> */}
            <img
              style={{ height: "20px", width: "20px", marginTop: "-8px" }}
              src={`assets/images/${coinImage}`}
              alt={coin}
            />
            <div style={styles.scoreText} onClick={this.handleFocus}>
              {isEditing ? (
                <input
                  type="number"
                  value={betAmount}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  autoFocus
                  placeholder="10 ~ 10000"
                  style={styles.betInput}
                />
              ) : (
                <>{betAmount}</>
              )}
            </div>
          </div>

          <div style={styles.scoreButtons}>
            <button style={styles.scoreButton}>Min</button>
            <button style={styles.scoreButton}>Max</button>
            <button style={styles.scoreButton}>1/2</button>
            <button style={styles.scoreButton}>x2</button>
          </div>
        </div>
        <div style={{ background: "#191B1E" }}>
          <div className="footer-tabs" style={styles.footerTabs}>
            <div
              className={`footer-tab ${
                footerTabActive === "LatestBets" ? styles.activeFooterTab : ""
              }`}
              style={styles.footerTab}
              onClick={() => this.handleFooterTabClick("LatestBets")}
            >
              Latest Bets
              {footerTabActive === "LatestBets" && (
                <div style={styles.whiteUnderlineTabs}></div>
              )}
            </div>

            <div
              className={`footer-tab ${
                footerTabActive === "MyBets" ? styles.activeFooterTab : ""
              }`}
              style={styles.footerTab}
              onClick={() => this.handleFooterTabClick("MyBets")}
            >
              My Bets
              {footerTabActive === "MyBets" && (
                <div style={styles.whiteUnderlineTabs}></div>
              )}
            </div>

            <div
              className={`footer-tab ${
                footerTabActive === "History" ? styles.activeFooterTab : ""
              }`}
              style={styles.footerTab}
              onClick={() => this.handleFooterTabClick("History")}
            >
              History
              {footerTabActive === "History" && (
                <div style={styles.whiteUnderlineTabs}></div>
              )}
            </div>

            <div></div>
          </div>

          {footerTabActive === "History" && (
            <div style={{ background: "#191B1E" }}>
              <div style={styles.dropdown}>
                <div
                  style={styles.dropdownHeader}
                  onClick={this.toggleDropdown}
                >
                  <span>{selectedOption}</span>
                  <span style={styles.chevronIcon}></span>
                </div>
                {dropdownOpen && (
                  <ul style={styles.dropdownList}>
                    {options.map((option, index) => (
                      <li
                        key={index}
                        style={{
                          ...styles.dropdownItem,
                          ...(option === selectedOption
                            ? styles.activeDropdownItem
                            : {}),
                        }}
                        onClick={() => this.selectOption(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div style={styles.resultsTable}>
                <div style={styles.tableHeader}>
                  <div>Time</div>
                  <div>Results</div>
                  <div>Type</div>
                </div>
                <div style={styles.tableBody}>
                  {Array.isArray(this.state.resultData) &&
                  this.state.resultData.length > 0 ? (
                    this.state.resultData.slice(0, 30).map((item, index) => (
                      <div key={index} style={styles.tableRow}>
                        <div>{formatDateItem(item.createdAt)}</div>
                        <div>
                          <img
                            src={getColor(item.betnumber)}
                            style={styles.resultBall}
                            alt={`Result ${item.betnumber}`}
                          />
                        </div>
                        <div>{item.namespace}</div>
                      </div>
                    ))
                  ) : (
                    <div style={{ textAlign: "center", padding: "20px" }}>
                      No Data Available
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {footerTabActive === "MyBets" && (
            <>
              <div style={styles.dropdown}>
                <div
                  style={styles.dropdownHeader}
                  onClick={this.toggleDropdown}
                >
                  <span>{selectedOption}</span>
                  <span style={styles.chevronIcon}></span>
                </div>
                {dropdownOpen && (
                  <ul style={styles.dropdownList}>
                    {options.map((option, index) => (
                      <li
                        key={index}
                        style={{
                          ...styles.dropdownItem,
                          ...(option === selectedOption
                            ? styles.activeDropdownItem
                            : {}),
                        }}
                        onClick={() => this.selectOption(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div style={styles.resultsTable}>
                <div style={styles.tableHeader}>
                  <div>Time</div>
                  <div>Payment</div>
                  <div>Profit</div>
                </div>
                <div
                  style={{
                    height: "500px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ padding: "50px" }}>
                    <img
                      style={{ objectFit: "contain", width: "200px" }}
                      src={"/assets/images/EmptyStatus.webp"}
                      alt="empty"
                    />
                    <div style={{ textAlign: "center", marginTop: "6px" }}>
                      No Data
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {footerTabActive === "LatestBets" && (
            <div style={styles.resultsTable}>
              <div style={styles.tableHeader}>
                <div>Player</div>
                <div>Choice</div>
                <div>Profit</div>
              </div>
              <div
                style={{
                  height: "500px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ padding: "50px" }}>
                  <img
                    style={{ objectFit: "contain", width: "200px" }}
                    src={"/assets/images/EmptyStatus.webp"}
                    alt="empty"
                  />
                  <div style={{ textAlign: "center", marginTop: "6px" }}>
                    No Data
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

// UserAgreement.PropTypes= {
//   coin: PropTypes.string,
//   credit: PropTypes.string
// };

export default withCookies(UserAgreement);

// export default withCookies(UserAgreement)
