// import React, { Component } from "react";
// import { Button, Row, Col, Dropdown } from "react-bootstrap";
// import socket from "../../../../Socket";
// import { __, decode, encode, isValidNumber, forceSatoshiFormat, wait, sendNotfication, Event } from "../../../../Helper";
// import storage from "../../../../Storage";
// import coins from "../../../coins";
// import WithdrawlArchive from "./WithdrawlArchive";
// import C from "../../../../Constant";
// import styled from "styled-components";

// const AddressContainer = styled.div`
//   border-radius: 8px;
//   padding: 16px;
//   width: 100%;
// `;

// const Title = styled.h3`
//   color: #ffffff;
//   font-size: 14px;
//   margin-bottom: 8px;
// `;

// const TronHighlight = styled.span`
//   color: #00ff00;
// `;

// const AddressBox = styled.div`
//   background-color: #25282C;
//   border-radius: 8px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 8px 12px;
// `;

// const Address = styled.span`
//   color: #ffffff;
//   font-family: monospace;
//   font-size: 14px;
// `;

// const CopyButton = styled.button`
//   background: none;
//   border: none;
//   cursor: pointer;
//   color: #ffffff;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 4px;

//   &:hover {
//     opacity: 0.8;
//   }
// `;

// const NavContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   background-color: #1E2024;
//   border-radius: 14px;
//   overflow: hidden;
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   gap: 32px; /* Default for larger screens */

//   @media (max-width: 1200px) { /* For tablets or medium screens */
//     gap: 24px;
//   }

//   @media (max-width: 768px) { /* For mobile screens */
//     gap: 16px;
//   }

//   @media (max-width: 480px) { /* For smaller mobile screens */
//     gap: 0px;
//   }
// `;

// const NavButton = styled.button`
//   display: flex;
//   align-items: center;

//   background-color: ${props => props.active ? '#da22ff' : 'transparent'};
//   color: ${props => props.active ? 'white' : '#8c8c8c'};
//   border: none;
//   padding: 12px 20px;
//   border-radius: 14px;
//   cursor: pointer;
//   transition: background-color 0.3s, color 0.3s;
//   font-size: 14px;
//   gap:8px;

//   &:hover {
//     background-color: ${props => props.active ? '#da22ff' : '#2c2c2c'};
//   }

//   svg {
//     margin-right: 8px;
//   }
// `;

// //_isMounted can prevent from double socket response

// const FEE_WITHDRAWL = forceSatoshiFormat(0.000001);

// class WithdrawlHello extends Component {
//   _isMounted = false;
//   constructor(props) {
//     super(props);
//     this.state = {
//       credit: storage.getKey("credit"),
//       showArchive: false,
//       slide: false,
//       final: false,
//       pass: "",
//       wallet: "",
//       amount: 0,
//       im: true,
//       error: false,
//       error2: false,
//       loading: false,
//       loader: false,
//       list: [],
//       coin: "INR",
//       fee_withdrawal: FEE_WITHDRAWL,
//     };
//     this.handleInputChange = this.handleInputChange.bind(this);
//     this.setMaxBits = this.setMaxBits.bind(this);
//     this.submitForm = this.submitForm.bind(this);
//   }

//   componentDidMount() {
//     this._isMounted = true;

//     socket.emit(C.CREDIT_COIN, encode({ coin: this.state.coin }));
//     socket.on(C.CREDIT_COIN, (data) => this.setCreditCoin(decode(data)));
//     socket.on(C.SUBMIT_NEW_WITHDRAWL, (data) => this.response(decode(data)));

//     coins.forEach((item, key) => {
//       if (item.preffix === "NC") return;

//       let list = (
//         <Dropdown.Item
//           key={key}
//           onClick={(e) => this.handleInputCoin(item.active, item.preffix)}
//           className={"animated fadeIn"}
//         >
//           <img
//             src={"/assets/images/" + item.image}
//             className={"mini-coin-8"}
//             alt=""
//           />
//           {item.preffix}
//         </Dropdown.Item>
//       );
//       this.setState((state) => ({ list: [list, ...state.list] }));
//     });

//     Event.on("withdraw_archive", () => {
//       this.showArchive();
//     });
//   }

//   componentWillUnmount() {
//     this._isMounted = false;
//   }

//   response(data) {
//     if (this._isMounted) {
//       wait(1000).then(() => {
//         let message = data.status.toString();
//         sendNotfication(message, "info", "top-center");
//         socket.emit(C.CREDIT);
//         this.setState({
//           final: false,
//           pass: "",
//           showArchive: false,
//           loading: false,
//         });
//       });
//     }
//   }

//   handleInputChange(event) {
//     let target = event.target;
//     let value = target.type === "checkbox" ? target.checked : target.value;

//     if (target.name === "coin") {
//       socket.emit(C.CREDIT_COIN, encode({ coin: value }));
//     }

//     if (target.name === "amount") {
//       if (!isValidNumber(value)) return false;

//       if (value.length > 20) {
//         return false;
//       }
//     }

//     if (target.name === "amount") {
//       let error = false;

//       if (Number(value) > Number(this.maxUserCredit())) error = true;

//       this.setState({ error: error });
//     }

//     this.setState({ [target.name]: value, error3: false });
//   }

//   getUserCredit() {
//     return forceSatoshiFormat(this.state.credit);
//   }

//   setCreditCoin(data) {
//     let { credit } = data;
//     this.setState({ credit: credit, amount: credit });
//   }

//   maxUserCredit() {
//     this.setState({ error: false, error2: false });
//     return this.getUserCredit();
//   }

//   setMaxBits() {
//     this.setState({ amount: this.maxUserCredit() });
//   }

//   submitForm(e) {
//     if (this._isMounted) {

//       e.preventDefault();

//       let { error, coin, pass, wallet, amount, credit, fee_withdrawal } = this.state;

//       if (error === true) return

//       if (!Number(amount) && !amount) return;

//       if (wallet === "" || wallet.length < 10) {
//         this.setState({ error3: "Please Enter wallet address" });
//         return;
//       }

//       if (amount <= forceSatoshiFormat(0.0)) {
//         this.setState({ error3: "Please check amount" });
//         return;
//       }

//       var check = amount - fee_withdrawal;

//       if (check <= 0.0) {
//         this.setState({ error3: "Please check amount" });
//         return;
//       }

//       let finalMoney = forceSatoshiFormat(amount - fee_withdrawal);

//       if (__.toNumber(finalMoney) <= 0) {
//         this.setState({ error3: "Please check amount" });
//         return;
//       }

//       let restrecFee = true, minFee;

//       //Check for minimum amount
//       coins.forEach((item, key) => {
//         if (__.lowerCase(item.preffix) === __.lowerCase(coin)) {
//           let min = forceSatoshiFormat(item.min);
//           finalMoney = __.toNumber(finalMoney);
//           min = __.toNumber(min);
//           if (finalMoney < min) {
//             minFee = forceSatoshiFormat(min);
//             restrecFee = false;
//           }
//         }
//       });

//       if (!restrecFee) {
//         this.setState({ error2: true, minFee: minFee });
//         return;
//       }

//       //Show Second Form [ Final ]
//       if (pass === "") {
//         this.setState({ final: true });
//         return;
//       }

//       this.setState({ loading: true });

//       wait(500).then(() => {
//         socket.emit(
//           C.SUBMIT_NEW_WITHDRAWL,
//           encode({
//             coin: coin,
//             wallet: wallet,
//             amount: amount,
//             immed: fee_withdrawal,
//             password: pass,
//           })
//         );
//       });
//     }
//   }

//   onChange(event, list) {
//     this.setState({ list: list });
//   }

//   back(e) {
//     e.preventDefault();
//     this.setState({ final: false, pass: "" });
//   }

//   enterPass(e) {
//     this.setState({ pass: e.target.value });
//   }

//   showArchive = (e) => {
//     if (this.state.slide) Event.emit("withdraw_archive_back");

//     this.setState({ slide: !this.state.slide });

//     wait(500).then(() => {
//       this.setState({ showArchive: !this.state.showArchive });
//     });
//   };

//   handleInputCoin = (active, value) => {
//     if (active) {
//       socket.emit(C.CREDIT_COIN, encode({ coin: value }));
//     } else {
//       this.setState({ amount: forceSatoshiFormat(0) });
//     }
//     this.setState({ coin: value, error: false, error2: false });
//   };

//   getFee = (coin) => {
//     let find = __.find(coins, function (o) {
//       return o.preffix === __.upperCase(coin);
//     });

//     return find.min;
//   };

//   render() {
//     let UNIT = this.state.coin;
//     let {
//       loader,
//       final,
//       error,
//       showArchive,
//       amount,
//       credit,
//       error2,
//       minFee,
//       fee_withdrawal,
//       error3,
//     } = this.state;
//     let details = withdrawlDetail(amount, fee_withdrawal, UNIT, credit, error);
//     const top = true;
//     return (
//       <>
//         {showArchive ? (
//           <div className={this.state.slide === false ? "animated fadeOut" : ""}>
//             <WithdrawlArchive clicked={this.showArchive} />
//           </div>
//         ) : (
//           <>
//             <div className={this.state.slide ? "animated fadeOut mt-1" : " mt-1"}
//             >
//               {final ? (
//                 <>
//                   <Row className={this.state.final === true ? "animated fadeIn" : ""}>
//                     <Col md="7" lg="7" sm="12" className="m-auto">
//                       <div className={"m-auto text-center"}>
//                         <h5 className={"text-success"}>
//                           Total Amount to Withdrawl:
//                           <b className="ml-2">
//                             {forceSatoshiFormat(
//                               this.state.amount - this.state.fee_withdrawal
//                             )}{" "}
//                             {UNIT}
//                           </b>
//                         </h5>
//                       </div>

//                       <div className={"m-auto text-center"}>
//                         <form
//                           className="w-100"
//                           onSubmit={(e) => {
//                             this.submitForm(e);
//                           }}
//                         >
//                           <div className="form-group text-center text-darker">
//                             <label>Enter Your Password</label>
//                             <input
//                               name={"password"}
//                               type="password"
//                               value={this.state.pass}
//                               onChange={(e) => this.enterPass(e)}
//                               className={"form-control bgl2"}
//                               required={true}
//                             />
//                           </div>
//                           <div className="form-group mt-3 text-center">
//                             <Button
//                               variant="btn btn-btn bg-cs shadow-none btn-sm mr-1"
//                               type="button"
//                               onClick={(e) => this.back(e)}
//                             >
//                               <i className="mdi mdi-refresh" /> Back
//                             </Button>
//                             <Button
//                               onClick={e => this.submitForm(e)}
//                               variant="btn btn-btn bg-cs shadow-none btn-sm"
//                               type="submit"
//                               disabled={this.state.loading}
//                             >
//                               <i className="mdi mdi-send" /> Send To Wallet
//                             </Button>
//                           </div>
//                         </form>
//                       </div>
//                     </Col>
//                   </Row>
//                 </>
//               ) : (
//                 <>
//                   <div className={"m-auto wallet"}>
//                     <form
//                       className="w-100"
//                       onSubmit={(e) => {
//                         this.submitForm(e);
//                       }}
//                     >
//                       {loader ? (
//                         <></>
//                       ) : (
//                         <Row className={this.state.final === false ? "animated fadeIn" : ""}>
//                           <Col md="7" lg="7" sm="12">
//                             <div className="form-group mb-3">
//                               <label htmlFor="targetWallet" className="text-white">
//                                 Enter Your Wallet Address or UPI
//                               </label>
//                               <div className="input-group">
//                                 <input
//                                   type="text"
//                                   value={this.state.wallet}
//                                   className="form-control bgl2"
//                                   name="wallet"
//                                   id="targetWallet"
//                                   placeholder="..."
//                                   onChange={this.handleInputChange}
//                                   required={true}
//                                   autoComplete={"off"}
//                                 />
//                                 <span className="input-group-append">
//                                   <div className="input-group fw">
//                                     <Dropdown
//                                       top={top.toString()}
//                                       className={
//                                         "drop-select clist3 btn-block bgl2 py-0 pt-2"
//                                       }
//                                     >
//                                       <Dropdown.Toggle
//                                         split
//                                         variant=""
//                                         className={"py-0 mt-0 bgl2"}
//                                       >
//                                         <span className="text-muted text-center font-12">
//                                           <span className="caret mr-1"></span>
//                                           <img
//                                             src={
//                                               "/assets/images/" +
//                                               __.upperCase(this.state.coin) +
//                                               ".png"
//                                             }
//                                             className={"mini-coin-9"}
//                                             alt="COIN"
//                                           />
//                                           {this.state.coin}
//                                         </span>
//                                       </Dropdown.Toggle>
//                                       <Dropdown.Menu
//                                         className={"dopdown-menu-bottom"}
//                                         align={"left"}
//                                       >
//                                         {this.state.list}
//                                       </Dropdown.Menu>
//                                     </Dropdown>
//                                   </div>
//                                 </span>
//                               </div>
//                             </div>
//                           </Col>
//                           <Col md="5" lg="5" sm="12">
//                             <label htmlFor="targetAmount" className="text-white">
//                               Enter Amount ({UNIT})
//                             </label>
//                             <div className="input-group">
//                               <input
//                                 type="text"
//                                 value={this.state.amount}
//                                 className="form-control bgl2"
//                                 name="amount"
//                                 id="targetAmount"
//                                 placeholder="0"
//                                 onChange={this.handleInputChange}
//                                 required={true}
//                                 autoComplete={"off"}
//                               />
//                               <div className="input-group-append">
//                                 <Button
//                                   variant="btn bg-greys no-shadow btn-sm btn-clipboard"
//                                   onClick={this.setMaxBits}
//                                 >
//                                   <i className="mdi mdi-coins mr-1" /> Max
//                                 </Button>
//                               </div>
//                               {error && (
//                                 <ul className="mt-2 d-inline-block w-100 p-0 m-0">
//                                   <li className="text-yellow font-12">
//                                     Balance not enough
//                                   </li>
//                                 </ul>
//                               )}
//                               {error3 ? (
//                                 <ul className="mt-2 d-inline-block w-100 p-0 m-0">
//                                   <li className="text-yellow font-12">
//                                     {error3}
//                                   </li>
//                                 </ul>
//                               ) : (
//                                 <ul className="mt-2 d-inline-block w-100 p-0">
//                                   <li className="text-yellow font-12">
//                                     Minimum : {this.getFee(this.state.coin)} {UNIT}
//                                   </li>
//                                 </ul>
//                               )}
//                             </div>
//                           </Col>
//                           <Col sm="12">
//                             <div className="form-group text-center">
//                               <button
//                                 onClick={e => this.submitForm(e)}
//                                 className="btn btn-s-4 btn-sm px-4"
//                                 type="submit"
//                                 disabled={this.state.loading}
//                               >
//                                 <i className="far fas fa-dot-circle mr-1" /> Submit
//                               </button>
//                             </div>
//                           </Col>
//                         </Row>
//                       )}
//                     </form>
//                     <div className="form-group mt-2 mb-0">
//                       {loader ? (
//                         <div className="ycenter text-center"></div>
//                       ) : (
//                         <div className="withdrawl-detail p-1 bg-soft-dark text-white">
//                           {details}
//                         </div>
//                       )}
//                       {loader ? (
//                         <></>
//                       ) : (
//                         <p className="mt-1 mb-0 p-2 bg-soft-dark text-white">
//                           Your withdrawal will be sent from the hot wallet, do not
//                           withdraw to any site that uses the sending address, or
//                           returns to sender, because any returns will probably
//                           be credited to a different player.
//                         </p>
//                       )}
//                     </div>
//                   </div>
//                 </>
//               )}
//             </div>
//           </>
//         )}
//       </>
//     );
//   }
// }

// function withdrawlDetail(amount, fee, coin, credit, error) {
//   let total = forceSatoshiFormat(amount - fee);

//   return (
//     <>
//       <ul className={"p-2 m-0"}>
//         <li>
//           Balance available for withdrawal: <b>{forceSatoshiFormat(credit)}</b>{" "}
//           {coin}
//         </li>
//         <li>
//           Amount to Withdraw: <b>{amount}</b> {coin}
//         </li>
//         <li>
//           Withdrawal Fee: <b>{forceSatoshiFormat(fee)}</b> {coin}
//         </li>
//         <li>Total: <span className="text-yellow">{total}</span></li>
//       </ul>
//     </>
//   );
// }

// export default WithdrawlHello;

import React, { Component } from "react";
import { Button, Row, Col, Dropdown } from "react-bootstrap";
import socket from "../../../../Socket";
import {
  __,
  decode,
  encode,
  isValidNumber,
  forceSatoshiFormat,
  wait,
  sendNotfication,
  Event,
} from "../../../../Helper";
import storage from "../../../../Storage";
import coins from "../../../coins";
import WithdrawlArchive from "./WithdrawlArchive";
import C from "../../../../Constant";
import styled from "styled-components";
import { Copy } from "lucide-react";

const AddressContainer = styled.div`
  border-radius: 8px;
  padding: 16px;
  width: 100%;
`;

const Title = styled.h3`
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 8px;
`;

const TronHighlight = styled.span`
  color: #00ff00;
`;

const AddressBox = styled.input`
  background-color: #25282c;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
`;

const Address = styled.span`
  color: #ffffff;
  font-family: monospace;
  font-size: 14px;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;

  &:hover {
    opacity: 0.8;
  }
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1e2024;
  border-radius: 14px;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 32px; /* Default for larger screens */

  @media (max-width: 1200px) {
    /* For tablets or medium screens */
    gap: 24px;
  }

  @media (max-width: 768px) {
    /* For mobile screens */
    gap: 16px;
  }

  @media (max-width: 480px) {
    /* For smaller mobile screens */
    gap: 0px;
  }
`;

const NavButton = styled.button`
  display: flex;
  align-items: center;

  background-color: ${(props) => (props.active ? "#da22ff" : "transparent")};
  color: ${(props) => (props.active ? "white" : "#8c8c8c")};
  border: none;
  padding: 12px 20px;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;
  gap: 8px;

  &:hover {
    background-color: ${(props) => (props.active ? "#da22ff" : "#2c2c2c")};
  }

  svg {
    margin-right: 8px;
  }
`;

const FormContainer = styled.div`
  color: #ffffff;
  padding: 13px;
  border-radius: 8px;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #a0a0a0;
  font-family: normal;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 20px;
  background-color: #25282c;
  border: 1px solid #3a3a3a;
  border-radius: 20px;
  color: #ffffff;
  font-size: 12px;
`;

const ButtonGroup = styled.div`
  position: absolute;
  right: 3px;
  display: flex;
`;

const ButtonWithdrawl = styled.button`
  background-color: #32363f;
  border: none;
  color: #a0a0a0;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  margin-right: 10px;

  &:hover {
    background-color: #3a3a3a;
  }
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1a202c;
  padding: 1rem;
`;

const FeeText = styled.div`
  color: #a0aec0;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;

const GreenText = styled.span`
  color: #da22ff;
`;

const StyledButton = styled.button`
  background-color: #da22ff;
  color: white;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  width: 100%;
  max-width: 20rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #da22ff;
    transform: scale(1.05);
  }
`;

const NoteText = styled.span`
  color: #da22ff;
  font-size: 12px;
`;

const UnlockText = styled.div`
  color: #a0a0a0;
  font-size: 14px;
  margin-top: 10px;
`;

const ButtonGroupE = styled.div`
  display: flex;
  background-color: #1e2124;
  border-radius: 4px;
  padding: 4px;
  gap: 10px;
  margin-bottom: 10px;
`;

const ButtonE = styled.div`
  background-color: ${(props) => (props.active ? "#da22ff10" : "#6282D")};
  color: ${(props) => (props.active ? "#ffffff" : "#a0a0a0")};
  border: ${(props) => (props.active ? "1px solid #da22ff" : "1px solid gray")};

  border-radius: 18px;
  padding: 8px 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};

  //   &:hover {
  //     background-color: ${(props) => (props.active ? "#3b4045" : "#2a2e31")};
  //   }

  //   &:first-child {
  //     border-top-left-radius: 4px;
  //     border-bottom-left-radius: 4px;
  //   }

  //   &:last-child {
  //     border-top-right-radius: 4px;
  //     border-bottom-right-radius: 4px;
  //   }
`;

//_isMounted can prevent from double socket response

const FEE_WITHDRAWL = forceSatoshiFormat(0.000001);

class WithdrawlHello extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      credit: storage.getKey("credit"),
      showArchive: false,
      slide: false,
      final: false,
      pass: "",
      wallet: "",
      amount: 0.0,
      withdrawAddress: "",
      withdrawAddressError: "",
      withdrawAmount: "0.000000000",
      im: true,
      error: false,
      error2: false,
      loading: false,
      loader: false,
      list: [],
      coin: this.props.coin,
      fee_withdrawal: FEE_WITHDRAWL,
      activeNetwork: "ERC20",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setMaxBits = this.setMaxBits.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    socket.emit(C.CREDIT_COIN, encode({ coin: this.state.coin }));
    socket.on(C.CREDIT_COIN, (data) => this.setCreditCoin(decode(data)));
    socket.on(C.SUBMIT_NEW_WITHDRAWL, (data) => this.response(decode(data)));

    coins.forEach((item, key) => {
      if (item.preffix === "NC") return;

      let list = (
        <Dropdown.Item
          key={key}
          onClick={(e) => this.handleInputCoin(item.active, item.preffix)}
          className={"animated fadeIn"}
        >
          <img
            src={"/assets/images/" + item.image}
            className={"mini-coin-8"}
            alt=""
          />
          {item.preffix}
        </Dropdown.Item>
      );
      this.setState((state) => ({ list: [list, ...state.list] }));
    });

    Event.on("withdraw_archive", () => {
      this.showArchive();
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  response(data) {
    if (this._isMounted) {
      wait(1000).then(() => {
        let message = data.status.toString();
        sendNotfication(message, "info", "top-center");
        socket.emit(C.CREDIT);
        this.setState({
          final: false,
          pass: "",
          showArchive: false,
          loading: false,
        });
      });
    }
  }

  // handleInputChange(event) {
  //   let target = event.target;
  //   let value = target.type === "checkbox" ? target.checked : target.value;

  //   if (target.name === "coin") {
  //     socket.emit(C.CREDIT_COIN, encode({ coin: value }));
  //   }

  //   if (target.name === "amount") {
  //     if (!isValidNumber(value)) return false;

  //     if (value.length > 20) {
  //       return false;
  //     }
  //   }

  //   if (target.name === "amount") {
  //     let error = false;

  //     if (Number(value) > Number(this.maxUserCredit())) error = true;

  //     this.setState({ error: error });
  //   }

  //   this.setState({ [target.name]: value, error3: false });
  // }
  handleInputChange(event) {
    let target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;

    // Allow updates for coin input
    if (target.name === "coin") {
      socket.emit(C.CREDIT_COIN, encode({ coin: value }));
    }

    // For 'amount' input field
    if (target.name === "amount") {
      // Ensure the input is a valid number and doesn't exceed decimal places
      if (!isValidNumber(value)) {
        return; // Stop further execution if the value is not valid
      }

      // Restrict to 12 decimal places for numbers with decimals
      if (value.includes(".") && value.split(".")[1].length > 12) {
        return; // Stop further execution if the number has more than 12 decimals
      }

      // Check if input length is within limit
      if (value.length > 20) {
        return; // Stop further execution if length exceeds 20 characters
      }

      // Error if the amount exceeds the user's max credit
      let error = Number(value) > Number(this.maxUserCredit());
      this.setState({ error });
    }

    // Update the state
    this.setState({ [target.name]: value, error3: false });
  }

  getUserCredit() {
    return forceSatoshiFormat(this.state.credit);
  }

  setCreditCoin(data) {
    let { credit } = data;
    this.setState({ credit: credit, amount: credit });
  }

  maxUserCredit() {
    this.setState({ error: false, error2: false });
    return this.getUserCredit();
  }

  setMaxBits() {
    this.setState({ amount: this.maxUserCredit() });
  }

  // submitForm(e) {
  //   if (this._isMounted) {

  //     e.preventDefault();

  //     let { error, coin, pass, wallet, amount, credit, fee_withdrawal } = this.state;

  //     if (error === true) return

  //     if (!Number(amount) && !amount) return;

  //     if (wallet === "" || wallet.length < 10) {
  //       this.setState({ error3: "Please Enter wallet address" });
  //       return;
  //     }

  //     if (amount <= forceSatoshiFormat(0.0)) {
  //       this.setState({ error3: "Please check amount" });
  //       return;
  //     }

  //     var check = amount - fee_withdrawal;

  //     if (check <= 0.0) {
  //       this.setState({ error3: "Please check amount" });
  //       return;
  //     }

  //     let finalMoney = forceSatoshiFormat(amount - fee_withdrawal);

  //     if (__.toNumber(finalMoney) <= 0) {
  //       this.setState({ error3: "Please check amount" });
  //       return;
  //     }

  //     let restrecFee = true, minFee;

  //     //Check for minimum amount
  //     coins.forEach((item, key) => {
  //       if (__.lowerCase(item.preffix) === __.lowerCase(coin)) {
  //         let min = forceSatoshiFormat(item.min);
  //         finalMoney = __.toNumber(finalMoney);
  //         min = __.toNumber(min);
  //         if (finalMoney < min) {
  //           minFee = forceSatoshiFormat(min);
  //           restrecFee = false;
  //         }
  //       }
  //     });

  //     if (!restrecFee) {
  //       this.setState({ error2: true, minFee: minFee });
  //       return;
  //     }

  //     //Show Second Form [ Final ]
  //     if (pass === "") {
  //       this.setState({ final: true });
  //       return;
  //     }

  //     console.log("istrue",final);

  //     this.setState({ loading: true });
  //    console.log(error, coin, pass, wallet, amount, credit, fee_withdrawal);
  //     wait(500).then(() => {
  //       socket.emit(
  //         C.SUBMIT_NEW_WITHDRAWL,
  //         encode({
  //           coin: coin,
  //           wallet: wallet,
  //           amount: amount,
  //           immed: fee_withdrawal,
  //           password: pass,
  //         })
  //       );
  //     });
  //   }
  // }

  handleInputChangeAddress = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      [`${name}Error`]: "", // Clear the error when the user types
    });
  };

  submitForm(e) {
    e.preventDefault();
    console.log("Submit form called");
    console.log("Current state:", this.state);
    const {
      error,
      coin,
      pass,
      wallet,
      amount,
      credit,
      fee_withdrawal,
      final,
      withdrawAddress,
    } = this.state;

    // Check if withdrawAddress is empty
    if (!withdrawAddress.trim()) {
      this.setState({ withdrawAddressError: "Withdraw address is required" });
      return;
    }

    if (!this._isMounted) {
      console.log("Component not mounted, returning");
      return;
    }

    console.log("Destructured values:", {
      error,
      coin,
      pass,
      wallet,
      amount,
      fee_withdrawal,
      final,
      withdrawAddress,
    });

    // Validation checks with logging
    if (error) {
      console.log("Returning due to existing error");
      return;
    }

    if (!amount || amount <= 0) {
      console.log("Invalid amount, setting error3");
      this.setState({ error3: "Please enter a valid amount" });
      return;
    }

    // if (!wallet || wallet.length < 10) {
    //   console.log('Invalid wallet, setting error3');
    //   this.setState({ error3: "Please enter a valid wallet address" });
    //   return;
    // }
    const fee = (1 * amount) / 100;
    const finalAmount = Number(amount) - Number(fee);
    console.log(fee);
    console.log("Calculated finalAmount:", finalAmount);

    if (finalAmount <= 0) {
      console.log("Final amount too low, setting error3");
      this.setState({ error3: "Amount after fee must be greater than 0" });
      return;
    }

    // Check minimum withdrawal amount
    const coin_info = coins.find(
      (item) => item.preffix.toLowerCase() === coin.toLowerCase()
    );
    console.log("Found coin_info:", coin_info);

    if (coin_info && finalAmount < Number(coin_info.min)) {
      console.log("Amount below minimum, setting error2 and error3");
      this.setState({
        error2: true,
        minFee: coin_info.min,
        error3: `Minimum withdrawal amount is ${coin_info.min} ${coin}`,
      });
      return;
    }

    // This is where final gets set to true
    if (!final) {
      console.log("Setting final to true");
      this.setState({ final: true });
      return;
    }

    console.log("Reached final check:", final);

    if (pass) {
      console.log("Password provided, proceeding with withdrawal");
      this.setState({ loading: true });

      setTimeout(() => {
        socket.emit(
          C.SUBMIT_NEW_WITHDRAWL,
          encode({
            coin: coin,
            wallet: withdrawAddress,
            amount: amount,
            immed: fee,
            password: pass,
          })
        );
      }, 500);
    }
  }

  onChange(event, list) {
    this.setState({ list: list });
  }

  back(e) {
    e.preventDefault();
    this.setState({ final: false, pass: "" });
  }

  enterPass(e) {
    this.setState({ pass: e.target.value });
  }

  showArchive = (e) => {
    if (this.state.slide) Event.emit("withdraw_archive_back");

    this.setState({ slide: !this.state.slide });

    wait(500).then(() => {
      this.setState({ showArchive: !this.state.showArchive });
    });
  };

  handleInputCoin = (active, value) => {
    if (active) {
      socket.emit(C.CREDIT_COIN, encode({ coin: value }));
    } else {
      this.setState({ amount: forceSatoshiFormat(0) });
    }
    this.setState({ coin: value, error: false, error2: false });
  };

  getFee = (coin) => {
    let find = __.find(coins, function (o) {
      return o.preffix === __.upperCase(coin);
    });

    return find.min;
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleButtonClick = (percentage) => {
    // Simulating max amount, replace with actual max amount logic
    const maxAmount = 1.0;
    let newAmount;

    switch (percentage) {
      case "min":
        newAmount = 5.0; // As per the minimum shown in the image
        break;
      case "25":
        newAmount = this.state.credit * 0.25;
        break;
      case "50":
        newAmount = this.state.credit * 0.5;
        break;
      case "max":
        newAmount = this.state.credit;
        break;
      default:
        newAmount = 0;
    }

    this.setState({ withdrawAmount: newAmount });
    this.setState({ amount: newAmount });
  };

  setActiveNetwork = (network) => {
    this.setState({ activeNetwork: network });
    // const chain=this.getChainValue(network)
    // socket.emit(C.GET_ADDRESS, encode({ coin: this.state.currentCoin,chain: chain }))
  };

  render() {
    let UNIT = this.state.coin;
    const networks = ["ERC20", "BEP20", "TRC20"];
    let {
      loader,
      final,
      error,
      showArchive,
      amount,
      credit,
      error2,
      minFee,
      fee_withdrawal,
      error3,
      withdrawAddressError,
    } = this.state;
    let details = withdrawlDetail(amount, fee_withdrawal, UNIT, credit, error);
    const top = true;

    return (
      <>
        {showArchive ? (
          <div className={this.state.slide === false ? "animated fadeOut" : ""}>
            <WithdrawlArchive clicked={this.showArchive} />
          </div>
        ) : (
          <>
            <div
              className={this.state.slide ? "animated fadeOut mt-1" : " mt-1"}
            >
              {final ? (
                <>
                  <Row
                    className={
                      this.state.final === true ? "animated fadeIn" : ""
                    }
                  >
                    <Col md="7" lg="7" sm="12" className="m-auto">
                      <div className={"m-auto text-center"}>
                        <h5 className={"text-success"}>
                          Total Amount to Withdrawl:
                          <b className="ml-2">
                            {forceSatoshiFormat(
                              this.state.amount - this.state.fee_withdrawal
                            )}{" "}
                            {UNIT}
                          </b>
                        </h5>
                      </div>
                      <div></div>

                      <div className={"m-auto text-center"}>
                        <form
                          className="w-100"
                          onSubmit={(e) => {
                            this.submitForm(e);
                          }}
                        >
                          <div className="form-group text-center text-darker">
                            <label>Enter Your Password</label>
                            <input
                              name={"password"}
                              type="password"
                              value={this.state.pass}
                              onChange={(e) => this.enterPass(e)}
                              className={"form-control bgl2"}
                              required={true}
                            />
                          </div>
                          <div className="form-group mt-3 text-center">
                            <Button
                              variant="btn btn-btn bg-cs shadow-none btn-sm mr-1"
                              type="button"
                              onClick={(e) => this.back(e)}
                            >
                              <i className="mdi mdi-refresh" /> Back
                            </Button>
                            <Button
                              onClick={(e) => this.submitForm(e)}
                              variant="btn btn-btn bg-cs shadow-none btn-sm"
                              type="submit"
                              disabled={this.state.loading}
                            >
                              <i className="mdi mdi-send" /> Send To Wallet
                            </Button>
                          </div>
                        </form>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <div className={"m-auto wallet"}>
                    <form
                      className="w-100"
                      onSubmit={(e) => {
                        this.submitForm(e);
                      }}
                    >
                      {loader ? (
                        <></>
                      ) : (
                        <FormContainer>
                          {this.state.coin === "USDT" && (
                            <>
                              <div style={{ color: "gray" }}>
                                Choose Network
                              </div>
                              <ButtonGroupE>
                                {networks.map((network) => (
                                  <ButtonE
                                    key={network}
                                    active={
                                      this.state.activeNetwork === network
                                    }
                                    onClick={() => {
                                      this.setActiveNetwork(network);
                                    }}
                                  >
                                    {network}
                                  </ButtonE>
                                ))}
                              </ButtonGroupE>
                            </>
                          )}
                          <InputContainer>
                            {this.props.coin != "INR" ? (
                              <Label>
                                Withdraw Address{" "}
                                <NoteText>
                                  (Note: Only {this.props.coin})
                                </NoteText>
                              </Label>
                            ) : (
                              <Label>
                                Withdraw UPI / PhonePe Number / Gpay Number{" "}
                                <NoteText>
                                  (Note: Only {this.props.coin})
                                </NoteText>
                              </Label>
                            )}
                            <Input
                              type="text"
                              name="withdrawAddress"
                              value={this.state.withdrawAddress}
                              onChange={this.handleInputChangeAddress}
                              placeholder={
                                this.props.coin != "INR"
                                  ? "Fill in carefully according to the specific currency"
                                  : "Enter your UPI ID / PhonePe / Gpay"
                              }
                            />
                            {withdrawAddressError && (
                              <ErrorMessage>
                                {withdrawAddressError}
                              </ErrorMessage>
                            )}
                          </InputContainer>
                          <InputContainer>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Label>Withdraw amount</Label>
                              <span style={{ color: "#a0a0a0" }}>
                                Min: {this.getFee(this.state.coin)} {UNIT}
                              </span>
                            </div>

                            <InputWrapper>
                              {/* <Input
                                type="number"
                                name="withdrawAmount"
                                value={this.state.amount}
                                onChange={this.handleInputChange}
                                step="0.000000001"
                                min="0"
                              /> */}
                              <Input
                                type="number"
                                name="amount"
                                value={this.state.amount}
                                onChange={this.handleInputChange}
                                step="0.000000000001" // Adjust step to match the number of decimal places you want
                                min="0"
                                onWheel={(e) => e.target.blur()} // Prevent scrolling issue with number inputs
                              />

                              <ButtonGroup>
                                <ButtonWithdrawl
                                  onClick={() => this.handleButtonClick("min")}
                                >
                                  Min
                                </ButtonWithdrawl>
                                <ButtonWithdrawl
                                  onClick={() => this.handleButtonClick("25")}
                                >
                                  25%
                                </ButtonWithdrawl>
                                <ButtonWithdrawl
                                  onClick={() => this.handleButtonClick("50")}
                                >
                                  50%
                                </ButtonWithdrawl>
                                <ButtonWithdrawl
                                  onClick={() => this.handleButtonClick("max")}
                                >
                                  Max
                                </ButtonWithdrawl>
                              </ButtonGroup>
                            </InputWrapper>
                          </InputContainer>
                          <UnlockText>Unlock at VIP 22 ?</UnlockText>

                          <SubmitContainer>
                            <FeeText>
                              Fee 1 % :{" "}
                              <GreenText>
                                {" "}
                                {(Number(this.state.amount) * 1) / 100}{" "}
                                {this.state.coin}
                              </GreenText>
                            </FeeText>
                            <StyledButton
                              onClick={(e) => this.submitForm(e)}
                              type="button" // Change from submit to button
                              disabled={this.state.loading}
                            >
                              {this.state.loading ? "Processing..." : "Confirm"}
                            </StyledButton>
                          </SubmitContainer>
                        </FormContainer>
                      )}
                    </form>
                    <div className="form-group mt-2 mb-0">
                      {loader ? (
                        <div className="ycenter text-center"></div>
                      ) : (
                        // <div className="withdrawl-detail p-1 bg-soft-dark text-white">
                        //   {details}
                        // </div>
                        <></>
                      )}
                      {loader ? (
                        <></>
                      ) : (
                        // <p className="mt-1 mb-0 p-2 bg-soft-dark text-white">

                        // </p>
                        <></>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

function withdrawlDetail(amount, fee, coin, credit, error) {
  let total = forceSatoshiFormat(amount - fee);

  return (
    <>
      {/* <ul className={"p-2 m-0"}>
        <li>
          Balance available for withdrawal: <b>{forceSatoshiFormat(credit)}</b>{" "}
          {coin}
        </li>
        <li>
          Amount to Withdraw: <b>{amount}</b> {coin}
        </li>
        <li>
          Withdrawal Fee: <b>{forceSatoshiFormat(fee)}</b> {coin}
        </li>
        <li>Total: <span className="text-yellow">{total}</span></li>
      </ul> */}
    </>
  );
}

export default WithdrawlHello;
