import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { ChevronRight, Headphones } from "lucide-react";
import { addNewStyle, CRISTP_ID, wait } from "../../../Helper";

import LanguageSelectorModal from "../LanguageSelector/LanguageSelectorModal";

const SupportButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #27272a;
  border: none;
  width: 200px;
  border-radius: 10px;
  padding-right: 20px;
  cursor: pointer;
  transition: background-color 0.2s;

  ${(props) =>
    props.isCollapsed &&
    `width: unset; width: max-content; padding: 0px !important;`}

  &:hover {
    background-color: #2a2a2a;
  }
`;

const IconWrapperSupport = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

const Text = styled.span`
  color: white;
  font-size: 14px;
  font-weight: 500;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  border-radius: 8px;
`;

const Title = styled.div`
  color: #9ca3af;
  font-size: 14px;
  margin-left: 10px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  z-index: 990;
`;

const LanguageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #27272a;
  color: white;
  padding: 8px 12px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  ${(props) =>
    props.isCollapsed &&
    `width: unset; width: max-content; padding: 10px !important; margin: auto;`}

  &:hover {
    background-color: #3f3f46;
  }
`;

const ButtonText = styled.span`
  font-size: 14px;
`;

const StyledChevronRight = styled(ChevronRight)`
  color: #9ca3af;
`;

const SidebarContainer = styled.nav`
  position: fixed;
  top: 70px;
  left: 0px;
  color: #ffffff;
  background-color: rgb(30, 32, 36);
  height: 100vh;
  z-index: 990;
  padding: 8px 0px;
  padding-bottom: 5rem;
  transition: width 0.3s ease-in-out;
  width: ${(props) => (props.isCollapsed ? "80px" : "239px")};
`;

const SidebarTop = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  width: ${(props) => (props.isCollapsed ? "80px" : "239px")};
  // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  background-color: rgb(30, 32, 36);
  padding: ${(props) => (props.isCollapsed ? "10px" : "16px 10px")};
  padding-right: 44px;
  color: #efb81c;
  z-index: 990;
  border-bottom: 1px solid #ffffff30;

  padding: 0px !important;
  border: none !important;
`;

const CircularBarContainer = styled.button`
  position: fixed;
  top: ${(props) => (props.isCollapsed ? "15px" : "20px")};
  left: ${(props) => (props.isCollapsed ? "10px" : "221px")};
  width: ${(props) => (props.isCollapsed ? "50px" : "max-content")};
  height: ${(props) => (props.isCollapsed ? "50px" : "1.875rem")};
  border-radius: 0.9375rem;
  background-color: rgb(26, 28, 32);
  box-shadow: transparent 0px 2px 4px 0px;
  z-index: 990;
  transition: right 0.2s linear;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  margin-left: auto;

  svg {
    fill: white;
  }
`;

const NavMenuLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const MenuItemLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(props) => props.color || "#ffffff"};
  padding: 4px 8px;
  margin-left: 20px;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  width: calc(100% - 40px);
  text-decoration: none;
  font-style: ${(props) =>
    props.isVIP || props.isCasino || props.isMultiplayer ? "italic" : "normal"};
  transition: all 0.3s ease;

  /* Active state with background highlight */
  ${({ isCollapsed, active }) =>
    !isCollapsed &&
    active &&
    `
      span {
        color: #efb81c !important;
        font-weight: 600;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        min-height: 100%;
        // background-color: rgba(76, 175, 80, 0.2);
        border-radius: 10px;
        transition: background-color 0.3s ease;
        box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(255, 255, 255, 0.2) 0px 1px 3px -10px, rgba(255, 255, 255, 0.15) 0px 1px 0px inset;
      }
    `}

  ${({ isSubmenu }) =>
    isSubmenu &&
    `
    min-width: unset;
    max-width: unset;
    width: max-content;
    padding: 0px;
    padding-right: 15px;
    margin: 0px;
  `}

/* Adjustments for collapsed state */
${({ isCollapsed }) =>
    isCollapsed &&
    `
    justify-content: center;
  `}

  /* Grayscale filter for specific items */
  ${({ isMultiplayer, isCasino }) =>
    isMultiplayer
      ? `filter: grayscale(100%); cursor: not-allowed;`
      : isCasino
      ? `filter: grayscale(100%); cursor: not-allowed;`
      : ``}

  &:hover {
    background-color: rgba(255, 255, 255, 0.025);
    transition: background-color 0.3s ease;
    color: #efb81c !important;
  }

  &:hover span {
    color: #efb81c !important;
  }
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  ${(props) =>
    props.isCollapsed &&
    props.active &&
    `
      background-color: #2E3238;
      border-radius: 10px;
      padding: 8px 15px;
    `}
`;

const Icon = styled.img`
  // width: 40px;
  height: auto;
`;

const Label = styled.span`
  position: relative;
  z-index: 1;
  color: ${(props) => {
    if (props.isVIP) return "#EB5D0E";
    return "#808A94";
  }} !important;
  display: ${(props) => (props.isCollapsed ? "none" : "inline")};
`;

const Divider = styled.div`
  height: 1px;
  background-color: #353535;
  margin: 8px ${(props) => (props.isCollapsed ? "4px" : "16px")};
`;

const SwitcherContainer = styled.div`
  display: none;
  border-radius: 10px;
  gap: 5px;
`;

const Tab = styled.div`
  color: #9ca3af;
  font-size: 14px;
  padding: 8px 22px;
  border-radius: 10px;
  transition: all 0.3s, color 0.3s;

  ${(props) =>
    props.active
      ? `
    color: #fff;
    background-size: 200% auto;
    text-align: center;
    background-image: linear-gradient(to right, #DA22FF 0%, #9733EE  51%, #DA22FF  100%)
  `
      : `
    &:hover {
      background-position: right center;
      color: #fff;
      text-decoration: none;
   }
  `}
`;

const ThemeSwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 5px;
  background-color: #27272a;
  transition: 0.3s ease;
  width: max-content;
  margin: 20px auto;

  ${(props) =>
    props.isCollapsed &&
    `
    padding: 0px;

    button {
      border-radius: 5px;
      padding: 12px;
    }
    `}
`;

const ThemeSwitcherButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px;
  background-color: #ffffff00;
  color: #ffffff10;
  filter: grayscale(1) brightness(0.5);
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &.active-light {
    background-color: #ffffff;
    color: #000000 !important;
    filter: none;
  }

  &.active-dark {
    background-color: #00000050;
    color: #ffffff;
    filter: none;
  }
`;

const SidebarHello = ({ isCollapsed, setIsCollapsed }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Casino");
  const tabs = ["Casino", "Sports"];

  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  const [loaded, setLoaded] = useState(false);

  const [theme, setTheme] = useState("dark");

  const handleToggle = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const support = () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CRISTP_ID;

    if (!loaded) {
      console.log("support");
      setLoaded(true);
      const script = document.createElement("script");
      script.src = "https://client.crisp.chat/l.js";
      script.id = "chtt";
      script.async = true;
      document.head.appendChild(script);
    }

    addNewStyle("#crisp-chatbox {display:block !important;}");
    addNewStyle(
      ".crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}"
    );

    wait(2000).then(() => {
      const close = document.querySelector(".cc-wdhl");
      if (close === null) return;
      close.addEventListener("click", function (event) {
        addNewStyle("#crisp-chatbox {display:none !important;}");
        document.getElementById("chtt").src = "";
      });
    });
  };

  useEffect(() => {
    const toggleValue = localStorage.getItem("isCollapsed");
    if (toggleValue !== null) {
      setIsCollapsed(toggleValue === "true");
    }
  }, []);

  const toggleSidebar = () => {
    const newIsCollapsed = !isCollapsed;
    setIsCollapsed(newIsCollapsed);
    localStorage.setItem("isCollapsed", newIsCollapsed.toString());
  };

  const menuItems = [
    {
      name: "Home",
      icon: "/assets/images/sidebar/bid-win-lobby.webp",
      width: "30px",
      path: "/",
    },
    {
      name: "Originals",
      icon: "/assets/images/sidebar/Originals-Star.svg",
      width: "22px",
      path: "/games",
    },
    {
      name: "Slots",
      icon: "/assets/images/sidebar/bid-win-slots-games.webp",
      width: "25px",
      path: "/slots",
    },
    {
      name: "Lottery",
      icon: "/assets/images/sidebar/lottery-ticket.webp",
      width: "25px",
      path: "/wingo",
    },
    {
      name: "Multiplayer",
      icon: "/assets/images/sidebar/bid-win-multiplayer-games.webp",
      width: "25px",
      color: "rgb(93, 219, 28)",
      path: "#!",
    },
    {
      name: "Top Trending",
      icon: "/assets/images/sidebar/bidwinx-trending-games.webp",
      width: "22px",
      path: "#!",
    },
    {
      name: "Promotions",
      icon: "/assets/images/sidebar/bidwinx-promotions.webp",
      width: "25px",
      color: "rgb(93, 219, 28)",
      path: "/promotions",
      submenu: [
        {
          name: "Rakeback",
          icon: "/assets/images/sidebar/rakeback.webp",
          width: "28px",
          path: "/rakeback",
        },
      ],
    },
    { divider: true },
    {
      name: "Affiliate",
      icon: "/assets/images/sidebar/bid-win-affiliate-program.webp",
      width: "24px",
      path: "/affiliate",
    },
    {
      name: "VIP Club",
      icon: "/assets/images/sidebar/bid-win-vip-club.webp",
      width: "24px",
      color: "rgb(235, 93, 15)",
      path: "/vip-club",
    },
    { divider: true },
    {
      name: "Help Center",
      icon: "/assets/images/sidebar/bid-win-help-center.webp",
      width: "24px",
      color: "rgb(235, 93, 15)",
      path: "/provably-fair",
    },
    {
      name: "About Us",
      icon: "/assets/images/sidebar/about-bid-win.webp",
      width: "24px",
      color: "rgb(93, 219, 28)",
      path: "/about-us",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <SidebarTop isCollapsed={isCollapsed}>
        {!isCollapsed && (
          <SwitcherContainer isCollapsed={isCollapsed}>
            {tabs.map((tab) => (
              <Tab
                key={tab}
                active={activeTab === tab}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </Tab>
            ))}
          </SwitcherContainer>
        )}

        <CircularBarContainer onClick={toggleSidebar} isCollapsed={isCollapsed}>
          <svg
            width="20"
            height="20"
            stroke-width="0"
            class=""
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="none"
            stroke="#fff"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 5C3 4.44772 3.44772 4 4 4H16C16.5523 4 17 4.44772 17 5C17 5.55228 16.5523 6 16 6H4C3.44772 6 3 5.55228 3 5Z"
              fill="#ffffff75"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 10C3 9.44772 3.44772 9 4 9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H4C3.44772 11 3 10.5523 3 10Z"
              fill="#ffffff75"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 15C3 14.4477 3.44772 14 4 14H16C16.5523 14 17 14.4477 17 15C17 15.5523 16.5523 16 16 16H4C3.44772 16 3 15.5523 3 15Z"
              fill="#ffffff75"
            ></path>
          </svg>
        </CircularBarContainer>
      </SidebarTop>

      <SidebarContainer isCollapsed={isCollapsed}>
        <div
          style={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            scrollBehavior: "smooth",
            scrollbarWidth: "none",
          }}
        >
          {menuItems.map((item, index) =>
            item.divider ? (
              <Divider key={`divider-${index}`} isCollapsed={isCollapsed} />
            ) : (
              <React.Fragment key={item.name}>
                <MenuItemLink
                  to={item.path}
                  active={location.pathname === item.path}
                  color={item.color}
                  isCollapsed={isCollapsed}
                  isVIP={item.name === "VIP Club"}
                  isMultiplayer={item.name === "Multiplayer"}
                  isCasino={item.name === "Top Trending"}
                >
                  <IconWrapper
                    active={location.pathname === item.path}
                    isCollapsed={isCollapsed}
                  >
                    <Icon src={item.icon} width={item.width} alt={item.name} />
                  </IconWrapper>
                  <Label
                    active={location.pathname === item.path}
                    isCollapsed={isCollapsed}
                    isVIP={item.name === "VIP Club"}
                  >
                    {item.name}
                  </Label>
                </MenuItemLink>

                {!isCollapsed && (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      marginLeft: "60px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {item.submenu &&
                      item.submenu.map((subItem, subIndex) => (
                        <MenuItemLink
                          key={`${subItem.name}-${subIndex}`}
                          to={subItem.path}
                          active={location.pathname === subItem.path}
                          isSubmenu={true}
                          title={subItem.name}
                        >
                          <IconWrapper
                            active={location.pathname === subItem.path}
                            isCollapsed={isCollapsed}
                          >
                            <Icon
                              src={subItem.icon}
                              width={subItem.width}
                              alt={subItem.name}
                            />
                          </IconWrapper>
                          <Label
                            active={location.pathname === item.path}
                            isCollapsed={isCollapsed}
                          >
                            {subItem.name}
                          </Label>
                        </MenuItemLink>
                      ))}
                  </div>
                )}
              </React.Fragment>
            )
          )}

          <Divider />

          {/* Language Support Button */}
          <Flex>
            <Container>
              <LanguageButton
                isCollapsed={isCollapsed}
                onClick={() => setLanguageSelectorOpen(true)}
              >
                <ButtonText>{isCollapsed ? "EN" : selectedLanguage}</ButtonText>
                {!isCollapsed && <StyledChevronRight>▶️</StyledChevronRight>}
              </LanguageButton>

              {languageSelectorOpen && (
                <LanguageSelectorModal
                  setModalClosed={setLanguageSelectorOpen}
                  setLanguage={(lang) => {
                    setSelectedLanguage(lang);
                    setLanguageSelectorOpen(false);
                  }}
                />
              )}
            </Container>
          </Flex>

          <Divider />

          {/* Customer Support Button */}
          <Flex
            onClick={support}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <SupportButton isCollapsed={isCollapsed}>
              <IconWrapperSupport>
                <Headphones size={18} color="white" />
              </IconWrapperSupport>
              {!isCollapsed && <Text>Live Support</Text>}
            </SupportButton>
          </Flex>

          {/* Theme Switcher Button */}
          <ThemeSwitcherWrapper isCollapsed={isCollapsed}>
            {isCollapsed ? (
              <ThemeSwitcherButton
                onClick={handleToggle}
                theme={theme}
                aria-label="Switch Theme"
                className={`${
                  theme === "dark" ? "active-dark" : "active-light"
                }`}
              >
                <img
                  src={`${
                    theme === "dark"
                      ? "/assets/images/sidebar/svg/dark-theme-moon.svg"
                      : "/assets/images/sidebar/svg/light-theme-sun.svg"
                  }`}
                  alt="Moon Icon"
                  width={theme === "dark" ? "15px" : "18px"}
                  height={"auto"}
                />
              </ThemeSwitcherButton>
            ) : (
              <>
                <ThemeSwitcherButton
                  onClick={handleToggle}
                  theme={theme}
                  aria-label="Switch Theme"
                  className={`${theme === "dark" && "active-dark"}`}
                >
                  <img
                    src="/assets/images/sidebar/svg/dark-theme-moon.svg"
                    alt="Moon Icon"
                    width={"15px"}
                    height={"auto"}
                  />
                  Dark
                </ThemeSwitcherButton>
                <ThemeSwitcherButton
                  onClick={handleToggle}
                  theme={theme}
                  aria-label="Switch Theme"
                  className={`${theme === "light" && "active-light"}`}
                >
                  <img
                    src="/assets/images/sidebar/svg/light-theme-sun.svg"
                    alt="sun Icon"
                    width={"18px"}
                    height={"auto"}
                  />
                  Light
                </ThemeSwitcherButton>
              </>
            )}
          </ThemeSwitcherWrapper>
        </div>
      </SidebarContainer>
    </div>
  );
};

export default SidebarHello;
