import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Search } from "lucide-react";
import { isMobile, Event } from "../../../../Helper";
import Login from "../../Auth/Login";
import Register from "../../Auth/Register";

import { Menu, X } from "lucide-react";

const HeaderContainer = styled.header`
  background-color: #23262b !important;
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  padding: 0 20px;
  z-index: 1000;
  justify-content: space-between;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  @media (max-width: 768px) {
    height: 70px;
    padding: 0 10px;
    padding-right: 20px;
  }
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 26px;
  cursor: pointer;
  z-index: 1001;
  padding: 0 !important;
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #1b1e22;
  border-radius: 10px;
  padding: 10px 16px;
  width: 100%;
  max-width: 500px;
  margin-right: auto;
  transition: outline 0.2s ease-in-out;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  margin-left: 8px;
  outline: none;

  &::placeholder {
    color: #a0a0a0;
  }
`;

const LogoContainer = styled.div`
  margin-right: 10px;
`;

const WebLogo = styled.img`
  width: 150px;
  height: auto;
`;

const MobileLogo = styled.img`
  width: 100px;
  height: auto;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Header = ({ isCollapsedMobile, setIsCollapsedMobile }) => {
  const [show, setShow] = useState(true);
  const [smallerScreenView, setSmallerScreenView] = useState(
    window.innerWidth > 768
  );
  useEffect(() => {
    const handleResize = () => {
      setSmallerScreenView(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const collapse = () => {
    setShow(!show);
    Event.emit("toggle_sidebar", show);
  };

  const renderContent = () => (
    <HeaderContainer>
      <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
        {!smallerScreenView && (
          <ToggleButton
            onClick={() => setIsCollapsedMobile(!isCollapsedMobile)}
          >
            {isCollapsedMobile ? (
              <X size={24} style={{ position: "absolute", left: "191px" }} />
            ) : (
              <Menu size={24} />
            )}
          </ToggleButton>
        )}
        <LogoContainer>
          <Link to="/" style={{ maxheight: "80px" }} title="BidwinX Home">
            {smallerScreenView ? (
              <WebLogo src="assets/images/logo/bidwinx-logo.png" alt="Logo" />
            ) : (
              <MobileLogo
                src="assets/images/logo/bidwinx-logo.png"
                alt="Logo"
              />
            )}
          </Link>
        </LogoContainer>
      </div>

      {smallerScreenView && (
        <SearchBarContainer isFocused={show}>
          <Search size={18} style={{ color: "#DA22FF50" }} />
          <SearchInput
            type="text"
            placeholder="Search..."
            onFocus={() => setShow(true)}
            onBlur={() => setShow(false)}
          />
        </SearchBarContainer>
      )}

      <RightWrapper>
        <Login />
        <Register />
      </RightWrapper>
    </HeaderContainer>
  );

  return renderContent();
};

export default Header;
