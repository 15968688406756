import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AffiliateDashboard from "./AffiliateDashboard";
import AffiliateReferral from "./AffiliateReferral";
import AffiliateRewards from "./AffiliateRewards";
import AffiliateRules from "./AffiliateRules";

const AffiliateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 0rem;
`;

const AffiliateNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

const Title = styled.h1`
  margin: 0px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
`;

const AffiliateNav = styled.ul`
  display: flex;
  gap: 10px;
  margin: 0px;
  padding: 5px;
  list-style: none;
  border-radius: 5px;
  background-color: #191a1e;

  li {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

const NavLinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  filter: grayscale(1) brightness(0.6);
  cursor: pointer;
  padding: 6px 18px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &.active-nav {
    filter: grayscale(0) brightness(1);
    background-color: #2c3137;
    color: #ffffff;
  }

  &:hover {
    background-color: #2c3137;
  }
`;

const AffiliateBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

class Affiliate extends Component {
  constructor(props) {
    super(props);
    // Initialize the state
    this.state = {
      activeRoute: "/affiliate/dashboard",
    };
  }

  // Function to handle the route change
  handleRouteChange = (route) => {
    this.setState({ activeRoute: route });
  };

  render() {
    const { activeRoute } = this.state;

    const navItemLinks = [
      {
        id: 1,
        name: "Dashboard",
        icon: "/assets/images/dashboard.webp",
        route: "/affiliate/dashboard",
      },
      {
        id: 2,
        name: "My Rewards",
        icon: "/assets/images/rewards.webp",
        route: "/affiliate/my-rewards",
      },
      {
        id: 3,
        name: "Referral Codes & Friends",
        icon: "/assets/images/referral.webp",
        route: "/affiliate/referral/codes",
      },
      {
        id: 4,
        name: "Rate & Rules",
        icon: "/assets/images/rate-and-rules.webp",
        route: "/affiliate/rules",
      },
    ];

    return (
      <AffiliateWrapper className="container">
        <AffiliateNavWrapper>
          <Title>Affiliate</Title>
          <AffiliateNav>
            {navItemLinks.map((item) => (
              <li key={item.id}>
                <NavLinkStyled
                  to={item.route}
                  className={activeRoute === item.route ? "active-nav" : ""}
                  onClick={() => this.handleRouteChange(item.route)}
                >
                  <img src={item.icon} alt={item.name} width={25} height={25} />
                  <span>{item.name}</span>
                </NavLinkStyled>
              </li>
            ))}
          </AffiliateNav>
        </AffiliateNavWrapper>

        <AffiliateBody>
          {activeRoute === "/affiliate/dashboard" && <AffiliateDashboard />}
          {activeRoute === "/affiliate/rules" && <AffiliateRules />}
          {activeRoute === "/affiliate/referral/codes" && <AffiliateReferral />}
          {activeRoute === "/affiliate/referral/my-rewards" && (
            <AffiliateRewards />
          )}
        </AffiliateBody>
      </AffiliateWrapper>
    );
  }
}

export default Affiliate;
