import React from "react";
import styled from "styled-components";
import GsCard from "./BannerCard";
import GsCard1 from "./BannerCard2";

import TopWinnersIcon from "./bid-win-top-winners.webp";
import TopWinningGamesIcon from "./bid-win-top-games.webp";

const Container = styled.div`
  background-color: rgb(25, 27, 30);
  color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 5px;
  border-radius: 8px;
  max-height: 220px; /* Adjust as necessary */
  overflow: hidden; /* Prevent overflow */

  @media (max-width: 768px) {
    overflow-y: hidden;
  }

  /* Responsive grid columns for larger screens */
  @media (min-width: 1200px) {
    max-height: 120px;
    overflow-y: hidden;
  }
`;

const GridItem = styled.div`
  font-size: 1em;
  color: white;

  &:nth-last-child(-n + 2) {
    display: none;
  }
`;

const TopHeading = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
`;

const HeadingContainer = styled.div`
  padding: 8px 6px;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
`;

const PinkText = styled.span`
  color: #da1341;
  font-size: 14px;
  font-family: Arial, sans-serif;
`;

const YellowText = styled.span`
  color: #ffa800;
  font-size: 14px;
  font-family: Arial, sans-serif;
`;

const WhiteText = styled.span`
  color: white;
  font-size: 14px;
  font-family: Arial, sans-serif;
  margin-left: 4px;
`;

const GreenText = styled.span`
  color: #14c455;
  font-size: 14px;
  font-family: Arial, sans-serif;
`;

const GSIconPlaceholder = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 8px;
`;

const BannerGames = () => {
  return (
    <Container>
      {/* Grid Item Starts */}
      <GridItem>
        <TopHeading>
          <GSIconPlaceholder src={TopWinnersIcon} />
          <HeadingContainer>
            <PinkText>Top</PinkText>
            <WhiteText>Winners</WhiteText>
          </HeadingContainer>
        </TopHeading>
        <GsCard />
      </GridItem>
      {/* Grid Item Ends */}

      {/* Grid Item Starts */}
      <GridItem>
        <TopHeading>
          <img
            src={TopWinningGamesIcon}
            style={{ height: "30px", width: "30px", objectFit: "contain" }}
          />
          <HeadingContainer>
            <YellowText>Top</YellowText>
            <WhiteText>Winning Games</WhiteText>
          </HeadingContainer>
        </TopHeading>
        <GsCard1 />
      </GridItem>
      {/* Grid Item Ends */}

      {/* Grid Item Starts */}
      <GridItem>
        <TopHeading>
          <img
            src="/assets/images/nano/popular.webp"
            style={{ height: "30px", width: "30px", objectFit: "contain" }}
          />
          <HeadingContainer>
            <GreenText>Trending</GreenText>
            <WhiteText>Right Now</WhiteText>
          </HeadingContainer>
        </TopHeading>
        <GsCard />
      </GridItem>
      {/* Grid Item Ends */}

      {/* Grid Item Starts */}
      <GridItem>
        <TopHeading>
          <img
            src="/assets/images/nano/recent.webp"
            style={{ height: "30px", width: "30px", objectFit: "contain" }}
          />
          <HeadingContainer>
            <WhiteText>Recently Added</WhiteText>
          </HeadingContainer>
        </TopHeading>
        <GsCard />
      </GridItem>
      {/* Grid Item Ends */}
    </Container>
  );
};

export default BannerGames;
