import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { forceSatoshiFormat } from "../../../Helper";

const Container = styled.div`
  background-color: #1e2024;
  color: white;
  padding: 12px;
  border-radius: 20px;
  font-family: Arial, sans-serif;

  @media (min-width: 768px) {
    padding: 20px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 2px;
  table-layout: fixed;

  @media (min-width: 768px) {
    border-spacing: 0 4px;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 6px;
  color: #99a4b0;
  font-weight: bold;
  font-size: 0.875rem;

  @media (min-width: 768px) {
    padding: 10px;
    font-size: 1rem;
  }

  &:first-child {
    padding-left: 8px;
    width: 20%;
  }

  &:nth-child(2) {
    width: 20%;
  }

  &:nth-child(3) {
    width: 25%;
  }

  &:nth-child(4) {
    width: 25%;
  }

  &:last-child {
    width: 20%;
  }
`;

const TableCell = styled.td`
  padding: 6px;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 768px) {
    padding: 10px;
    font-size: 1rem;
  }

  &:first-child {
    padding-left: 8px;
  }
`;

const GreenText = styled.span`
  color: #da22ff;
  font-size: 0.875rem;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const RedText = styled.span`
  color: #f44336;
  font-size: 0.875rem;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const AnimatedTableRow = styled.tr`
  background-color: transparent;
  transition: background-color 0.3s ease;
`;

const CryptoIcon = styled.img`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  flex-shrink: 0;

  @media (min-width: 768px) {
    height: 16px;
    width: 16px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  overflow: hidden;

  @media (min-width: 768px) {
    gap: 4px;
  }
`;

const UserText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  @media (max-width: 767px) {
    max-width: 60px;
  }
`;

const AmountText = styled.div`
  color: #99a4b0;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const formatTime = (date) => {
  return date.toLocaleTimeString("en-US", { hour12: false });
};

// Define cryptocurrency configurations
const cryptoConfig = {
  ETH: {
    icon: "/assets/images/ETH.png",
    minBet: 0.001,
    maxBet: 0.1,
    decimals: 6,
  },
  BTC: {
    icon: "/assets/images/BTC.png",
    minBet: 0.00001,
    maxBet: 0.001,
    decimals: 7,
  },
  USDT: {
    icon: "/assets/images/USDT.png",
    minBet: 1,
    maxBet: 100,
    decimals: 2,
  },
  DOGE: {
    icon: "/assets/images/DOGE.png",
    minBet: 50.0,
    maxBet: 500.0,
    decimals: 7,
  },
  LTC: {
    icon: "/assets/images/LTC.png",
    minBet: 0.001,
    maxBet: 0.1,
    decimals: 7,
  },
  BCH: {
    icon: "/assets/images/LTC.png",
    minBet: 0.001,
    maxBet: 0.1,
    decimals: 7,
  },
  TRON: {
    icon: "/assets/images/TRON.png",
    minBet: 20.0,
    maxBet: 100.0,
    decimals: 7,
  },
  INR: {
    icon: "/assets/images/INR.png",
    minBet: 100,
    maxBet: 5000,
    decimals: 7,
  },
};

// Function to get random bet amount based on cryptocurrency
const getRandomBetAmount = (crypto) => {
  const config = cryptoConfig[crypto];
  const range = config.maxBet - config.minBet;
  const randomAmount = Math.random() * range + config.minBet;
  return randomAmount.toFixed(config.decimals);
};

// Function to calculate profit
const calculateProfit = (betAmount, crypto) => {
  const isWin = Math.random() < 0.5;
  if (!isWin) return "0.00";

  // Double the bet amount for profit
  const profit = parseFloat(betAmount) * 2;
  return profit.toFixed(cryptoConfig[crypto].decimals);
};

// Function to generate random betting data
const generateRandomData = (count) => {
  const data = [];
  let currentTime = new Date();
  const cryptoTypes = Object.keys(cryptoConfig);

  for (let i = 0; i < count; i++) {
    const selectedCrypto =
      cryptoTypes[Math.floor(Math.random() * cryptoTypes.length)];
    const betAmount = getRandomBetAmount(selectedCrypto);
    const profit = calculateProfit(betAmount, selectedCrypto);

    data.push({
      id: Math.floor(Math.random() * 1000000),
      username: "Hidden",
      bet: betAmount,
      profit: profit,
      dateTime: formatTime(currentTime),
      icon: cryptoConfig[selectedCrypto].icon,
      crypto: selectedCrypto,
    });

    currentTime = new Date(
      currentTime.getTime() - Math.floor(Math.random() * 55000 + 5000)
    );
  }
  return data;
};

const AnimatedTable = ({ visibleRowsCount = 10 }) => {
  const [visibleData, setVisibleData] = useState([]);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    setAllData(generateRandomData(20));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setAllData((prevData) => {
        const newData = [...prevData];
        newData.pop();
        newData.unshift(generateRandomData(1)[0]);
        return newData;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setVisibleData(allData.slice(0, visibleRowsCount));
  }, [allData, visibleRowsCount]);

  return (
    <Table>
      <thead>
        <tr>
          <TableHeader>ID</TableHeader>
          <TableHeader>USER</TableHeader>
          <TableHeader>BET</TableHeader>
          <TableHeader>PROFIT</TableHeader>
          <TableHeader>TIME</TableHeader>
        </tr>
      </thead>
      <tbody>
        {visibleData.map((row, index) => (
          <AnimatedTableRow key={`${row.id}-${index}`}>
            <TableCell style={{ color: "#99A4B0", fontWeight: "bold" }}>
              {row.id}
            </TableCell>
            <TableCell>
              <UserText>{row.username}</UserText>
            </TableCell>
            <TableCell>
              <FlexContainer>
                <CryptoIcon src={row.icon} alt="Crypto icon" />
                <AmountText>{forceSatoshiFormat(row.bet)}</AmountText>
              </FlexContainer>
            </TableCell>
            <TableCell>
              {parseFloat(row.profit) > 0 ? (
                <FlexContainer>
                  <CryptoIcon src={row.icon} alt="Crypto icon" />
                  <GreenText>{forceSatoshiFormat(row.profit)}</GreenText>
                </FlexContainer>
              ) : (
                <FlexContainer>
                  <CryptoIcon src={row.icon} alt="Crypto icon" />
                  <RedText>{forceSatoshiFormat(row.profit)}</RedText>
                </FlexContainer>
              )}
            </TableCell>
            <TableCell style={{ color: "#99A4B0", fontWeight: "bold" }}>
              {row.dateTime}
            </TableCell>
          </AnimatedTableRow>
        ))}
      </tbody>
    </Table>
  );
};

function SingleKenoTable() {
  return (
    <div style={{ marginTop: "12px" }}>
      <Container>
        <AnimatedTable />
      </Container>
    </div>
  );
}

export default SingleKenoTable;
