import React from "react";
import styled from "styled-components";

const AffiliateRewardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 0rem;
`;

const DashboardSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 2rem;
`;

const SectionHeader = styled.h2`
  margin: 0px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  padding: "";
`;

const SectionContentBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SectionBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const AffiliateRewards = () => {
  return <div>Hellow World</div>;
};

export default AffiliateRewards;
